#character {
  padding-top:152px;
  background:url(/assets/img/common/header_bg.png) top center no-repeat;
  background-size:100% auto;
  .content {
    padding-top:10px;
  }
  .subnav {
    padding-bottom:16px;
    background: url(/assets/img/common/header_border.png) left bottom repeat-x;
    width: 100%;
    &__wrap { @include wrap-pc; }
    a {
      background:#c1a0cc;
      border-radius:6px;
      color:#FFF;
      padding:5px 20px;
      margin-right:15px;
      font-size:14px;
      display:inline-block;
      position:relative;
      &:hover {
        background:#aa4eab;
      }
      &.active {
        background:#aa4eab;
        cursor:default;
      }
    }
    &--new::after {
      content:'';
      position:absolute;
      width:29px;
      height:10px;
      top:-10px;
      left:4px;
      background:url(/assets/img/common/ico_subnavnew.png) 0 0 no-repeat;
      background-size:auto 10px;
    }
  }
  .group {
    position:relative;
    overflow:hidden;
    margin-bottom:30px;
    &__wrap {
      @include wrap-pc;
      height:630px;
      position:relative;
    }
    &__title {
      width:100%;
      min-width:1500px;
      height:80px;
      position:absolute;
      left:0;
      bottom:0;
    }
    &__titlewrap {
      width:1500px;
      height:80px;
      margin:0 auto;
      position:relative;
    }
    &__name {
      position:absolute;
      left:500px;
      bottom:370px;
      width:260px;
      text-align:left;
      transition:all 1s ease 0.5s;
    }
    &__illust {
      transition:all 1s ease 0.5s;
      position:absolute;
      right:500px;
      bottom:370px;
      width:260px;
      text-align:right;
    }
    &--oda {
      background:url(/assets/img/character/oda_bg.jpg) top center no-repeat;
      .group__title {
        background:url(/assets/img/character/oda_titlebg.png) bottom left repeat-x;
        background-size: auto 100%;
      }
      .active .character__wrap { background-image:url(/assets/img/character/oda_char_bg.png); }
      .character {
        &--1 .character__visual { left:-80px; }
        &--2 .character__visual { left:-110px; }
        &--3 .character__visual { left:0px; }
        &--4 .character__visual { left:-110px; }
        &--5 .character__visual { left:-50px; }
      }
    }
    &--toyotomi {
      background:url(/assets/img/character/toyotomi_bg.jpg) top center no-repeat;
      .group__title {
        background:url(/assets/img/character/toyotomi_titlebg.png) bottom left repeat-x;
        background-size: auto 100%;
      }
      .active .character__wrap { background-image:url(/assets/img/character/toyotomi_char_bg.png); }
      .character {
        &--1 .character__visual { left:-20px; }
        &--2 .character__visual { left:2px; }
        &--3 .character__visual { left:-70px; }
        &--4 .character__visual { left:-34px; }
        &--5 .character__visual { left:-50px; }
      }
    }
    &--uesugi {
      background:url(/assets/img/character/uesugi_bg.jpg) top center no-repeat;
      .group__title {
        background:url(/assets/img/character/uesugi_titlebg.png) bottom left repeat-x;
        background-size: auto 100%;
      }
      .active .character__wrap { background-image:url(/assets/img/character/uesugi_char_bg.png); }
      .character {
        &--1 .character__visual { left:-50px; }
        &--2 .character__visual { left:-68px; }
        &--3 .character__visual { left:-60px; }
        &--4 .character__visual { left:-10px; }
        &--5 .character__visual { left:-30px; }
      }
    }
    &--takeda {
      background:url(/assets/img/character/takeda_bg.jpg) top center no-repeat;
      .group__title {
        background:url(/assets/img/character/takeda_titlebg.png) bottom left repeat-x;
        background-size: auto 100%;
      }
      .active .character__wrap { background-image:url(/assets/img/character/takeda_char_bg.png); }
      .character {
        &--1 .character__visual { left:-60px; }
        &--2 .character__visual { left:-78px; }
        &--3 .character__visual { left:-30px; }
        &--4 .character__visual { left:-50px; }
        &--5 .character__visual { left:-40px; }
      }
    }
    &--sanada {
      background:url(/assets/img/character/sanada_bg.jpg) top center no-repeat;
      .group__title {
        background:url(/assets/img/character/sanada_titlebg.png) bottom left repeat-x;
        background-size: auto 100%;
      }
      .active .character__wrap { background-image:url(/assets/img/character/sanada_char_bg.png); }
      .character {
        &--1 .character__visual { left:-100px; }
        &--2 .character__visual { left:-38px; }
        &--3 .character__visual { left:-90px; }
        &--4 .character__visual { left:-7px; }
        &--5 .character__visual { left:-45px; }
      }
    }
    &--date {
      background:url(/assets/img/character/date_bg.jpg) top center no-repeat;
      .group__title {
        background:url(/assets/img/character/date_titlebg.png) bottom left repeat-x;
        background-size: auto 100%;
      }
      .active .character__wrap { background-image:url(/assets/img/character/date_char_bg.png); }
      .character {
        &--1 .character__visual { left:-30px; }
        &--2 .character__visual { left:-148px; }
        &--3 .character__visual { left:-60px; }
      }
    }
    &--mouri {
      background:url(/assets/img/character/mouri_bg.jpg) top center no-repeat;
      .group__title {
        background:url(/assets/img/character/mouri_titlebg.png) bottom left repeat-x;
        background-size: auto 100%;
      }
      .active .character__wrap { background-image:url(/assets/img/character/mouri_char_bg.png); }
      &.gather{
        .character {
          &--2{
            left: 172px;
          }
          &--4{
            .character__catch{
              left: 20px;
            }
          }
          &--3{
            .character__catch{
              left: -23px;
            }
          }
          &--5{
            left: 778px;
            .character__visual{
              width: 265px;
              img{
                width: 100%;
              }
            }
            .character__catch{
              left: 20px;
              img{
                position: relative;
                left: 70px;
              }
            }
          }
        }
      }
    }
  }
  .gather {
    .group__illust { bottom:0; right:0; }
    .group__name { bottom:0; left:0; }
  }
  .gather .character {
    bottom:0px;
    opacity:1;
    &--1 { left:384px; }
    &--2 { left:192px; }
    &--3 { left:576px; }
    &--4 { left:0px; }
    &--5 { left:768px; }
  }
  .character {
    transition:all 1.5s ease 0.5s;
    opacity:0;
    position:absolute;
    width:192px;
    height:550px;
    bottom:-300px;
    &--1 { left:384px; }
    &--2 { left:0px; }
    &--3 { left:960px; }
    &--4 { left:-960px; }
    &--5 { left:1980px; }
    &--new:hover {
      .character__catch {
        z-index:300;
      }
    }
    &--new::after {
      content:'';
      background:url(/assets/img/character/ico_new.png) top center no-repeat;
      display:block;
      width:100%;
      height:42px;
      position:absolute;
      top:-70px;
      left:0;
      z-index:300;
    }
    &__wrap {
      position:relative;
      overflow:visible;
      width:192px;
      height:550px;
      transition:all 0.5s ease 0s;
      background-image:none;
      background-repeat:no-repeat;
      background-position:bottom 0px left -192px;
    }
    &.active {
      z-index:10;
      .character__wrap {
        background-position:bottom 0px left 0px;
      }
      .character__visual img {
      }
      .character__name {
        bottom:0;
      }
    }
    &__visual {
      position:absolute;
      width:50px;
      bottom:0;
      left:-100px;
      z-index:0;
      pointer-events:none;
    }
    &__catch {
      position:absolute;
      top:0;
      left:0;
      width:192px;
      height:550px;
      z-index:200;
      img {
        transition:all 0.5s ease 0s;
        opacity:0;
        margin:0 50px;
      }
      &--left { text-align:left; }
      &--right { text-align:right; }
      &:hover img {
        opacity:1;
        margin:0 14px;
      }
      &--disable {
        &:hover {
          cursor:default;
          img { opacity:0; }
        }
      }
    }
    &__name {
      width:50px;
      height:259px;
      position:absolute;
      bottom:0;
      right:0;
      z-index:300;
      transition:all 0.5s ease 0s;
      &--disable {
        background:url(/assets/img/character/disable_name.png) bottom left no-repeat;
        img {
          opacity:0.5;
        }
      }
    }
    &--1 {
      .character__visual {
        z-index:1;
      }
    }
  }
  .modal {
    display:none;
    position:absolute;
    top:100px;
    left:0;
    min-width:1280px;
    width:100%;
    z-index:2000;
    background-color:#FFF;
    background-position:bottom center;
    background-repeat:no-repeat;
    &__cover {
      display:none;
      position:fixed;
      top:0;
      left:0;
      width:100%;
      height:100%;
      opacity:0.9;
      background-color:#d3ceea;
      z-index:1000;
    }
    &__wrap {
      position:relative;
      width:100%;
      height:1200px;
      border-top:13px solid #deb4f3;
      border-bottom:13px solid #deb4f3;
      box-sizing:content-box;
      overflow:hidden;
    }
    &__control {
      position:absolute;
      z-index:2000;
      &--close {
        top:0;
        right:10px;
      }
      &--left {
        top:480px;
        left:10px;
      }
      &--right {
        top:480px;
        right:10px;
      }
      &--voice {
        &s { margin-bottom:3px; }
      }
      &--k {
        display:none;
        a { margin-right:-3px; }
      }
      &--nok { display:block; }
      &--int { display:none; }
      &--rec{display: inline-block;}
    }
    &__char {
      width:45%;
      position:absolute;
      left:0;
      top:0;
      &visual {
        text-align:right;
        img {
          float:right;
          margin-right:-1020px;
        }
      }
      &catch {
        position:absolute;
        top:45px;
        left:20px;
      }
      &btns {
        margin:0 0 15px 200px;
        width:470px;
        a {
          &:hover img { opacity:0.8; }
        }
      }
      &voices {
        margin:0 0 15px 200px;
      }
      &copy {
        margin:0 0 15px 0;
        img {
          width:100%;
          height:auto;
        }
      }
      &name {
        margin:0 0 15px 190px;
      }
      &text {
        text-shadow:0 0 6px #FFF;
        font-size:18px;
        line-height:30px;
        margin:0 0 0 200px;
        background:rgba(255,255,255,0.2);
        padding:15px;
        width:480px;
      }
      &spec {
        padding:15px;
        margin:0 0 0 200px;
        width:480px;
        font-size:18px;
        text-shadow:0 0 6px #FFF;
        line-height:30px;
        dl { overflow:hidden; }
        dt {
          width:150px;
          float:left;
          position:relative;
          padding-left:16px;
          &::before {
            content:'';
            background:url(/assets/img/character/modal/ico_spec.png) top left no-repeat;
            background-size:auto 100%;
            width:12px;
            height:13px;
            position:absolute;
            top:7px;
            left:0;
          }
          &::after {
            content:'：';
            position:absolute;
            top:0;
            right:5px;
          }
        }
        dd {
          width:300px;
          float:left;
          position:relative;
          span { font-size:14px; }
        }
      }
      &extra {
        padding:15px;
        margin:10px 0 0 200px;
        width:480px;
        font-size:18px;
        text-shadow:0 0 6px #FFF;
        line-height:30px;
        position:relative;
        img { display:block; }
      }
      &zoom {
        display:block;
        position:absolute;
        top:100px;
        left:0;
        width:100%;
        text-align:right;
        img {
          height:950px;
          width:auto;
          float:right;
        }
      }
    }
    &__info {
      width:55%;
      position:absolute;
      right:0;
      top:0;
    }
    &__leader {
      position:relative;
      &btn {
        position:absolute;
        top:8px;
        left:208px;
        &:hover img {
          opacity:0.8;
        }
      }
    }
    &__btns {
      padding-left:225px;
      img { display:block; }
      &voice {
        overflow:hidden;
        li { float:left; }
      }
    }
    .modal__char {
      .modal__charvisual img, .modal__charzoom img { display:none; }
      .modal__charvisual .modal__charvisual--a { display:block; }
    }
    .modal__char.all {
      .modal__charvisual img, .modal__charzoom img { display:none; }
      .modal__charzoom .modal__charzoom--a { display:block; }
    }
    .modal__char.secondary {
      .modal__charvisual img, .modal__charzoom img { display:none; }
      .modal__charvisual .modal__charvisual--b { display:block; }
    }
    .modal__char.secondary.all {
      .modal__charvisual img, .modal__charzoom img { display:none; }
      .modal__charzoom .modal__charzoom--b { display:block; }
    }
    .modal__char.kakusei {
      .modal__charvisual img, .modal__charzoom img { display:none; }
      .modal__charvisual .modal__charvisual--k { display:block; }
    }
    .modal__char.kakusei.all {
      .modal__charvisual img, .modal__charzoom img { display:none; }
      .modal__charzoom .modal__charzoom--k { display:block; }
    }

//織田軍
    &.oda.char1 {
      .modal__charvisual--a { margin:-20px -960px; }
      .modal__charvisual--b { margin:-20px -900px; }
      .modal__charvisual--k { margin:-18px -960px; }
      .modal__charzoom--a { margin-right:-90px; }
      .modal__charzoom--b { margin-right:-50px; }
      .modal__charzoom--k { margin-right:-113px; margin-top:-1px; }
      .modal__control--int{display:inline-block;}
    }
    &.oda.char2 {
      .modal__charcatch { top:25px; }
      .modal__charvisual--a { margin:-20px -940px; }
      .modal__charvisual--b { margin:-20px -900px; }
      .modal__charzoom--a { margin-right:50px; margin-top:-50px; }
      .modal__charzoom--b { margin-right:-10px; }
    }
    &.oda.char3 {
      .modal__charcatch {}
      .modal__charvisual--a { margin:-20px -900px; }
      .modal__charvisual--b { margin:-20px -900px; }
      .modal__charzoom--a { margin-right:-90px; }
      .modal__charzoom--b { margin-right:-50px; }
    }
    &.oda.char4 {
      .modal__charcatch {}
      .modal__charvisual--a { margin:0 -910px; }
      .modal__charvisual--b { margin:0 -910px; }
      .modal__charzoom--a { margin-right:-70px; margin-top:-30px; }
      .modal__charzoom--b { margin-right:50px; }
    }
    &.oda.char5 {
      .modal__charcatch {}
      .modal__charvisual--a { margin:0 -910px; }
      .modal__charvisual--b { margin:0 -910px; }
      .modal__charzoom--a { margin-right:-40px; }
      .modal__charzoom--b { margin-right:-50px; }
    }
//豊富軍
    &.toyotomi.char1 {
      .modal__charvisual--a { margin:0 -880px; }
      .modal__charvisual--b { margin:0 -910px; }
      .modal__charvisual--k { margin:0 -880px; }
      .modal__charzoom--a { margin-right:10px; }
      .modal__charzoom--b { margin-right:20px; }
      .modal__charzoom--k { margin-right:10px; }
      .modal__control--int{display:inline-block;}

      .modal__charextra { min-height:200px; }
      .modal__charextra01 {  }
      .modal__charextra02 {
        position:absolute;
        top:20px;
        right:10px;
      }
    }
    &.toyotomi.char2 {
      .modal__charcatch { top:95px; }
      .modal__charvisual--a { margin:0 -910px; }
      .modal__charvisual--b { margin:0 -910px; }
      .modal__charzoom--a { margin-right:-20px; margin-top:-40px; }
      .modal__charzoom--b { margin-right:10px; }
    }
    &.toyotomi.char3 {
      .modal__charcatch { top:0; }
      .modal__charvisual--a { margin:0 -910px; }
      .modal__charvisual--b { margin:0 -910px; }
      .modal__charzoom--a { margin-right:10px; }
      .modal__charzoom--b { margin-right:10px; }
    }
    &.toyotomi.char4 {
      .modal__charcatch {  }
      .modal__charvisual--a { margin:0 -940px; }
      .modal__charvisual--b { margin:0 -940px; }
      .modal__charzoom--a { margin-right:-40px; }
      .modal__charzoom--b { margin-right:10px; }
    }
    &.toyotomi.char5 {
      .modal__charcatch {  }
      .modal__charvisual--a { margin:0 -940px; }
      .modal__charvisual--b { margin:-20px -940px; }
      .modal__charzoom--a { margin-right:-20px; margin-top:-40px; }
      .modal__charzoom--b { margin-right:40px; }
    }
//上杉軍
    &.uesugi.char1 {
      .modal__charcatch { left:40px; }
      .modal__charvisual img { margin-right:-910px; }
      .modal__charzoom--k { margin-right:-127px; }
      .modal__control--int{display:inline-block;}
    }
    &.uesugi.char2 {
      .modal__charvisual img {  }
      .modal__charvisual--a { margin-right:-910px; }
      .modal__charvisual--b { margin-right:-910px; }
    }
    &.uesugi.char3 {
      .modal__charvisual img {  }
      .modal__charvisual--a { margin-right:-930px; }
      .modal__charvisual--b { margin-right:-930px; }
      .modal__charzoom--a { margin-right:-20px; }
      .modal__charzoom--b { margin-right:-10px; }
    }
    &.uesugi.char4 {
      .modal__charvisual img {  }
      .modal__charvisual--a { margin-right:-940px; }
      .modal__charvisual--b { margin-right:-940px; }
    }
    &.uesugi.char5 {
      .modal__charcatch { left:40px; }
      .modal__charvisual--a { margin-right:-960px; }
      .modal__charvisual--b { margin-right:-940px; }
      .modal__charzoom--a { margin-right:-50px; }
      .modal__charzoom--b { margin-right:-50px; }
    }
//武田軍
    &.takeda.char1 {
      .modal__charvisual img { margin:0 -930px; }
      .modal__charzoom--a { margin-right:-70px; }
      .modal__charzoom--b { margin-right:-70px; }
      .modal__charzoom--k { margin-right:-69px; }
      .modal__control--int{display:inline-block;}
    }
    &.takeda.char2 {
      .modal__charvisual img {  }
      .modal__charvisual--a { margin-right:-920px; }
      .modal__charvisual--b { margin-right:-930px; }
    }
    &.takeda.char3 {
      .modal__charvisual img { margin-right:-920px; }
      .modal__charzoom--a { margin-right:-60px; }
    }
    &.takeda.char4 {
      .modal__charcatch { top:-10px; }
      .modal__charvisual--a { margin-right:-920px; }
      .modal__charvisual--b { margin-right:-950px; }
      .modal__charzoom--a { margin-right:-30px; }
    }
    &.takeda.char5 {
      .modal__charvisual--a { margin-right:-930px; }
      .modal__charvisual--b { margin-right:-990px; }
      .modal__charzoom--a { margin-right:-90px; }
    }
//真田軍
    &.sanada.char1 {
      .modal__charvisual img { margin-right:-890px; }
      .modal__charzoom--a { margin-right:-30px; margin-top:-90px; height:990px; }
      .modal__charzoom--k { margin-right:-39px; margin-top:-87px; height:990px; }
      .modal__control--int{display:inline-block;}
    }
    &.sanada.char2 {
      .modal__charvisual img { margin-right:-890px; }
    }
    &.sanada.char3 {
      .modal__charvisual img { margin-right:-950px; }
    }
    &.sanada.char4 {
      .modal__charvisual img { margin-right:-950px; }
    }
    &.sanada.char5 {
      .modal__charvisual img { margin-right:-940px; }
    }
//伊達郡
    &.date.char1 {
      .modal__charvisual img { margin-right:-900px; }
      .modal__charvisual--k { margin-top:0px; }
      .modal__charzoom--k { margin-right:-31px; }
      .modal__control--int{display:inline-block;}
    }
    &.date.char2 {
      .modal__charvisual img { margin-right:-900px; }
    }
    &.date.char3 {
      .modal__charvisual img { margin-right:-900px; }
    }

//毛利郡
    &.mouri.char1 {
      .modal__charvisual--a { margin: -157px -810px; }
      .modal__charvisual--b { margin: -157px -810px; }
      .modal__charvisual--k { margin: -157px -810px; }
      .modal__charzoom--a { margin-right:-130px; margin-top:-80px;  height: 970px;}
      .modal__charzoom--b { margin-right:-90px; margin-top:-60px; height: 970px;}
      .modal__charzoom--k { margin-right:-130px; margin-top:-80px; height: 970px;}
    }
    &.mouri.char2 {
      .modal__charvisual--a { margin:-330px -810px; }
      .modal__charvisual--b { 
        margin:-180px -859px; 
      }
      .modal__charzoom--a { 
        margin-right: -230px; margin-top: -180px;
        height: 1200px;
      }
      .modal__charzoom--b { margin-right: -80px; margin-top: -60px; }
    }
    &.mouri.char3 {
      .modal__charvisual--a { margin:-170px -780px; }
      .modal__charvisual--b { margin:-170px -780px; }
      .modal__charzoom--a {  margin-right: -70px; margin-top: -60px; }
      .modal__charzoom--b {  margin-right: -70px; margin-top: -60px; }
    }
    &.mouri.char4 {
      .modal__charvisual--a { margin:-200px -750px; }
      .modal__charvisual--b { margin:-200px -750px; }
      .modal__charzoom--a {  margin-right: -20px; margin-top: -60px; }
      .modal__charzoom--b {  margin-right: 30px; margin-top: -60px; }
    }
    &.mouri.char5 {
      .modal__charvisual--a { margin:60px -790px; }
      .modal__charvisual--b { margin:30px -330px; }
      .modal__charzoom{
        img{
          height: 650px;
        }
      }
      .modal__charzoom--a {  margin-right: -60px; margin-top: 30px; }
      .modal__charzoom--b {  margin-right: -10px; margin-top: 30px; }
    }
    



    &.oda {
      .modal__wrap {
        border-top:13px solid #deb4f3;
        border-bottom:13px solid #deb4f3;
      }
      .modal__chartext { background:rgba(219,177,241,0.4); }
      .modal__charspec { background:rgba(219,177,241,0.4); }
      .modal__charextra { background:rgba(219,177,241,0.4); }
    }
    &.toyotomi {
      .modal__wrap {
        border-top:13px solid #dac38f;
        border-bottom:13px solid #dac38f;
      }
      .modal__chartext { background:rgba(216,191,142,0.4); }
      .modal__charspec { background:rgba(216,191,142,0.4); }
      .modal__charextra { background:rgba(216,191,142,0.4); }
    }
    &.uesugi {
      .modal__wrap {
        border-top:13px solid #a5d3f2;
        border-bottom:13px solid #a5d3f2;
      }
      .modal__chartext { background:rgba(166,211,240,0.4); }
      .modal__charspec { background:rgba(166,211,240,0.4); }
      .modal__charextra { background:rgba(166,211,240,0.4); }
    }
    &.takeda {
      .modal__wrap {
        border-top:13px solid #efaabf;
        border-bottom:13px solid #efaabf;
      }
      .modal__chartext { background:rgba(237,171,191,0.4); }
      .modal__charspec { background:rgba(237,171,191,0.4); }
      .modal__charextra { background:rgba(237,171,191,0.4); }
    }
    &.sanada {
      .modal__wrap {
        border-top:13px solid #de9e81;
        border-bottom:13px solid #de9e81;
      }
      .modal__chartext { background:rgba(227,173,143,0.4); }
      .modal__charspec { background:rgba(227,173,143,0.4); }
      .modal__charextra { background:rgba(227,173,143,0.4); }
    }
    &.date {
      .modal__wrap {
        border-top:13px solid #b9c2fd;
        border-bottom:13px solid #b9c2fd;
      }
      .modal__chartext { background:rgba(224,229,251,0.4); }
      .modal__charspec { background:rgba(224,229,251,0.4); }
      .modal__charextra { background:rgba(224,229,251,0.4); }
    }
    &.mouri {
      .modal__wrap {
        border-top:13px solid #5FDC94;
        border-bottom:13px solid #5FDC94;
      }
      .modal__chartext { 
        background:rgba(95,220,148,0.4); 
        letter-spacing: -0.04em;
      }
      .modal__charspec { background:rgba(95,220,148,0.4); }
      .modal__charextra { background:rgba(95,220,148,0.4); }
    }

  }
}


html.magnifying > body {
  overflow-x: hidden !important;
}
.magnify {
  position: relative;
  display: inline-block;
}
.magnify .magnify-lens {
  /* Create the magnifying lens */
  position: absolute;
  width: 304px;
  height: 304px;
  border-radius: 100%;
  /* Multiple box shadows to achieve the glass lens effect */
  -webkit-box-shadow:
    0 0 7px 7px rgba(0, 0, 0, 0.25),
    inset 0 0 40px 2px rgba(0, 0, 0, 0.25);
  box-shadow:
    0 0 7px 7px rgba(0, 0, 0, 0.25),
    inset 0 0 40px 2px rgba(0, 0, 0, 0.25);
  /* Hide the mouse pointer */
  cursor: none;
  /* Hide the lens by default */
  display: none;
  /* Place the lens on top of other elements */
  z-index: 100;
  overflow:visible;
  background-size:auto 2600px !important;
  &#z1 { background-size:auto 2000px !important; }
  &#z2 { background-size:auto 2600px !important; }
  &#z3 { background-size:auto 3000px !important; }
}

.magnify .magnify-lens::after {
  content: '';
  display:block;
  background:url(/assets/img/character/modal/zoom_glass.png) center center no-repeat;
  width:326px;
  height:326px;
  margin-top:-12px;
  margin-left:-12px;
}
.magnify .magnify-lens.loading {
  background: #333 !important;
  opacity: 0.8;
}
.magnify .magnify-lens.loading:after {
  /* Loading text */
  position: absolute;
  top: 45%;
  left: 0;
  width: 100%;
  color: #fff;
  content: 'Loading...';
  font: italic normal 16px/1 Calibri, sans-serif;
  text-align: center;
  text-shadow: 0 0 2px rgba(51, 51, 51, 0.8);
  text-transform: none;
}

.video-modal{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 3000;
  display: none;
  .video-cont{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 854px;
    height: 480px;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    iframe{
      width: 100%;
      height: 100%;
    }
    .video-close{
      position: absolute;
      top: 0;
      right: 0;
      -webkit-transform: translateY(-100%);
      -ms-transform: translateY(-100%);
      transform: translateY(-100%);
    }
  }
}


@media (max-width: 750px) {
  #character {
    padding-top:140px;
    background:url(/assets/img/common/sp/bg_main.png) top 120px center no-repeat;
    min-width:auto;
    .subnav {
      padding-bottom:16px;
      background: url(/assets/img/common/sp/header_border.png) left bottom repeat-x;
      &__wrap { 
        @include wrap-sp; 
      }
      a {
        padding:5px 20px;
        margin-right:0;
        margin-left:20px;
        font-size:20px;
      }
    }
    .group {
      padding-top:72px;
      &__wrap {
        @include wrap-sp;
        height:600px;
      }
      &__title {
        min-width:auto;
        width:100%;
        height:72px;
        position:static;
        background:url(/assets/img/common/bg_content.png) 0 0 repeat;
        &.sp-on { display:block; }
        margin-bottom:-72px;
      }
      &--oda {
        background:url(/assets/img/character/oda_bg.jpg) top 72px center no-repeat;
        .group__wrap { background:url(/assets/img/character/oda_titlebg.png) bottom center no-repeat; }
        .active .character__wrap { background-image:url(/assets/img/character/oda_char_bg.png); }
        .character {
          &--1 .character__visual { left:-80px; }
          &--2 .character__visual { left:-90px; }
          &--3 .character__visual { left:0px; }
          &--4 .character__visual { left:-110px; }
          &--5 .character__visual { left:-50px; }
        }
      }
      &--toyotomi {
        background:url(/assets/img/character/toyotomi_bg.jpg) top 72px center no-repeat;
        .group__wrap { background:url(/assets/img/character/toyotomi_titlebg.png) bottom center no-repeat; }
        .active .character__wrap { background-image:url(/assets/img/character/toyotomi_char_bg.png); }
        .character {
          &--1 .character__visual { left:-20px; }
          &--2 .character__visual { left:2px; }
          &--3 .character__visual { left:-70px; }
          &--4 .character__visual { left:-34px; }
          &--5 .character__visual { left:-50px; }
        }
      }
      &--uesugi {
        background:url(/assets/img/character/uesugi_bg.jpg) top 72px center no-repeat;
        .group__wrap { background:url(/assets/img/character/uesugi_titlebg.png) bottom center no-repeat; }
        .active .character__wrap { background-image:url(/assets/img/character/uesugi_char_bg.png); }
        .character {
          &--1 .character__visual { left:-50px; }
          &--2 .character__visual { left:-68px; }
          &--3 .character__visual { left:-60px; }
          &--4 .character__visual { left:-10px; }
          &--5 .character__visual { left:-30px; }
        }
      }
      &--takeda {
        background:url(/assets/img/character/takeda_bg.jpg) top 72px center no-repeat;
        .group__wrap { background:url(/assets/img/character/takeda_titlebg.png) bottom center no-repeat; }
        .active .character__wrap { background-image:url(/assets/img/character/takeda_char_bg.png); }
        .character {
          &--1 .character__visual { left:-60px; }
          &--2 .character__visual { left:-78px; }
          &--3 .character__visual { left:-30px; }
          &--4 .character__visual { left:-50px; }
          &--5 .character__visual { left:-40px; }
        }
      }
      &--sanada {
        background:url(/assets/img/character/sanada_bg.jpg) top 72px center no-repeat;
        .group__wrap { background:url(/assets/img/character/sanada_titlebg.png) bottom center no-repeat; }
        .active .character__wrap { background-image:url(/assets/img/character/sanada_char_bg.png); }
        .character {
          &--1 .character__visual { left:-100px; }
          &--2 .character__visual { left:-38px; }
          &--3 .character__visual { left:-90px; }
          &--4 .character__visual { left:-7px; }
          &--5 .character__visual { left:-45px; }
        }
      }
      &--date {
        background:url(/assets/img/character/date_bg.jpg) top 72px center no-repeat;
        .group__wrap { background:url(/assets/img/character/date_titlebg.png) bottom center no-repeat; }
        .active .character__wrap { background-image:url(/assets/img/character/date_char_bg.png); }
        .character {
          &--1 .character__visual { left:-30px; }
          &--2 .character__visual { left:-148px; }
          &--3 .character__visual { left:-60px; }
        }
      }
      &--mouri {
        background:url(/assets/img/character/mouri_bg.jpg) top 72px center no-repeat;
        .group__wrap { background:url(/assets/img/character/mouri_titlebg.png) bottom center no-repeat; }
        .active .character__wrap { background-image:url(/assets/img/character/mouri_char_bg.png); }
        &.gather{
          .character {
            &--2{
              left: 140px;
            }
            &--3{
              left: 463px;
            }
            &--4{
              .character__catch{
                left: 20px;
              }
            }
            &--5{
              left: 660px;
              .character__name{
                right: 46px;
              }
            }
          }
        }
      }
    }
    .gather .character {
      bottom:0px;
      opacity:1;
      &--1 { left:300px; }
      &--2 { left:150px; }
      &--3 { left:450px; }
      &--4 { left:0px; }
      &--5 { left:600px; }
    }
    .character {
      transition:all 1.5s ease 0s;
      opacity:0;
      position:absolute;
      width:150px;
      height:550px;
      bottom:-300px;
      &--1 { left:300px; }
      &--2 { left:0px; }
      &--3 { left:600px; }
      &--4 { left:-150px; }
      &--5 { left:750px; }
      &--new::after {
        top:-40px;
      }
      &__wrap {
        position:relative;
        overflow:visible;
        width:150px;
        height:550px;
        transition:all 0.5s ease 0s;
        background-image:none;
        background-repeat:no-repeat;
        background-position:bottom 0px left -150px;
        right: 25px;
      }
      &.active {
        .character__wrap {
          background-position:bottom 0px left 0px;
        }
        .character__visual img {
        }
      }
      &__visual {
        position:absolute;
        bottom:0;
        left:-100px;
        z-index:0;
      }
      &__catch {
        img { display:none; }
        &:hover img { opacity:0; }
      }
      &__name {
        width:50px;
        height:259px;
        position:absolute;
        bottom:0;
        right:0;
        z-index:300;
      }
      &--1 {
        .character__visual {
          z-index:1;
        }
      }
    }
    .modal {
      display:none;
      position:absolute;
      top:100px;
      left:0;
      width:100%;
      min-width:750px;
      height:1120px;
      background-size:auto 100%;
      &__wrap {
        position:relative;
        width:100%;
        height:1120px;
        box-sizing:border-box;
      }
      &__control {
        position:absolute;
        z-index:2000;
        &--close {
          top:0px;
          right:10px;
        }
        &--left {
          display:none;
          top:800px;
          left:10px;
          img { width:60px; }
        }
        &--right {
          display:none;
          top:800px;
          right:10px;
          img { width:60px; }
        }
        &--k {
          a { margin-right:0; }
        }
      }
      &__char {
        width:100%;
        position:relative;
        height:1094px;
        overflow:hidden;
        &visual {
          display:none;
        }
        &catch {
          left:5px !important;
          top:auto !important;
          bottom:20px;
          z-index:200;
          img {
            height:650px;
          }
        }
        &zoom {
          display:block;
          overflow:visible;
          top:0;
          height:1174px;
          position:relative;
          text-align:right;
          width:105%;
          img {
            height:1600px;
            width:auto;
            margin-right:150px;
          }
        }
        &info {
          padding-top:64px;
          width:100%;
        }
        &copy {
          width:100%;
          overflow:hidden;
          img {
            display:block;
            width:110%;
            height:auto;
          }
        }
        &name {
          position:static;
          width:300px;
          margin-left:160px;
          margin-bottom:0;
          img {
            width:275px;
            height:auto;
          }
        }
        &btns {
          width:380px;
          margin-left:70px;
          margin-right:20px;
          margin-bottom:5px;
          text-align:right;
          .modal__control--all { display:none; }
        }
        &text {
          font-size:16px;
          line-height:22px;
          margin:0 20px 0 100px;
          padding:10px;
          width:auto;
        }
        &spec {
          margin:0 20px 0 100px;
          padding:10px;
          width:auto;
          font-size:16px;
          line-height:22px;
          dt { float:none; }
          dd {
            float:none;
            width:auto;
          }
        }
        &extra {
          margin:10px 20px 0 100px;
          padding:10px;
          width:auto;
          font-size:16px;
          line-height:22px;
        }
      }
      &__info {
        width:470px;
        overflow:hidden;
        position:absolute;
        right:0;
        top:45px;
      }
      &__leader {
        margin-left:20px;
        height:60px;
        overflow:hidden;
        margin-bottom:18px;
      }
      &__btns {
        padding-left:0;
        img { display:block; }
        &zoom {
          display:none;
        }
        &voice {
          overflow:hidden;
          li { float:left; }
        }
      }
      .modal__char {
        .modal__charvisual img, .modal__charzoom img { display:none; }
        .modal__charzoom .modal__charzoom--a { display:block; }
      }
      .modal__char.secondary {
        .modal__charvisual img, .modal__charzoom img { display:none; }
        .modal__charzoom .modal__charzoom--b { display:block; }
      }
      .modal__char.kakusei {
        .modal__charvisual img, .modal__charzoom img { display:none; }
        .modal__charzoom .modal__charzoom--k { display:block; }
      }
//織田軍
      &.oda.char1 {
        .modal__wrap { height:auto; }
        .modal__charzoom { top:0; }
        .modal__charzoom--a { margin:40px 30px; }
        .modal__charzoom--b { margin:30px 140px; }
        .modal__charzoom--k { margin:39px -6px; }
      }
      &.oda.char2 {
        .modal__charcatch { top:200px; }
        .modal__charzoom--a { margin:-50px 340px; }
        .modal__charzoom--b { margin:40px 220px; }
      }
      &.oda.char3 {
        .modal__charzoom img { height:1440px; }
        .modal__charzoom--a { margin:20px 140px; }
        .modal__charzoom--b { margin:40px 250px; }
      }
      &.oda.char4 {
        .modal__charzoom--a { margin:-50px 140px; }
        .modal__charzoom--b { margin:20px 320px; }
      }
      &.oda.char5 {
        .modal__charzoom--a { margin:20px 200px; }
        .modal__charzoom--b { margin:20px 200px; }
      }
//上杉軍
      &.uesugi.char1 {
        .modal__wrap { height:auto; }
        .modal__charzoom { top:0; }
        .modal__charzoom--a { margin:-50px 270px; }
        .modal__charzoom--b { margin:10px 300px; }
        .modal__charzoom--k { margin:-50px 69px; }
      }
      &.uesugi.char2 {
        .modal__charzoom--a { margin:-20px 300px; }
        .modal__charzoom--b { margin:10px 300px; }
      }
      &.uesugi.char3 {
        .modal__charzoom--a { margin:10px 250px; }
        .modal__charzoom--b { margin:10px 250px; }
      }
      &.uesugi.char4 {
        .modal__charzoom--a { margin:10px 240px; }
        .modal__charzoom--b { margin:10px 260px; }
      }
      &.uesugi.char5 {
        .modal__charzoom img { height:1520px; }
        .modal__charzoom--a { margin:10px 120px; }
        .modal__charzoom--b { margin:10px 170px; }
      }
//豊富軍
      &.toyotomi.char1 {
        .modal__wrap { height:auto; }
        .modal__charzoom { top:0; }
        .modal__charzoom--a { margin:-20px 310px; }
        .modal__charzoom--b { margin:-20px 310px; }
        .modal__charzoom--k { margin:-20px 310px; }
        .modal__charextra {
          min-height:140px;
        }
        .modal__charextra01 {}
        .modal__charextra02 {
          top:10px;
          img { width:150px; height:auto; }
        }
      }
      &.toyotomi.char2 {
        .modal__charcatch { top:195px; }
        .modal__charzoom--a { margin:-80px 240px; }
        .modal__charzoom--b { margin:0px 250px; }
      }
      &.toyotomi.char3 {
        .modal__charcatch { top:200px; }
        .modal__charzoom--a { margin:10px 280px; }
        .modal__charzoom--b { margin:0px 230px; }
      }
      &.toyotomi.char4 {
        .modal__charzoom--a { margin:-80px 190px; }
        .modal__charzoom--b { margin:-100px 240px; }
      }
      &.toyotomi.char5 {
        .modal__charzoom--a { margin:-60px 250px; }
        .modal__charzoom--b { margin:0px 320px; }
      }
//武田軍
      &.takeda.char1 {
        .modal__wrap { height:auto; }
        .modal__charzoom { top:0; }
        .modal__charzoom--a { margin:10px 140px; }
        .modal__charzoom--b { margin:10px 180px; }
        .modal__charzoom--k { margin:10px 141px; }
      }
      &.takeda.char2 {
        .modal__charzoom img { height:1520px; }
        .modal__charzoom--a { margin:-30px 230px; }
        .modal__charzoom--b { margin:20px 230px; }
      }
      &.takeda.char3 {
        .modal__charzoom--a { margin:20px 150px; }
        .modal__charzoom--b { margin:10px 200px; }
      }
      &.takeda.char4 {
        .modal__charcatch { top:200px; }
        .modal__charzoom--a { margin:0px 170px; }
        .modal__charzoom--b { margin:0px 300px; }
      }
      &.takeda.char5 {
        .modal__charzoom--a { margin:-50px 130px; }
        .modal__charzoom--b { margin:-10px 250px; }
      }
//真田軍
      &.sanada.char1 {
        .modal__wrap { height:auto; }
        .modal__charzoom { top:0; }
        .modal__charzoom--a {
          height:1800px;
          margin:-240px 290px;
        }
        .modal__charzoom--b { margin:-10px 320px; }
        .modal__charzoom--k {
          height:1800px;
          margin:-234px 274px;
        }
      }
      &.sanada.char2 {
        .modal__charzoom img { margin:-10px 220px; }
      }
      &.sanada.char3 {
        .modal__charzoom img { height:1440px; }
        .modal__charzoom--a { margin:-10px 310px; }
        .modal__charzoom--b { margin:20px 230px; }
      }
      &.sanada.char4 {
        .modal__charzoom img { margin:-20px 230px; }
      }
      &.sanada.char5 {
        .modal__charzoom--a { margin:-90px 280px; }
        .modal__charzoom--b { margin:-20px 210px; }
      }
//伊達郡
      &.date.char1 {
        .modal__wrap { height:auto; }
        .modal__charzoom { top:0; }
        .modal__charzoom--a { margin:30px 300px; }
        .modal__charzoom--b { margin:20px 330px; }
        .modal__charzoom--k { margin:30px 251px; }
      }
      &.date.char2 {
        .modal__charzoom img { height:1520px; }
        .modal__charzoom--a { margin:20px 270px; }
        .modal__charzoom--b { margin:20px 370px; }
      }
      &.date.char3 {
        .modal__charzoom--a { margin:0px 320px; }
        .modal__charzoom--b { margin:30px 280px; }
      }

      &.mouri.char1 {
        .modal__wrap { height:auto; }
        .modal__charzoom { top:0; }
        .modal__charzoom--a { margin:-20px 50px;height:1600px; }
        .modal__charzoom--b { margin:10px 120px;height:1600px; }
        .modal__charzoom--k { margin:-20px 50px;height:1600px; }
      }
      &.mouri.char2 {
        .modal__wrap { height:auto; }
        .modal__charzoom { top:0; }
        .modal__charzoom--a { margin:-100px 60px;height:1600px; }
        .modal__charzoom--b { margin:40px 170px; height:1600px;}
      }
      &.mouri.char3 {
        .modal__wrap { height:auto; }
        .modal__charzoom { top:0; }
        .modal__charzoom--a { margin:-20px 190px; }
        .modal__charzoom--b { margin:-20px 190px; }
      }
      &.mouri.char4 {
        .modal__wrap { height:auto; }
        .modal__charzoom { top:0; }
        .modal__charzoom--a { margin:30px 290px; }
        .modal__charzoom--b { margin:30px 340px; }
      }
      &.mouri.char5 {
        .modal__wrap { height:auto; }
        .modal__charzoom { 
          top:0; 
          img{
            height: auto;
          }
        }
        .modal__charzoom--a { margin:30px 220px; }
        .modal__charzoom--b { margin:30px 220px; }
      }








      &.sanada {
        .modal__chartext { background:rgba(250,224,201,0.7); }
        .modal__charspec { background:rgba(250,224,201,0.7); }
      }
      &.date {
        .modal__chartext { background:rgba(224,229,251,0.7); }
        .modal__charspec { background:rgba(224,229,251,0.7); }
      }
    }
  }

}

//軍紹介・相関図
#character {
  .gun {
    &s {
      &__nav {
        @include wrap-pc;
        padding:50px 0;
        a {
          position:relative;
          display:inline-block;
          &.guns__nav--new::after {
            content:'';
            position:absolute;
            width:29px;
            height:10px;
            top:-10px;
            left:14px;
            background:url(/assets/img/common/ico_subnavnew.png) 0 0 no-repeat;
            background-size:auto 10px;
          }
        }
      }
    }
    &__wrap {
      @include wrap-pc;
      padding-bottom:30px;
      position:relative;
    }
    &__btnmovie {
      position:absolute;
      top:8px;
      left:220px;
    }
    &__title {
      margin-bottom:10px;
    }
    &__chart {
      margin-bottom:10px;
    }
    &__text {
      font-size:18px;
      line-height:32px;
    }
    &__cs {
      margin-bottom:5px;
    }
    &#oda {
      border-top:12px solid #dfb5f4;
      .gun__wrap { background:url(/assets/img/character/gun/bg_oda.png) center right no-repeat; }
    }
    &#toyotomi {
      border-top:12px solid #dbc490;
      .gun__wrap { background:url(/assets/img/character/gun/bg_toyotomi.png) center right no-repeat; }
    }
    &#uesugi {
      border-top:12px solid #a6c7ef;
      .gun__wrap { background:url(/assets/img/character/gun/bg_uesugi.png) center right no-repeat; }
    }
    &#takeda {
      border-top:12px solid #efa2b5;
      .gun__wrap { background:url(/assets/img/character/gun/bg_takeda.png) center right no-repeat; }
    }
    &#sanada {
      border-top:12px solid #e4ae8d;
      .gun__wrap { background:url(/assets/img/character/gun/bg_sanada.png) center right no-repeat; }
    }
    &#date {
      border-top:12px solid #b4bafb;
      .gun__wrap { background:url(/assets/img/character/gun/bg_date.png) center right no-repeat; }
    }
    &modal {
      display:none;
      width:100%;
      position:absolute;
      top:100px;
      left:0;
      overflow:hidden;
      z-index:2000;
      &__close {
        position:absolute;
        top:0;
        right:0;
      }
      &__cover {
        display:none;
        position:fixed;
        top:0;
        left:0;
        width:100%;
        height:100%;
        opacity:0.9;
        background-color:#d3ceea;
        z-index:1000;
      }
      &__wrap {
        padding-top:50px;
        width:838px;
        height:530px;
        margin:0 auto;
        position:relative;
      }
      &__body {
        border:10px solid #FFF;
        width:100%;
        height:100%;
        iframe {
          width:100%;
          height:100%;
        }
      }
    }
  }
}

@media (max-width: 750px) {
  #character {
    .gun {
      &s {
        &__nav {
          @include wrap-sp;
          padding:30px 30px;
          img {
            width:110px;
            height:auto;
          }
        }
      }
      &__wrap {
        @include wrap-sp;
      }
      &__chart {
        padding:0 10px;
        img { width: 100%; height: auto;}
      }
      &__text { padding:0 20px; }
      &__cs {
        padding:0 20px;
        img {
          width:100%;
          height:auto;
        }
      }
      &modal {
        top:900px;
        &__wrap {
          width:730px;
          height:469px;
        }
      }
    }

    .video-modal{
      .video-cont{
        width: 80%;
        height: 30%;
      }
    }


  }
}
#character .gun#mouri .gun__wrap {
    height: 550px;
    background: url(/assets/img/character/gun/bg_mouri.png) center right no-repeat;
}
#character .gun#mouri {
  border-top: 12px solid #3EB370;
}




























.modal.mouri{
  .modal__control--int{
    display: none !important;
  }
}