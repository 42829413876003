#gallery {
  padding-top:152px;
  background:url(/assets/img/common/header_bg.png) top center no-repeat;
  background-size:100% auto;
  .content {
    padding-top:10px;
    padding-bottom:0;
  }
  .subnav {
    padding-bottom:16px;
    background: url(/assets/img/common/header_border.png) left bottom repeat-x;
    width: 100%;
    &__wrap { @include wrap-pc; }
    a {
      background:#c1a0cc;
      border-radius:6px;
      color:#FFF;
      padding:5px 20px;
      margin-right:15px;
      font-size:14px;
      display:inline-block;
      position:relative;
      &:hover {
        background:#aa4eab;
      }
      &.active {
        background:#aa4eab;
        cursor:default;
      }
    }
    &--new::after {
      content:'';
      position:absolute;
      width:29px;
      height:10px;
      top:-10px;
      left:4px;
      background:url(/assets/img/common/ico_subnavnew.png) 0 0 no-repeat;
      background-size:auto 10px;
    }
  }
  .cgnav {
    padding-bottom:16px;
    background: url(/assets/img/gallery/cgnav_bg.png) left top repeat-x;
    width: 100%;
    height:213px;
    &__wrap {
      @include wrap-pc;
      padding-top:75px;
      a {
        margin-left:20px;
        display:block;
        float:left;
        position:relative;
      }
    }
    &--new::after {
      content:'';
      position:absolute;
      width:29px;
      height:10px;
      top:-12px;
      left:2px;
      background:url(/assets/img/common/ico_subnavnew.png) 0 0 no-repeat;
      background-size:auto 10px;
    }
  }
  .cg__main li {
    display:none;
    &.active { display:block; }
    img {
      max-width:100%;
    }
  }
  .movie__block {
    padding-top:70px;
    padding-bottom:80px;
    .decbox{
      margin-bottom: 40px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  .stageevent__block {
    padding-top:70px;
    padding-bottom:80px;
  }
  .cg__block {
    padding-bottom:80px;
    .decbox {
      padding-top:88px;
    }
  }
  .gun__block {
    padding-top:70px;
    padding-bottom:80px;
    .decbox {
      margin-bottom:50px;
      &:last-child { margin-bottom:0; }
    }
  }
  .decbox {
    @include wrap-pc;
    box-sizing:border-box;
    background:
      url(/assets/img/gallery/floral_top.png) top 14px center no-repeat,
      url(/assets/img/gallery/floral_btm.png) bottom 14px center no-repeat,
      url(/assets/img/gallery/floral_grad.png) top left repeat-x,
      url(/assets/img/gallery/floral_pattern.png) top left repeat;
    border:4px solid #e8c95e;
    padding-top:32px;
    padding-bottom:90px;
    position:relative;
    text-align:center;
    &::before {
      content:'';
      position:absolute;
      z-index:1;
      top:6px; left:6px; bottom:6px; right:6px;
      border:1px solid #e8c95e;
    }
    &__wrap {
      width:840px;
      margin:0 auto;
    }
    &__title {
      margin-bottom:20px;
    }
    &__thumb {
      position:relative;
      z-index:2;
      width:670px;
      height:378px;
      margin:0 auto;
      iframe {
        width:100%;
        height:100%;
      }
    }
    &__subtitle {
      text-align:left;
      margin-bottom:10px;
      &--s {
        padding-left:20px;
      }
    }
    &__text {
      padding-left:20px;
      text-align:left;
      font-size:18px;
      line-height:32px;
      text-shadow:0 0 6px #FFF;
      position:relative;
      z-index:2;
      a {
        color:#2384C8;
        text-decoration:underline;
        &:hover { text-decoration:none; }
      }
    }
  }

//胸キュン
  .gun {
    &s {
      &__title {
        padding-top:15px;
        text-align:center;
        img {
          max-width:100%;
        }
      }
      &__nav {
        @include wrap-pc;
        padding:20px 0 25px;
        a, span {
          position:relative;
          display:inline-block;
          &.guns__nav--new::after {
            content:'';
            position:absolute;
            width:29px;
            height:10px;
            top:-10px;
            left:14px;
            background:url(/assets/img/common/ico_subnavnew.png) 0 0 no-repeat;
            background-size:auto 10px;
          }
          &.guns__nav--cs::after {
            content:'';
            position:absolute;
            width:100px;
            height:29px;
            top:0;
            left:0;
            background:url(/assets/img/gallery/mq/nav_cs.png) 0 0 no-repeat;
            background-size:100% auto;
          }
        }
      }
    }
    &__wrap {
      @include wrap-pc;
      position:relative;
    }
    &__btnmovie {
      position:absolute;
      top:8px;
      left:220px;
    }
    &__title {
    }
    &#oda {
      border-top:12px solid #dfb5f4;
      .gun__wrap { background:url(/assets/img/gallery/mq/bg_oda.png) center center no-repeat; background-size:450px auto; }
    }
    &#toyotomi {
      border-top:12px solid #dbc490;
      .gun__wrap { background:url(/assets/img/gallery/mq/bg_toyotomi.png) center center no-repeat; background-size:450px auto; }
    }
    &#uesugi {
      border-top:12px solid #a6c7ef;
      .gun__wrap { background:url(/assets/img/gallery/mq/bg_uesugi.png) center center no-repeat; background-size:450px auto; }
    }
    &#takeda {
      border-top:12px solid #efa2b5;
      .gun__wrap { background:url(/assets/img/gallery/mq/bg_takeda.png) center center no-repeat; background-size:450px auto; }
    }
    &#sanada {
      border-top:12px solid #e4ae8d;
      .gun__wrap { background:url(/assets/img/gallery/mq/bg_sanada.png) center center no-repeat; background-size:450px auto; }
    }
    &#date {
      border-top:12px solid #b4bafb;
      .gun__wrap { background:url(/assets/img/gallery/mq/bg_date.png) center center no-repeat; background-size:450px auto; }
    }
    &__char {
      &s {
        width:100%;
        display:table;
        border-spacing:14px;
      }
      display:table-cell;
    }
    &__illust {
      text-align:center;
      margin-bottom:5px;
    }
    &__btn {
      text-align:center;
    }

    &modal {
      display:none;
      width:100%;
      position:absolute;
      top:100px;
      left:0;
      overflow:hidden;
      z-index:2000;
      &__close {
        position:absolute;
        top:0;
        right:0;
      }
      &__cover {
        display:none;
        position:fixed;
        top:0;
        left:0;
        width:100%;
        height:100%;
        opacity:0.9;
        background-color:#d3ceea;
        z-index:1000;
      }
      &__wrap {
        padding-top:50px;
        width:344px;
        height:646px;
        margin:0 auto;
        position:relative;
      }
      &__body {
        border:10px solid #FFF;
        width:100%;
        height:100%;
        iframe {
          width:100%;
          height:100%;
        }
      }
    }
  }


}

@media (max-width: 750px) {

  #gallery {
    padding-top:140px;
    background:url(/assets/img/common/sp/bg_main.png) top 120px center repeat-x;
    min-width:auto;
    .cgnav{
      background-size: contain;
      height: 308px;
      &__wrap{
        position: relative;
        top: 17px;
      }
    }
    .cgnav {
      a img { width:100px; height:auto; }
    }
    .cgnav {
      a:nth-child(7){
        position: relative;
        top:17px;
        clear: both;
      }
    }
    .subnav {
      background: url(/assets/img/common/sp/header_border.png) left bottom repeat-x;
      padding-bottom:3px;
      &__wrap { @include wrap-sp; }
      a {
        padding:5px 20px;
        margin-right:0;
        margin-left:20px;
        margin-bottom:13px;
        font-size:20px;
      }
    }
    .decbox {
      width:750px;
      background-size:
        710px auto,
        710px auto,
        auto,
        auto;
      &__thumb {
        width:560px;
        height:325px;
      }
      &__info {
        width:650px;
      }
      &__wrap {
        width:710px;
      }
    }

//胸キュン
    .gun {
      &s {
        &__nav {
          @include wrap-sp;
          padding:30px 30px;
          img {
            width:110px;
            height:auto;
          }
          a, span {
            &.guns__nav--cs::after {
              width:110px;
              height:32px;
            }
          }
        }
      }
      &__char {
        img { width:130px; }
      }
      &#date {
        img { width:217px; }
      }
      &__wrap {
        @include wrap-sp;
      }
      &modal {
        top:900px;
        &__wrap {
        }
      }
    }

  }

}