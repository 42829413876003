.browser {
  width:100%;
  height:100%;
  position:fixed;
  top:0;
  left:0;
  z-index:9000;
  background:url(/assets/img/common/bg_content.png) 0 0 repeat;
  &__text {
    text-align:center;
    position:absolute;
    top:-50%;
    margin-top:50%;
    width:100%;
    display:block;
    img {
      max-width:80%;
    }
  }
}
.splash {
  display:none;
}
#top {
  @include minw-pc;
  background:url(/assets/img/top/bg_visual.png) top center no-repeat;
  background-size:100% auto;

  .visual {
    position:relative;
    overflow:hidden;
    &__wrap { @include wrap-pc; }
    &__image {
      display:none;
    }
    &__catch {
      width:100%;
      position:absolute;
      left:0;
      bottom:140px;
      z-index:200;
    }
    &__logo {
      width:100%;
      position:absolute;
      top:540px;
      left:-180px;
      text-align:right;
      z-index:200;
    }
    &__banners {
      @include wrap-pc;
      position:relative;
      overflow:visible;
      &--single { 
        top:-32px; 
        position:absolute;
        left:0;
        z-index:300;
      }
      .app-btn{
        position: absolute;
        &--i{
          top: 94px;
          left: 163px;
        }
        &--g{
          top: 94px;
          left: 560px;
        }
      }
    }
    &__banner {
      display:block;
      float:left;
      margin-left:366px;
      border:6px solid #6b6096;
      &.visual__modal {
        margin-left:10px;
      }
      img {
        border:1px solid #FFF;
        width:280px;
        height:auto;
        display:block;
      }
    }
    &__slide {
      position:absolute;
      z-index:101;
    }
    &__slides {
      display:block;
      overflow:visible;
      width:100%;
      height:810px;
      position:relative;
    }
    &__controls {
      width:100%;
      position:absolute;
      top:50%;
      left:0;
      overflow:visible;
      z-index:200;
      cursor: pointer;
      &--left {
        float:left;
        margin-left:25px;
      }
      &--right {
        float:right;
        margin-right:25px;
      }
    }

    @media (min-width: 1400px) {
      &__slides {
        height:850px;
      }
      &__controls {
        top:460px;
      }
    }

    &__border {
      display:block;
      background:url(/assets/img/top/border_visual.png) 0 0 repeat-x;
      width:100%;
      height:6px;
      position:absolute;
      left:0;
      bottom:0;
      z-index:200;
    }
  }
  .content {
    background:url(/assets/img/common/bg_content.png) 0 0 repeat;
    padding:183px 0 40px;
    &__wrap { @include wrap-pc; }
    &__banners {
      width:984px;
      margin:0 auto;
      position:relative;
      overflow:visible;
      display:table;
      a {
        display:table-cell;
        text-align:center;
      }
    }
    &__banner {
      float:left;
      width:320px;
      height:160px;
    }
    .columns {
      overflow:hidden;
      width:970px;
      margin:0 auto;
    }
    .block {
      width:350px;
      height:240px;
      background:#FFF;
      position:relative;
      box-shadow:1px 1px 5px #DDD;
      margin:5px;
      float:left;
      &--movie {
        width:240px;
        overflow:hidden;
        .slide {
          transition:all 0.5s ease 0s;
          padding:20px 0 0 15px;
          overflow:visible;
          width:9000px;
          position:absolute;
          bottom:18px;
          left:0;
          z-index:1;
          a {
            float:left;
            margin-right:15px;
            img.pc-on { display:block; }
          }
        }
        .moviecontrols {
          position:absolute;
          z-index:2;
          top:75px;
          left:0;
          width:100%;
          text-align:center;
          &--left { margin-right:5px; }
          &--right { margin-left:5px; }
          &--ind {
            display:inline-block;
            background:#CCC;
            width:9px;
            height:9px;
            border-radius:9px;
            line-height:10px;
          }
          &--active { background:#b569f1; }
        }
      }
      &__title {
        background:url(/assets/img/top/title_bg.png) 0 0 no-repeat;
        height:79px;
        img {
          padding:15px 0 0 10px;
        }
      }
      &__link {
        position:absolute;
        top:14px;
        right:9px;
      }
    }
    .twitter {
      iframe {
        min-height:161px !important;
      }
      &__feed {
        padding-left:10px;
      }
    }
    .news {
      height:161px;
      overflow-y:scroll;
      margin:0 0 0 10px;
      &__row {
        border-bottom:1px solid #edebfa;
        padding-bottom:5px;
        margin-bottom:5px;
        overflow:hidden;
      }
      &__date {
        color:#8b57da;
        font-size:12px;
        margin-bottom:5px;
      }
      &__title {
        a {
          color:#474747;
          font-size:14px;
          line-height:20px;
          &:hover {
            text-decoration:underline;
          }
        }
      }
    }
  }
  .modal {
    display:none;
    width:100%;
    position:absolute;
    top:100px;
    left:0;
    overflow:hidden;
    z-index:2000;
    &__close {
      position:absolute;
      top:0;
      right:0;
    }
    &__cover {
      display:none;
      position:fixed;
      top:0;
      left:0;
      width:100%;
      height:100%;
      opacity:0.9;
      background-color:#d3ceea;
      z-index:1000;
    }
    &__wrap {
      padding-top:50px;
      width:838px;
      height:530px;
      margin:0 auto;
      position:relative;
    }
    &__body {
      border:10px solid #FFF;
      width:100%;
      height:100%;
      iframe {
        width:100%;
        height:100%;
      }
    }
  }
}
.top-lead{
  position: absolute;
  left: 50%;
  bottom: 50px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 300;
}

@media (max-width: 750px) {
  .top-lead{
    width: 80%;
    bottom: 30px;
    img{width: 100%;}
  }
  .splash {
    display:block;
    width:100%;
    height:100%;
    position:fixed;
    top:0;
    left:0;
    z-index:9000;
    background:url(/assets/img/top/sp/splash_bg.png) 0 0 no-repeat #FFF;
    background-size:cover;
    &__center {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
    }
    &__cover {
      display:none;
      width:100%;
      height:100%;
      background:#FFF;
      position:absolute;
      top:0;
      left:0;
    }
    &__border {
      display:none;
      position:absolute;
      transform: translateY(-50%);
      top:50%;
      left:0;
      width:100%;
      text-align:center;
      img { width:100%; height:auto; }
    }
    &__char {
      -webkit-transition: all .3s ease 0s;
      transition: all .3s ease 0s;
      position:absolute;
      transform: translateY(-50%);
      top:50%;
      width:100%;
      text-align:center;
      img { width:100%; height:auto; }

      &.char1.active { top:50%; left:0px; }
      &.char2.active { top:50%; left:0px; }
      &.char3.active { top:50%; left:0px; }
      &.char4.active { top:50%; left:0px; }
      &.char5.active { top:50%; left:0px; }
      &.char6.active { top:50%; left:0px; }

      &.char1 { top:-100%; left:0px;}
      &.char2 { top:-100%; left:100%;}
      &.char3 { top:100%; left:100%;}
      &.char4 { top:100%; left:0px;}
      &.char5 { top:100%; left:-100%;}
      &.char6 { top:-100%; left:-100%;}
    }
    &__logo {
      text-align:center;
      width:100%;
      img { width:100%; height:auto; }
      visibility:hidden;
      position:absolute;
      transform: translateY(-50%);
      top:50%;
      left:0;
    }
  }
  #top {
    @include minw-sp;
    background:url(/assets/img/top/bg_visual.png) top center no-repeat;
    background-size:100% auto;
    .main {
      background:url(/assets/img/common/bg_content.png) 0 0 repeat;
    }
    .app-banner{
      position: static;
    }
    .visual {
      &__slides { display:none; }
      &__logo { display:none; }
      &__catch { display:none; }
      &__controls { display:none; }
      &__border { display:none; }
      &__wrap { @include wrap-sp; }
      &__image {
        display:block;
        margin-bottom:10px;
        img { display:block; }
      }
      &__banners {
        @include wrap-sp;
        text-align:center;
        margin:0 15px 10px;
        padding-top:10px;
        a {
          position:static;
          display:block;
          img { width:100%; }
        }
        .app-banner{
          > img{width: 100%;}
        }
        .app-btn{
          width: 160px;
          &--i{
            top: 86px;
            left: 138px;
          }
          &--g{
            top: 86px;
            left: 432px;
          }
        }
      }
      &__wrap {
        display:table;
        width:100%;
        border-spacing:20px 0;
      }
      &__banner {
        display:table-cell;
        text-align:center;
        float:none;
        margin:0;
        img { width:333px; }
      }
    }
    .content {
      padding:0 0 80px;
      &__wrap { @include wrap-sp; }
      &__banners {
        @include wrap-sp;
        margin:0 15px 10px;
        text-align:center;
        display:table;
        a {
          position:static;
          display:table-cell;
          img { width:100%; }
        }
      }
      .columns {
        margin:0 20px;
        overflow:visible;
        width:auto;
      }
      .block {
        width:100%;
        height:350px;
        background:#FFF;
        position:relative;
        box-shadow:1px 1px 5px #DDD;
        margin:0 0 18px 0;
        float:none;
        &--left {
          margin-bottom:20px;
        }
        &__title {
          background:url(/assets/img/top/sp/title_bg.png) 0 0 no-repeat;
          background-size:100% auto;
          height:76px;
          margin-bottom:12px;
          img {
            padding:10px 0 0 10px;
          }
        }
        &__link {
          top:10px;
          right:20px;
        }
        &--movie {
          height:390px;
          .slide {
            padding:0 0 25px 140px;
            bottom:0;
            a {
              img.pc-on { display:none; }
              img.pc-sp { display:block; }
            }

          }
        }
      }
      .news {
        margin:0 20px;
        height:262px;
        &__row {
          border-bottom:1px solid #edebfa;
          padding-bottom:10px;
          margin-bottom:10px;
          overflow:hidden;
        }
        &__date {
          color:#8b57da;
          font-size:18px;
          width:100%;
          float:none;
          margin-bottom:5px;
        }
        &__title {
          width:100%;
          float:none;
          a {
            line-height:20px;
            color:#474747;
            font-size:18px;
          }
        }
      }
      .twitter iframe {
        width:670px !important;
        height:262px !important;
      }
    }
    .modal {
      top:900px;
      &__wrap {
        width:730px;
        height:469px;
      }
    }
  }
}

#top {
  .visual {
    &__slide {
      -webkit-transition: all .7s ease 0s;
      transition: all .7s ease 0s;
      display:block;
      width:100%;
      height:200%;
      &.slide1 {
        background:url(/assets/img/top/visual_oda.png) 0 0 no-repeat;
        background-size:46% auto;
      }
      &.slide2 {
        background:url(/assets/img/top/visual_toyotomi.png) 0 0 no-repeat;
        background-size:27% auto;
      }
      &.slide3 {
        background:url(/assets/img/top/visual_uesugi.png) 0 0 no-repeat;
        background-size:30% auto;
      }
      &.slide4 {
        background:url(/assets/img/top/visual_takeda.png) 0 0 no-repeat;
        background-size:36% auto;
      }
      &.slide5 {
        background:url(/assets/img/top/visual_sanada.png) 0 0 no-repeat;
        background-size:33% auto;
      }
      &.slide6 {
        background:url(/assets/img/top/visual_date.png) 0 0 no-repeat;
        background-size:28% auto;
      }
      &.slide7 {
        background:url(/assets/img/top/visual_mouri.png) 0 0 no-repeat;
        background-size:50% auto;
      }

    }
    &__slides {
      &#state1 {
        .slide1 { background-size:75% auto; }

        .slide1 {top: 130px; left: -1%; z-index: 106;}
        .slide2 {top: 340px; left: 50%; z-index: 105;}
        .slide3 {top: 260px; left: 64%; z-index: 104;}
        .slide4 {top: 60px; left: 75%; z-index: 103;}
        .slide5 {top: -2px; left: 39%; z-index: 101;}
        .slide6 {top: 20px; left: -8%; z-index: 102;}
        .slide7 {top: 109px; left: -17%; z-index: 102;}
      }

      &#state2 {
        .slide2 { background-size:42% auto; }

        .slide2 {top: 130px;left: 13%;z-index: 106; }
        .slide3 {top: 290px;left: 48%;z-index: 105; }
        .slide4 {top: 260px;left: 64%;z-index: 104; }
        .slide5 {top: -80px;left: 69%;z-index: 103; }
        .slide6 {top: 110px;left: 43%;z-index: 101; }
        .slide1 {top: 240px;left: -14%;z-index: 102; }
        .slide7 {top: -7px;left: -14%;z-index: 100; }
      }

      &#state3 {
        .slide3 { background-size:50% auto; }

        .slide3 {top: 60px;left: 11%;z-index: 106; }
        .slide4 {top: 325px;left: 46%;z-index: 105; }
        .slide5 {top: 170px;left: 61%;z-index: 104; }
        .slide6 {top: 20px;left: 76%;z-index: 103; }
        .slide1 {top: 14px;left: -15%;z-index: 101; }
        .slide2 {top: 260px;left: -9%;z-index: 102; }
        .slide7 {top: 54px;left: 35%;z-index: 101; }
      }

      &#state4 {
        .slide4 { background-size:64% auto; }

        .slide4 {top: 120px;left: 4%;z-index: 106; }
        .slide5 {top: 170px;left: 37%;z-index: 105; }
        .slide6 {top: 310px;left: 64%;z-index: 104; }
        .slide1 {top: 109px;left: 43%;z-index: 103; }
        .slide2 {top: 20px;left: -9%;z-index: 101; }
        .slide3 {top: 190px;left: -6%;z-index: 102; }
        .slide7 {top: -10px;left: 61%;z-index: 102; }
      }

      &#state5 {
        .slide5 { background-size:56% auto; }

        .slide5 {
          top:-130px; left:0%; z-index:106;
          @media (max-width: 1200px) { top:-50px; }
        }
        .slide6 {top: 330px;left: 45%;z-index: 105; }
        .slide1 {top: 50px;left: 68%;z-index: 104; }
        .slide2 {top: 110px;left: 46%;z-index: 103; }
        .slide3 {top: -20px;left: -8%;z-index: 101; }
        .slide4 {top: 250px;left: -5%;z-index: 102; }
        .slide7 {top: 240px;left: 54%;z-index: 104; }
      }


      &#state6 {
        .slide6 { background-size:44% auto; }

        .slide6 {top: 150px;left: 9%;z-index: 106; }
        .slide1 {top: 240px;left: 67%;z-index: 105; }
        .slide2 {top: 100px;left: 70%;z-index: 104; }
        .slide3 {top: 90px;left: 42%;z-index: 103; }
        .slide4 {top: 20px;left: -12%;z-index: 101; }
        .slide5 {top: 110px;left: -8%;z-index: 102; }
        .slide7 {top: 270px;left: 39%;z-index: 105; }

      }

      &#state7 {
        .slide7 { background-size:83% auto; }

        .slide7 {top: 60px;left: -7%;z-index: 106; }
        .slide1 {top: 300px;left: 42%;z-index: 106; }
        .slide2 {top: 221px;left: 71%;z-index: 105; }
        .slide3 {top: -10px;left: 75%;z-index: 104; }
        .slide4 {top: 110px;left: 42%;z-index: 103; }
        .slide5 {top: -70px;left: -11%;z-index: 101; }
        .slide6 {top: 210px;left: 0%;z-index: 102; }

      }

    }
  }
}


















.main-ket{
  padding-top: 10px;
  background: url(/assets/img/common/bg_content.png) 0 0 repeat;
  img{
    width: 100%;
  }
}
.main-fixd{
  img{
    width: 100%;
  }
}
#top .visual__logo{
  .visual__wrap{
    width: auto;
  }
  left: 50%;
  top: 68%;
  text-align: center;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  img{
    width: 25%;
  }
}















.top-fade{
  position: relative;
  width: 100%;
  .top-fade-slide{
    // position:absolute;
    // top: 0;
    // left: 50%;
    // -webkit-transform: translateX(-50%);
    // -ms-transform: translateX(-50%);
    // transform: translateX(-50%);
    // opacity: 0;
    // transition: all 1.2s;
    // width: 100%;
    // display: block;
    // transition:all 3s;
    // height: 100%;
    // text-align: center;
    // background-repeat: no-repeat;
    // background-size: 110%;
    // background-position: center bottom;
    img{width: 100%;}
    // &.current{
    //   opacity: 1;
    //   height: 10vw;
    // }
    // &:nth-child(1){background-image: url(/assets/img/top/fade_slide_mouri.png);}
    // &:nth-child(2){background-image: url(/assets/img/top/fade_slide_oda.png);}
    // &:nth-child(3){background-image: url(/assets/img/top/fade_slide_toyotomi.png);}
    // &:nth-child(4){background-image: url(/assets/img/top/fade_slide_uesugi.png);}
    // &:nth-child(5){background-image: url(/assets/img/top/fade_slide_takeda.png);}
    // &:nth-child(6){background-image: url(/assets/img/top/fade_slide_sanada.png);}
    // &:nth-child(7){background-image: url(/assets/img/top/fade_slide_date.png);}
  }
}

.slide-token{
  width: 100%;
  height: 40px;
  display: none;
}

.visual__banners--single.app-banner{
  top: 10px !important;
}
#top{
  min-width: 1200px;
  .content{
    padding: 203px 0 40px !important;
  }
}
@media screen and (max-width: 750px){
  #top{
  min-width: 320px;
  .content{
    padding: 143px 0 40px !important;
  }
}
}