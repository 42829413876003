#special {
  padding-top:152px;
  background:url(/assets/img/common/header_bg.png) top center no-repeat;
  background-size:100% auto;
  .main{
    .content {
      padding-top: 16px;
      padding-bottom:0;
    }
  }
  .subnav {
    padding-bottom:16px;
    background: url(/assets/img/common/header_border.png) left bottom repeat-x;
    width: 100%;
    &__wrap { @include wrap-pc; }
    a {
      background:#c1a0cc;
      border-radius:6px;
      color:#FFF;
      padding:5px 20px;
      margin-right:15px;
      font-size:14px;
      display:inline-block;
      position:relative;
      &:hover {
        background:#aa4eab;
      }
      &.active {
        background:#aa4eab;
        cursor:default;
      }
      &:last-child{
        margin-top: 20px;
      }
    }
    &--new::after {
      content:'';
      position:absolute;
      width:29px;
      height:10px;
      top:-10px;
      left:4px;
      background:url(/assets/img/common/ico_subnavnew.png) 0 0 no-repeat;
      background-size:auto 10px;
    }
  }
  .title {
    width:100%;
    height:96px;
    margin-top: 20px;
    text-align:center;
    img { padding-top:0; max-width:100%; }
    background:url(/assets/img/special/title_bg.png) top center no-repeat;
  }
  .title--fankit{
    margin-top: 20px;
    margin-bottom: 25px;
  }
  .commentary{
    position: relative;
    z-index: 100;
    font-size: 18px;
    color: #111111;
    line-height: 1.8;
    margin-bottom: 20px;
    a{
      &:hover{
        text-decoration: underline;
      }
    }
  }
  .decbox {
    @include wrap-pc;
    box-sizing:border-box;
    background:
      url(/assets/img/special/floral_top.png) top 14px center no-repeat,
      url(/assets/img/special/floral_btm.png) bottom 14px center no-repeat,
      url(/assets/img/special/floral_grad.png) top left repeat-x,
      url(/assets/img/special/floral_pattern.png) top left repeat;
    border:4px solid #e8c95e;
    padding:32px 0;
    position:relative;
    text-align:center;
    &::before {
      content:'';
      position:absolute;
      z-index:1;
      top:6px; left:6px; bottom:6px; right:6px;
      border:1px solid #e8c95e;
    }
    &__thumb {
      margin-bottom:26px;
      position:relative;
      z-index:2;
    }
    &__title {
      margin-bottom:20px;
    }
    &__text {
      text-align:center;
      margin-bottom:20px;
      font-size:18px;
      line-height:32px;
      text-shadow:0 0 6px #FFF;
      position:relative;
      z-index:2;
      a {
        color:#2384C8;
        text-decoration:underline;
        &:hover { text-decoration:none; }
      }
      &--twitter {
        margin-bottom:70px;
      }
    }
    &__center {
      text-align:center;
      margin-bottom:10px;
    }
    &__info {
      width:760px;
      margin:0 auto;
      text-align:left;
      font-size:14px;
      margin-bottom:90px;
      position:relative;
      z-index:2;
      a {
        color:#2384C8;
        text-decoration:underline;
        &:hover { text-decoration:none; }
      }
      li {
        padding-left:20px;
        position:relative;
        &::before {
          content: '・';
          position:absolute;
          top:0;
          left:0;
        }
      }
    }

  }
  .camp {
    background:url(/assets/img/special/project_bg.png) top right 20px no-repeat;
    background-attachment:fixed;
    &__visual {
      text-align:center;
      overflow:hidden;
      img {
        margin-top:-6px;
        margin-left:-5px;
        margin-bottom:-6px;
      }
    }
    &__step {
      width:100%;
      position:relative;
      z-index:2;
      &--1 {
        background:url(/assets/img/special/camp_03_step01_bg.png) top 0 left 30px no-repeat,
        url(/assets/img/special/camp_03_step_arrow.png) bottom 20px left 75px no-repeat;
        .camp__steptitle {
          height:220px;
        }
      }
      &--2 {
        background:url(/assets/img/special/camp_03_step02_bg.png) top 0 left 30px no-repeat,
        url(/assets/img/special/camp_03_step_arrow.png) bottom 20px left 75px no-repeat;
        padding-bottom:100px;
        .camp__steptitle {
          margin-bottom:10px;
        }
      }
      &--3 {
        background:url(/assets/img/special/camp_03_step03_bg.png) top 0 left 30px no-repeat;
        margin-bottom:50px;
        .camp__steptitle { height:60px; }
      }
      &title {
        padding-top:7px;
        padding-left:200px;
        text-align:left;
      }
      &btn {
        display:block;
        margin-top:15px;
      }
      &banners {
        padding-left:140px;
        overflow:hidden;
      }
      &banner {
        float:left;
        width:376px;
        height:149px;
        margin-right:30px;
      }
    }
  }
  .about__block {
    padding-bottom:35px;
  }
  .cast {
    background:url(/assets/img/about/cast_bg.png) center center no-repeat;
    background-attachment:fixed;
    &__list {
      width:690px;
      margin:0 auto;
      overflow:hidden;
      margin-bottom:50px;
      z-index:100;
    }
    &__line {
      width:50%;
      height:38px;
      float:left;
      overflow:hidden;
      text-align:left;
      position:relative;
      z-index:2;
      &--vc {
        text-indent:5px;
        width:56%;
        float:left;
        font-size:24px;
      }
      &--char {
        width:44%;
        float:left;
        font-size:19px;
        padding-top:5px;
      }
      &:nth-child(odd) {
        .cast__line--vc { width:50%; }
        .cast__line--char { width:50%; }
      }
    }
    &__memo {
      margin-bottom:20px;
      width:100%;
      text-align:center;
    }
    &__comingsoon {
      font-size:20px;
    }
  }
  .illust {
    &__list {
      width:760px;
      margin:0 auto;
      overflow:hidden;
      position:relative;
      z-index:2;
    }
    &__banner {
      float:left;
      width:50%;
      display:block;
      text-align:center;
      margin-bottom:30px;
      img {
        display:block;
      }
    }
  }
  .embedded-list{
    margin: 0 40px;
    &:after{
      content: "";   
      display: block;   
      height: 0;   
      clear: both;   
      visibility: hidden;
    }
  }
  .embedded{
    position: relative;
    width: 48%;
    float: left;
    background-color: #fff;
    margin-right: 2%;
    margin-bottom: 2%;
    // box-shadow: 1px 1px 5px #DDD;
    z-index: 50;
    &:nth-child(2n){
      margin-right: 0;
    }
    // &:before{
    //   content: '';
    //   position: absolute;
    //   z-index: 30;
    //   top: 3px;
    //   left: 3px;
    //   bottom: 3px;
    //   right: 3px;
    //   border: 1px solid #e8ca24;
    // }
    &__title{
      position: relative;
      z-index: 30;
      img{width: 100%;}
    }
    &__tweet{
      position: relative;
      top: -20px;
      z-index: 20;
      .twitter-tweet{
        // &:before{
        //   content: '';
        //   position: absolute;
        //   z-index: 1;
        //   top: 0px;
        //   left: 0px;
        //   bottom: 0px;
        //   right: 0px;
        //   border: 1px solid #fff;
        // }
      }
    }
  }
  .normal-inner{
    width: 960px;
    margin: 0 auto 10px auto;
    padding: 20px 0;
    background: #ffffff; /* Old browsers */
    background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0.7) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */


  }
  .normal-inner--center{
    text-align: center;
  }
  .radio-key{
    width: 960px;
    margin: 0 auto;
    box-shadow: 0px 2px 10px rgba(100, 100, 100, 0.4);
    img{
      width: 100%;
      height: auto;
      display: block;
    }
  }

  .taping-nav{
    margin-bottom: 30px;
    li{
      display: inline-block;
    }
  }

  .taping-area{
    padding-bottom: 50px;
  }
  .taping-title{
    margin-bottom: 30px;
    text-align: left;
    padding-left: 10px;
  }
  .taping-list{
    width: 820px;
    margin: 0 auto;
    overflow: hidden;
  }
  .taping{
    width: 380px;
    overflow: hidden;
    padding: 15px;
    margin-bottom: 20px;
    &:nth-child(odd){
      float: left;
    }
    &:nth-child(even){
      float: right;
    }
    &__thum{
      margin-top: 15px;
      margin-right: 10px;
      float: left;
      img{
        display: block;
      }
    }
    &__btn{
      float: left;
      li{
        &:hover{
          cursor: pointer;
          opacity: 0.7;
        }
      }
    }
  }


  #taping-oda{
    .taping-title{
      border-top: 3px solid #e6c6f6;
    }
    .taping{
      background: #f4e7f9;
    }
  }
  #taping-toyotomi{
    .taping-title{
      border-top: 3px solid #dcc693;
    }
    .taping{
      background: #f9f3e4;
    }
  }
  #taping-uesugi{
    .taping-title{
      border-top: 3px solid #a5d2f2;
    }
    .taping{
      background: #eff1f6;
    }
  }
  #taping-takeda{
    .taping-title{
      border-top: 3px solid #efa8ba;
    }
    .taping{
      background: #fbeded;
    }
  }
  #taping-sanada{
    .taping-title{
      border-top: 3px solid #e6b190;
    }
    .taping{
      background: #faebe4;
    }
  }
  #taping-date{
    .taping-title{
      border-top: 3px solid #bec6fc;
    }
    .taping{
      background: #eff0fd;
    }
  }
  #taping-mouri{
    .taping-title{
      border-top: 3px solid #5FDC94;
    }
    .taping{
      background: #d9f8e6;
    }
  }

  .modal-wrapper{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(160, 160, 160, 0.9);
    z-index: 100;
    display: none;
  }
  .modal-img-inner{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    img{width: 100%;}
  }
  .modal-close{
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    img{width: 100%;}
    z-index: 100;
  }
  .modal-img--twihead,
  .modal-img--wallpc{
    width: 960px;
  }
  .modal-img--twiico,
  .modal-img--wallsp{
    width: 300px;
  }
  .yonkoma-list{
    width: 920px;
    margin: 0 auto 10px auto;
    overflow: hidden;
    position: relative;
    z-index: 30;
    padding-top: 10px;
    li{
      position: relative;
      width: 300px;
      float: left;
      margin-right: 10px;
      &:hover{
        opacity: 0.7;
      }
      &:nth-child(3n){margin-right: 0;}
      &.new-koma:before{
        position: absolute;
        -webkit-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        transform: translateY(-100%);
        left: 2px;
        content: "";
        width: 29px;
        height: 10px;
        background: url(/assets/img/common/ico_subnavnew.png) 0 0 no-repeat;
        background-size: cover;
      }
      img{width: 100%;}
    }
  }
  .yonkoma-page{
    margin-bottom: 10px;
  }
  .yonkoma-an{
    position: relative;
    width: 505px;
    margin: 0 auto;
    border: 1px solid #e8ca24;
    z-index: 100;
    box-shadow: 0px 2px 5px #ccc;
    img{
      width: 100%;
      display: block;
    }
    .twitter-tweet{
      width: auto !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    .EmbeddedTweet-tweet{
      border: none !important;
    }
  }
  .yonkoma-main{
    position: relative;
    z-index: 100;
  }
  .yonkoma-cont{
    position: absolute;
    top: 50%; 
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 200;
    &:hover{
      opacity: 0.7;
    }
  }
  .yonkoma-cont--next{
    right: 30px;
  }
  .yonkoma-cont--prev{
    left: 30px;
  }
  #fankit{
    .decbox{
      &:before{
        content: none;
      }
    }
  }
  .yaku-box{
    margin-bottom: 60px;
    &__title{margin-bottom: 10px;}
  }

  .cast__list{
    width: 777px;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
  }
  .cast-btn:hover{
    opacity: 0.7;
    cursor: pointer;
  }
  .cast__line{
    float: left;
    height: auto;
    margin-bottom: 15px;
    text-align: center;
    &:nth-child(2){
      position: relative;
      top: 7px;
    }
    &:nth-child(2n){
      margin-right: 0;
    }
  }
  .taisyo-btn{
    position: relative;
    z-index: 100;
    &:hover{
      opacity: 0.7;
    }
  }




















}





@media (max-width: 750px) {

  #special {
    padding-top:140px;
    background:url(/assets/img/common/sp/bg_main.png) top 120px center repeat-x;
    min-width:auto;
    .title {
      display: block !important;
      width:100%;
      height:96px;
      text-align:center;
      img { padding-top:5px; max-width:100%; }
      background:url(/assets/img/special/title_bg.png) top center no-repeat;
    }
    .subnav {
      background: url(/assets/img/common/sp/header_border.png) left bottom repeat-x;
      padding-bottom:3px;
      &__wrap { @include wrap-sp; }
      a {
        padding:5px 20px;
        margin-right:0;
        margin-left:20px;
        margin-bottom:13px;
        font-size:20px;
      }
    }
    .decbox {
      width:750px;
      background-size:
        710px auto,
        710px auto,
        auto,
        auto;
      &__info {
        width:650px;
      }
      &__center {
        img{
          width: 85%;
        }
      }
    }
    .camp {
      &__visual {
        img {
          width:100%;
          height:auto;
        }
      }
      &__step {
        padding-right:30px;
        .camp__steptitle {
          img {
            width:100%;
            height:auto;
          }
        }
        &--2 {
          padding-bottom:60px;
        }
        &--3 {
          .camp__steptitle {
            img {
              width:auto;
              height:auto;
            }
          }
        }
        &banner {
          width:270px;
          height:auto;
          margin-right:10px;
          img {
            width:100%;
            height:auto;
            display:block;
          }
        }
      }
    }
    .illust__list {
      width:700px;
      text-align:center;
    }
    .illust__banner {
      margin-bottom:20px;
      img {
        display:inline;
        width:95%;
      }
    }
    .embedded{
      width: auto;
      margin-right: 0;
      float: none;
      &__tweet{
        width: 80%;
        margin: 0 auto;
      }
    }
    .normal-inner{
      width: auto;
    }
    .radio-key{
      width: auto;
    }
    .taping-list{
      width: auto;
    }
    .taping{
      &:nth-child(odd),
      &:nth-child(even){
        width: 80%;
        margin: 0 auto;
        float: none;
        margin-bottom: 3px;
      }
      &__thum{
        width: 48%;
        margin-right: 4%;
        margin-top: 4%;
        img{width: 100%;}
      }
      &__btn{
        width: 48%;
        img{width: 100%;}
      }
    }
    .modal-img-inner{
      width: 80%;
    }
    .modal-img--twihead,
    .modal-img--wallpc,
    .modal-img--twiico{
      width: auto;
    }
    .modal-img--wallsp{
      width: 70%;
      margin: 0 auto;
    }


    .yonkoma-list{
      width: 95%;
      li{
        width: 33.3333%;
        margin: 0 auto;
      }
    }

    .cast__list{
      width: auto;
    }
    .cast__line{
      float: none;
      width: 380px;
      margin: 0 auto 20px auto;
      &:nth-child(2n){
        margin-right: auto;
      }
    }



  }
}



  

.mb10{margin-bottom: 10px;}
.mb20{margin-bottom: 20px;}
.mb30{margin-bottom: 30px;}
.mb40{margin-bottom: 40px;}
.mb50{margin-bottom: 50px;}