body {
  font-family: "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
  font-size: 14px;
  color: #000;
  position: relative;
  line-height: 1.5;
}
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}
a {
  color: #323232;
  text-decoration: none;
  &:hover {
    img.fade {
      transition: opacity 0.3s;
      opacity: 0.7;
    }
    img:not(.ov) {
      -webkit-transition: all .3s linear 0s;
      transition: all .3s linear 0s;
      -webkit-filter: drop-shadow(0px 0px 3px #FFF);
      filter:         drop-shadow(0px 0px 3px #FFF);

    }
  }
}

sub {
  vertical-align: sub;
}
sup {
  vertical-align: super;
}

.pc-on {
  display: inline-block;
}
.sp-on {
  display: none;
}
@media (max-width: 750px) {
  .pc-on {
    display: none;
  }
  .sp-on {
    display: inline-block;
  }
}


.l-header {
  position:absolute;
  top:0;
  left:0;
  z-index:1000;
  width:100%;
  .gnavi {
    @include wrap-pc;
    overflow:hidden;
    &__list {
      float:left;
      margin-right:6px;
      &--last { margin-right:0; }
      width:132px;
      height:159px;
      position:relative;
    }
    &__link {
      position:absolute;
      top:-15px;
      left:0;
      -webkit-transition: top 0.5s ease 0.2s, background-position 0.4s linear 0.2s;
      transition: top 0.5s ease 0.2s, background-position 0.4s linear 0.2s;
      background-position:-100px 0px;
      &:hover {
        background-position:80px 0px;
        top:0;
      }
      &::after{
        content:'';
        position: absolute;
        pointer-events: none;
        top:0; left:0; right:0; bottom: 0;
        background: radial-gradient(-50px 0,ellipse farthest-side, rgba(255,255,255,0) 90%,rgba(255,255,255,.5) 98%,rgba(255,255,255,0) 100%) no-repeat;
        background: radial-gradient(ellipse farthest-side at -50px 0, rgba(255,255,255,0) 90%,rgba(255,255,255,.5) 98%,rgba(255,255,255,0) 100%) no-repeat;
        background-position: inherit;
        -webkit-mask: url('/assets/img/common/gnavi_mask.png') center;
        mask: url('/#mask-firefox');
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { background:none; }
      }
      &._on {
        top:0;
      }
      &--new::before {
        content:'';
        background:url(/assets/img/common/gnavi_new.png) top center no-repeat;
        width:132px;
        height:159px;
        display:block;
        position:absolute;
        top:60px;
        left:0;
        z-index:300;
      }
    }
    &__link--comingsoon {
      position:absolute;
      top:-15px;
      left:0;
    }
    &__text {
      display:none;
    }
  }
  &__bg {
    @include minw-pc;
    position:absolute;
    top:0;
    left:0;
    height:200px;
    z-index:100;
    background:url(/assets/img/common/bg_header.png) top center no-repeat;
    background-size:100% auto;
  }
}
.l-footer {
  @include minw-pc;
  overflow:visible;
  position:relative;
  &__wrap {
    @include wrap-pc;
    overflow:hidden;
  }
  .footertop {
    background:url(/assets/img/common/bg_footer.png) 0 0 repeat;
    padding:35px 0;
    &__sns {
      &btns {
        //padding-top:45px;
        width:470px;
        float:left;
        overflow:hidden;
      }
      &btn {
        float:left;
        margin-right:5px;
        &--twitter .pc-on { width: 35px; }
        &--facebook .pc-on { width: 35px; }
        &--line .pc-on { width: 35px; }
        &--faq a {
          color:#FFF;
          text-decoration:underline;
          position:relative;
          padding-left:15px;
          display:inline-block;
          margin-top:10px;
          &:hover { text-decoration:none; }
          &::after {
            content:'・';
            position:absolute;
            top:0;
            left:0;
          }
        }
      }
    }
    &__info {
      width:490px;
      float:right;
      background:rgba(255,255,255,0.1);
      border:2px solid #a17d00;
    }
    &__table {
      border:1px solid #745c0b;
      margin:3px;
      padding:10px 15px;
      color:#FFF;
      font-size:12px;
      &row {
        overflow:hidden;
      }
      &title {
        background:url(/assets/img/common/ico_footer_spec.png) left center no-repeat;
        padding-left:18px;
        float:left;
        width:84px;
      }
    }
  }
  .footerbtm {
    padding:24px 0;
    &__company {
      width:630px;
      margin:0 auto 20px;
      td {
        vertical-align:middle;
      }
    }
    &__copyright {
      text-align:center;
      font-size:12px;
      color:#999;
    }
  }
  .footerpagetop {
    opacity:0;
    position:fixed;
    right:20px;
    bottom:20px;
    transition:all 0.5s linear 0s;
    z-index:3;
    &.active {
      opacity:1;
    }
  }
}
.main {
  &__wrap {
    @include wrap-pc;
    overflow:hidden;
  }
  &__header {
    padding-bottom:10px;
    background:url(/assets/img/common/header_border.png) left bottom repeat-x;
    width:100%;
  }
  &__title {
    padding-top:24px;
    float:left;
  }
  &__logo {
    float:right;
  }
}
.content {
  padding:30px 0 50px;
  background:url(/assets/img/common/bg_content.png) 0 0 repeat;
  overflow:hidden;
}
@supports (-ms-accelerator:true) {
  .l-header .gnavi__link::after {
    background:none;
  }
}

@media (max-width: 750px) {
  body { -webkit-text-size-adjust: none; }
  .wrapper { overflow:hidden; min-width:750px !important; }
  svg { display:none; }
  .main { overflow:hidden; }
  .l-header {
    position:fixed;
    top:0;
    left:0;
    z-index:1000;
    width:100%;
    .menu {
      position:absolute;
      top:0;
      left:0;
      z-index:1002;
      width:100%;
      height:134px;
      background:url(/assets/img/common/sp/bg_header.png) top center repeat-x;
      display:table;
      background-size:auto 134px;
      &__twitter {
        display:table-cell;
        vertical-align:top;
        text-align:left;
        padding-top:20px;
        padding-left:10px;

      }
      &__toggle {
        display:table-cell;
        vertical-align:top;
        text-align:right;
        padding-right:10px;
      }
    }
    .gnavi {
      @include wrap-sp;
      overflow:hidden;
      background:url(/assets/img/top/border_visual.png) bottom left repeat-x, url(/assets/img/common/sp/bg_gnavilist.png) 0 0 repeat;
      width:100%;
      position:absolute;
      left:0;
      top:-1000px;
      transition: top 0.5s ease 0s;
      z-index:1001;
      &.open {
        top:120px;
      }
      &__under {
        width:100%;
        height:100%;
        position:fixed;
        top:0;
        left:0;
        display:none;
        &.open {
          display:block;
        }
      }
      &__list {
        float:none;
        width:auto;
        height:auto;
        margin-right:0;
        margin:0 75px;
        display:block;
        &--last .gnavi__link { border-bottom:none; }
      }
      &__link {
        position:static;
        color:#2b4aa6;
        font-size:28px;
        text-align:center;
        display:block;
        padding:30px 0;
        border-bottom:2px solid #dad3f7;
        &::after {
          background:none;
        }
        &--new::before {
          width:38px;
          height:16px;
          top:43px;
          left:30px;
        }

      }
      &__link--comingsoon {
        position:static;
        color:#CCC; 
        font-size:28px;
        text-align:center;
        display:block;
        padding:30px 0;
        border-bottom:2px solid #dad3f7;
      }
      &__img {
        display:none;
      }
      &__text {
        display:block;
      }
    }
    &__bg {
      display:none;
    }

  }

  .l-footer {
    @include minw-sp;
    min-width:750px !important;
    &__wrap { @include wrap-sp; }
    .footertop {
      background:url(/assets/img/common/bg_footer.png) 0 0 repeat;
      padding:30px 0 110px;
      &__sns {
        &btns {
          width:520px;
          float:none;
          margin:0 auto 30px;
          padding-top:0;
        }
        &btn {
          float:left;
          margin-right:16px;
          &--follow {
            margin-right:0;
            float:none;
            margin-bottom:30px;
            text-align:center;
          }
          &--twitter {
            margin-left:91px;
          }
          &--faq {
            position:absolute;
            bottom:145px;
            a {
              // margin-top:22px;
              // margin-left:180px;
              font-size:20px;
            }
          }
        }
      }
      &__info {
        width:auto;
        margin:0 20px 20px;
        float:none;
        background:rgba(255,255,255,0.1);
        border:2px solid #a17d00;
      }
      &__table {
        font-size:20px;
        &title {
          background:url(/assets/img/common/ico_footer_spec.png) left center no-repeat;
          background-size:15px auto;
          padding-left:28px;
          float:left;
          width:140px;
        }
      }
    }
    .footerbtm {
      padding:24px 0 5px;
      &__company {
        width:700px;
        td { text-align:center; }
      }
      &__copyright {
        font-size:14px;
      }
    }
    .footerpagetop {
      display:none;
      position:fixed;
      top:auto;
      bottom:100px;
      right:0;
    }
  }

  .main {
    &__wrap {
      @include wrap-sp;
      padding:0 20px;
    }
    &__header {
      background:url(/assets/img/common/sp/header_border.png) left bottom repeat-x;
      width:100%;
      padding-bottom:10px;
    }
  }
  .content {
    padding:30px 0 50px;
  }

}
