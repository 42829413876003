#about {
  padding-top:152px;
  background:url(/assets/img/common/header_bg.png) top center no-repeat;
  background-size:100% auto;
  .content { padding-bottom:0; }
  .title {
    width:100%;
    height:80px;
    text-align:center;
    img { padding-top:10px; }
    background:url(/assets/img/about/title_bg.png) top center no-repeat;
    margin-bottom:20px;
  }
  .decbox {
    @include wrap-pc;
    box-sizing:border-box;
    background:
      url(/assets/img/about/floral_top.png) top 14px center no-repeat,
      url(/assets/img/about/floral_btm.png) bottom 14px center no-repeat,
      url(/assets/img/about/floral_grad.png) top left repeat-x,
      url(/assets/img/about/floral_pattern.png) top left repeat;
    border:4px solid #e8c95e;
    padding:32px 0;
    position:relative;
    text-align:center;
    &::before {
      content:'';
      position:absolute;
      z-index:1;
      top:6px; left:6px; bottom:6px; right:6px;
      border:1px solid #e8c95e;
    }
    .decbox__thumb {
      position:relative;
      z-index:2;
      border:14px solid #6b6096;
      width:700px;
      height:405px;
      margin:0 auto 26px;
      iframe {
        width:100%;
        height:100%;
      }
    }
    .decbox__title {
      margin-bottom:20px;
    }
    .decbox__text {
      text-align:center;
      margin-bottom:20px;
      font-size:18px;
      line-height:32px;
      text-shadow:0 0 6px #FFF;
    }

  }
  .project {
    background:url(/assets/img/about/project_bg.png) top right 20px no-repeat;
    background-attachment:fixed;
  }
  .about__block {
    padding-bottom:35px;
  }
  .cast {
    padding-bottom:80px;
    background:url(/assets/img/about/project_bg.png) top right 20px no-repeat;
    background-attachment:fixed;
    &__list {
      width:777px;
      overflow:hidden;
      margin:0 auto 20px;
      z-index:100;
    }
    &__line {
      display:table;
      width:100%;
      position:relative;
      z-index:2;
      border-spacing:10px 5px;
      a {
        display:table-cell;
        width:50%;
        vertical-align:bottom;
        text-align:center;
        img { display:inline; }
      }
    }
    &__memo {
      margin-bottom:20px;
      width:100%;
      text-align:center;
    }
    &__comingsoon {
      font-size:20px;
    }
  }
  .illust {
    background:url(/assets/img/about/project_bg.png) top right 20px no-repeat;
    background-attachment:fixed;
    &__list {
      width:760px;
      margin:0 auto;
      overflow:hidden;
      position:relative;
      z-index:2;
    }
    &__banner {
      float:left;
      width:50%;
      display:block;
      text-align:center;
      margin-bottom:30px;
      img {
        display:block;
        opacity:1;
        transition:all 0.5s linear 0s;
      }
      &:hover {
        img { opacity:0.8; filter:none; }
      }
    }
  }

}

@media (max-width: 750px) {

  #about {
    padding-top:140px;
    background:url(/assets/img/common/sp/bg_main.png) top 120px center repeat-x;
    min-width:auto;
    .decbox {
      width:750px;
      background-size:
        710px auto,
        710px auto,
        auto,
        auto;
      .decbox__thumb {
        width:560px;
        height:325px;
      }
    }
    .cast__list {
      width:100%;
      img { width:350px; }
    }
    .cast__line {
      border-spacing:10px 0px;
    }
    .illust__list {
      width:700px;
      text-align:center;
    }
    .illust__banner {
      margin-bottom:20px;
      img {
        display:inline;
        width:95%;
      }
    }
  }

}