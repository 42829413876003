@mixin clearfix {
  overflow: hidden;
}
@mixin legacy-pie-clearfix {
  &:after {
    content: ".";
    visibility: hidden;
    height: auto;
    line-height: 0;
    clear: both;
    display: block;
  }
}

@mixin rem-font-size($size) {
  font-size: $size + px;
  font-size: ($size / 10) * 1rem;
}

@mixin wrap-pc {
  width:960px;
  margin:0 auto;
}
@mixin wrap-sp {
  width:auto;
  margin:0;
}

@mixin minw-pc {
  width:100%;
  min-width:960px;
}
@mixin minw-sp {
  width:100%;
  min-width:auto;
}