#system {
  padding-top:152px;
  background:url(/assets/img/common/header_bg.png) top center no-repeat;
  background-size:100% auto;
  .content {
    padding-top:10px;
    padding-bottom:0;
  }
  .subnav {
    padding-bottom:16px;
    background: url(/assets/img/common/header_border.png) left bottom repeat-x;
    width: 100%;
    &__wrap { @include wrap-pc; }
    a {
      background:#c1a0cc;
      border-radius:6px;
      color:#FFF;
      padding:5px 20px;
      margin-right:15px;
      font-size:14px;
      display:inline-block;
      position:relative;
      &:hover {
        background:#aa4eab;
      }
      &.active {
        background:#aa4eab;
        cursor:default;
      }
    }
    &--new::after {
      content:'';
      position:absolute;
      width:29px;
      height:10px;
      top:-10px;
      left:4px;
      background:url(/assets/img/common/ico_subnavnew.png) 0 0 no-repeat;
      background-size:auto 10px;
    }
  }
  .system__block {
    padding-top:25px;
    padding-bottom:50px;
    background: url(/assets/img/system/battle_bg.jpg) top center repeat;
    background-attachment:fixed;
  }
  .decbox {
    @include wrap-pc;
    box-sizing:border-box;
    background:
      url(/assets/img/system/floral_top.png) top 14px center no-repeat,
      url(/assets/img/system/floral_btm.png) bottom 14px center no-repeat,
      url(/assets/img/system/floral_grad.png) top left repeat-x,
      url(/assets/img/system/floral_pattern.png) top left repeat;
    border:4px solid #e8c95e;
    padding:32px 0 60px;
    position:relative;
    text-align:center;
    margin-bottom:30px;
    &::before {
      content:'';
      position:absolute;
      z-index:1;
      top:6px; left:6px; bottom:6px; right:6px;
      border:1px solid #e8c95e;
    }
    &__wrap {
      width:840px;
      margin:0 auto;
    }
    &__title {
      margin-bottom:20px;
    }
    &__subtitle {
      text-align:left;
      margin-bottom:10px;
      img { max-width:100%; }
      &--s {
        padding-left:20px;
      }
    }
    &__text {
      padding-left:20px;
      text-align:left;
      font-size:18px;
      line-height:32px;
      text-shadow:0 0 6px #FFF;
      position:relative;
      z-index:2;
      a {
        color:#2384C8;
        text-decoration:underline;
        &:hover { text-decoration:none; }
      }
      &--small {
        line-height:12px;
        font-size:12px;
      }
    }
    &__notice {
      text-align:left;
      font-size:14px;
      padding:40px 0 0 20px;
    }
    &__thumb {
      &s {
        width:860px;
        margin:0 auto;
        display:table;
        border-spacing:0 15px;
      }
      &caption {
        text-align:left;
        padding-left:10px;
      }
      &row {
        display:table-row;
      }
      display:table-cell;
    }
    &__center {
      position:relative;
      z-index:2;
      img { max-width:95%; }
      &--illust {
        padding:20px 0;
      }
    }
  }
  .flow {
    &__block {
      .decbox__subtitle {
        width:810px;
        margin:0 auto;
      }
      background:url(/assets/img/system/flow_arrow.png) bottom 0 left 90px no-repeat;
      padding-bottom:65px;
      margin-bottom:10px;
      position:relative;
      z-index:2;
      &--kyuketsu {
        padding-bottom:95px;
      }
      &--last {
        background:none;
        padding-bottom:80px;
      }
    }
    &__imari {
      background:url(/assets/img/system/flow_imari_illust.png) top left no-repeat;
      width:640px;
      margin:0 auto;
      text-align:left;
      text-shadow:0 0 6px #FFF;
      &talk {
        background:url(/assets/img/system/flow_imari_talk.png) top left no-repeat;
        padding:8px 8px 20px 90px;
        margin-left:160px;
        font-weight:bold;
        font-size:18px;
        line-height:23px;
      }
      &name {
        margin-left:200px;
      }
      &text {
        font-size:17px;
        line-height:30px;
        padding:0 0 10px 210px;
      }
    }
    &__illust {
      position:absolute;
      top:-20px;
      right:90px;
    }
    &__btn {
      position:absolute;
      width:100%;
      left:0;
      bottom:10px;
    }
  }
  .type {
    &__block {
      background:url(/assets/img/system/ikusei_type_bg.png) top left no-repeat;
      width:832px;
      height:646px;
      margin:0 auto;
      text-align:left;
      box-sizing:border-box;
      padding:92px 24px 0;
      &--kakusei2 {
        height:376px;
        background:url(/assets/img/system/kakusei2_type_bg.png) top left no-repeat;
      }
    }
    &__title {
      margin-bottom:2px;
    }
    &__text {
      font-size:16px;
      line-height:19px;
      text-shadow:0 0 6px #FFF;
      margin-bottom:10px;
      padding-left:20px;
    }
    &__subtitle {
      padding-top:20px;
    }
  }
  .decbox--flow {
    .decbox__notice { padding:10px 0 0 20px; }
  }
  .decbox--ikusei {
    .decbox__notice { padding:10px 0 0 20px; }
  }
  .decbox--kakusei2 {
    .decbox__notice { padding:10px 0 0 20px; }
  }
}

@media (max-width: 750px) {

  #system {
    padding-top:140px;
    background:url(/assets/img/common/sp/bg_main.png) top 120px center repeat-x;
    min-width:auto;
    .subnav {
      padding-bottom:16px;
      background: url(/assets/img/common/sp/header_border.png) left bottom repeat-x;
      &__wrap { @include wrap-sp; }
      a {
        padding:5px 20px;
        margin-right:0;
        margin-left:20px;
        font-size:20px;
      }
    }
    .decbox {
      width:750px;
      background-size:
        710px auto,
        710px auto,
        auto,
        auto;
      &__info {
        width:650px;
      }
      &__wrap {
        width:710px;
      }
      &__title {
      }
      &__subtitle {
        &--s {
        }
      }
      &__text {
        a {
        }
      }
      &__thumb {
        &s {
          width:720px;
          margin:0 auto;
          display:table;
          border-spacing:0 5px;
          padding:10px 0;
        }
        &row {
          display:table-row;
        }
        display:table-cell;
        img {
          width:230px;
          height:auto;
        }
      }
    }
    .flow {
      &__block {
        .decbox__subtitle { width:710px; }
        .decbox__thumb img {
          width:340px;
        }
      }
      &__illust {
        position:absolute;
        top:0;
        right:20px;
        img { width:400px; }
      }
    }
    .type {
      &__block {
        background:url(/assets/img/system/sp/ikusei_type_bg.png) top left no-repeat;
        width:730px;
        &--kakusei2 {
          background:url(/assets/img/system/sp/kakusei_type_bg.png) top left no-repeat;
        }
      }
    }
    .decbox--kakusei {
      .decbox__thumb img {
        width:340px;
      }
    }
  }

}
