@charset "UTF-8";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

html {
  line-height: 1; }

ol, ul {
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle; }

q, blockquote {
  quotes: none; }

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none; }

a img {
  border: none; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block; }

body {
  font-family: "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
  font-size: 14px;
  color: #000;
  position: relative;
  line-height: 1.5; }

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none; }

a {
  color: #323232;
  text-decoration: none; }
  a:hover img.fade {
    transition: opacity 0.3s;
    opacity: 0.7; }
  a:hover img:not(.ov) {
    -webkit-transition: all .3s linear 0s;
    transition: all .3s linear 0s;
    -webkit-filter: drop-shadow(0px 0px 3px #FFF);
    filter: drop-shadow(0px 0px 3px #FFF); }

sub {
  vertical-align: sub; }

sup {
  vertical-align: super; }

.pc-on {
  display: inline-block; }

.sp-on {
  display: none; }

@media (max-width: 750px) {
  .pc-on {
    display: none; }
  .sp-on {
    display: inline-block; } }

.l-header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%; }
  .l-header .gnavi {
    width: 960px;
    margin: 0 auto;
    overflow: hidden; }
    .l-header .gnavi__list {
      float: left;
      margin-right: 6px;
      width: 132px;
      height: 159px;
      position: relative; }
      .l-header .gnavi__list--last {
        margin-right: 0; }
    .l-header .gnavi__link {
      position: absolute;
      top: -15px;
      left: 0;
      -webkit-transition: top 0.5s ease 0.2s, background-position 0.4s linear 0.2s;
      transition: top 0.5s ease 0.2s, background-position 0.4s linear 0.2s;
      background-position: -100px 0px; }
      .l-header .gnavi__link:hover {
        background-position: 80px 0px;
        top: 0; }
      .l-header .gnavi__link::after {
        content: '';
        position: absolute;
        pointer-events: none;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: radial-gradient(-50px 0, ellipse farthest-side, rgba(255, 255, 255, 0) 90%, rgba(255, 255, 255, 0.5) 98%, rgba(255, 255, 255, 0) 100%) no-repeat;
        background: radial-gradient(ellipse farthest-side at -50px 0, rgba(255, 255, 255, 0) 90%, rgba(255, 255, 255, 0.5) 98%, rgba(255, 255, 255, 0) 100%) no-repeat;
        background-position: inherit;
        -webkit-mask: url("/assets/img/common/gnavi_mask.png") center;
        mask: url("/#mask-firefox"); }
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          .l-header .gnavi__link::after {
            background: none; } }
      .l-header .gnavi__link._on {
        top: 0; }
      .l-header .gnavi__link--new::before {
        content: '';
        background: url(/assets/img/common/gnavi_new.png) top center no-repeat;
        width: 132px;
        height: 159px;
        display: block;
        position: absolute;
        top: 60px;
        left: 0;
        z-index: 300; }
    .l-header .gnavi__link--comingsoon {
      position: absolute;
      top: -15px;
      left: 0; }
    .l-header .gnavi__text {
      display: none; }
  .l-header__bg {
    width: 100%;
    min-width: 960px;
    position: absolute;
    top: 0;
    left: 0;
    height: 200px;
    z-index: 100;
    background: url(/assets/img/common/bg_header.png) top center no-repeat;
    background-size: 100% auto; }

.l-footer {
  width: 100%;
  min-width: 960px;
  overflow: visible;
  position: relative; }
  .l-footer__wrap {
    width: 960px;
    margin: 0 auto;
    overflow: hidden; }
  .l-footer .footertop {
    background: url(/assets/img/common/bg_footer.png) 0 0 repeat;
    padding: 35px 0; }
    .l-footer .footertop__snsbtns {
      width: 470px;
      float: left;
      overflow: hidden; }
    .l-footer .footertop__snsbtn {
      float: left;
      margin-right: 5px; }
      .l-footer .footertop__snsbtn--twitter .pc-on {
        width: 35px; }
      .l-footer .footertop__snsbtn--facebook .pc-on {
        width: 35px; }
      .l-footer .footertop__snsbtn--line .pc-on {
        width: 35px; }
      .l-footer .footertop__snsbtn--faq a {
        color: #FFF;
        text-decoration: underline;
        position: relative;
        padding-left: 15px;
        display: inline-block;
        margin-top: 10px; }
        .l-footer .footertop__snsbtn--faq a:hover {
          text-decoration: none; }
        .l-footer .footertop__snsbtn--faq a::after {
          content: '・';
          position: absolute;
          top: 0;
          left: 0; }
    .l-footer .footertop__info {
      width: 490px;
      float: right;
      background: rgba(255, 255, 255, 0.1);
      border: 2px solid #a17d00; }
    .l-footer .footertop__table {
      border: 1px solid #745c0b;
      margin: 3px;
      padding: 10px 15px;
      color: #FFF;
      font-size: 12px; }
      .l-footer .footertop__tablerow {
        overflow: hidden; }
      .l-footer .footertop__tabletitle {
        background: url(/assets/img/common/ico_footer_spec.png) left center no-repeat;
        padding-left: 18px;
        float: left;
        width: 84px; }
  .l-footer .footerbtm {
    padding: 24px 0; }
    .l-footer .footerbtm__company {
      width: 630px;
      margin: 0 auto 20px; }
      .l-footer .footerbtm__company td {
        vertical-align: middle; }
    .l-footer .footerbtm__copyright {
      text-align: center;
      font-size: 12px;
      color: #999; }
  .l-footer .footerpagetop {
    opacity: 0;
    position: fixed;
    right: 20px;
    bottom: 20px;
    transition: all 0.5s linear 0s;
    z-index: 3; }
    .l-footer .footerpagetop.active {
      opacity: 1; }

.main__wrap {
  width: 960px;
  margin: 0 auto;
  overflow: hidden; }

.main__header {
  padding-bottom: 10px;
  background: url(/assets/img/common/header_border.png) left bottom repeat-x;
  width: 100%; }

.main__title {
  padding-top: 24px;
  float: left; }

.main__logo {
  float: right; }

.content {
  padding: 30px 0 50px;
  background: url(/assets/img/common/bg_content.png) 0 0 repeat;
  overflow: hidden; }

@supports (-ms-accelerator: true) {
  .l-header .gnavi__link::after {
    background: none; } }

@media (max-width: 750px) {
  body {
    -webkit-text-size-adjust: none; }
  .wrapper {
    overflow: hidden;
    min-width: 750px !important; }
  svg {
    display: none; }
  .main {
    overflow: hidden; }
  .l-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%; }
    .l-header .menu {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1002;
      width: 100%;
      height: 134px;
      background: url(/assets/img/common/sp/bg_header.png) top center repeat-x;
      display: table;
      background-size: auto 134px; }
      .l-header .menu__twitter {
        display: table-cell;
        vertical-align: top;
        text-align: left;
        padding-top: 20px;
        padding-left: 10px; }
      .l-header .menu__toggle {
        display: table-cell;
        vertical-align: top;
        text-align: right;
        padding-right: 10px; }
    .l-header .gnavi {
      width: auto;
      margin: 0;
      overflow: hidden;
      background: url(/assets/img/top/border_visual.png) bottom left repeat-x, url(/assets/img/common/sp/bg_gnavilist.png) 0 0 repeat;
      width: 100%;
      position: absolute;
      left: 0;
      top: -1000px;
      transition: top 0.5s ease 0s;
      z-index: 1001; }
      .l-header .gnavi.open {
        top: 120px; }
      .l-header .gnavi__under {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        display: none; }
        .l-header .gnavi__under.open {
          display: block; }
      .l-header .gnavi__list {
        float: none;
        width: auto;
        height: auto;
        margin-right: 0;
        margin: 0 75px;
        display: block; }
        .l-header .gnavi__list--last .gnavi__link {
          border-bottom: none; }
      .l-header .gnavi__link {
        position: static;
        color: #2b4aa6;
        font-size: 28px;
        text-align: center;
        display: block;
        padding: 30px 0;
        border-bottom: 2px solid #dad3f7; }
        .l-header .gnavi__link::after {
          background: none; }
        .l-header .gnavi__link--new::before {
          width: 38px;
          height: 16px;
          top: 43px;
          left: 30px; }
      .l-header .gnavi__link--comingsoon {
        position: static;
        color: #CCC;
        font-size: 28px;
        text-align: center;
        display: block;
        padding: 30px 0;
        border-bottom: 2px solid #dad3f7; }
      .l-header .gnavi__img {
        display: none; }
      .l-header .gnavi__text {
        display: block; }
    .l-header__bg {
      display: none; }
  .l-footer {
    width: 100%;
    min-width: auto;
    min-width: 750px !important; }
    .l-footer__wrap {
      width: auto;
      margin: 0; }
    .l-footer .footertop {
      background: url(/assets/img/common/bg_footer.png) 0 0 repeat;
      padding: 30px 0 110px; }
      .l-footer .footertop__snsbtns {
        width: 520px;
        float: none;
        margin: 0 auto 30px;
        padding-top: 0; }
      .l-footer .footertop__snsbtn {
        float: left;
        margin-right: 16px; }
        .l-footer .footertop__snsbtn--follow {
          margin-right: 0;
          float: none;
          margin-bottom: 30px;
          text-align: center; }
        .l-footer .footertop__snsbtn--twitter {
          margin-left: 91px; }
        .l-footer .footertop__snsbtn--faq {
          position: absolute;
          bottom: 145px; }
          .l-footer .footertop__snsbtn--faq a {
            font-size: 20px; }
      .l-footer .footertop__info {
        width: auto;
        margin: 0 20px 20px;
        float: none;
        background: rgba(255, 255, 255, 0.1);
        border: 2px solid #a17d00; }
      .l-footer .footertop__table {
        font-size: 20px; }
        .l-footer .footertop__tabletitle {
          background: url(/assets/img/common/ico_footer_spec.png) left center no-repeat;
          background-size: 15px auto;
          padding-left: 28px;
          float: left;
          width: 140px; }
    .l-footer .footerbtm {
      padding: 24px 0 5px; }
      .l-footer .footerbtm__company {
        width: 700px; }
        .l-footer .footerbtm__company td {
          text-align: center; }
      .l-footer .footerbtm__copyright {
        font-size: 14px; }
    .l-footer .footerpagetop {
      display: none;
      position: fixed;
      top: auto;
      bottom: 100px;
      right: 0; }
  .main__wrap {
    width: auto;
    margin: 0;
    padding: 0 20px; }
  .main__header {
    background: url(/assets/img/common/sp/header_border.png) left bottom repeat-x;
    width: 100%;
    padding-bottom: 10px; }
  .content {
    padding: 30px 0 50px; } }

.browser {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;
  background: url(/assets/img/common/bg_content.png) 0 0 repeat; }
  .browser__text {
    text-align: center;
    position: absolute;
    top: -50%;
    margin-top: 50%;
    width: 100%;
    display: block; }
    .browser__text img {
      max-width: 80%; }

.splash {
  display: none; }

#top {
  width: 100%;
  min-width: 960px;
  background: url(/assets/img/top/bg_visual.png) top center no-repeat;
  background-size: 100% auto; }
  #top .visual {
    position: relative;
    overflow: hidden; }
    #top .visual__wrap {
      width: 960px;
      margin: 0 auto; }
    #top .visual__image {
      display: none; }
    #top .visual__catch {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 140px;
      z-index: 200; }
    #top .visual__logo {
      width: 100%;
      position: absolute;
      top: 540px;
      left: -180px;
      text-align: right;
      z-index: 200; }
    #top .visual__banners {
      width: 960px;
      margin: 0 auto;
      position: relative;
      overflow: visible; }
      #top .visual__banners--single {
        top: -32px;
        position: absolute;
        left: 0;
        z-index: 300; }
      #top .visual__banners .app-btn {
        position: absolute; }
        #top .visual__banners .app-btn--i {
          top: 94px;
          left: 163px; }
        #top .visual__banners .app-btn--g {
          top: 94px;
          left: 560px; }
    #top .visual__banner {
      display: block;
      float: left;
      margin-left: 366px;
      border: 6px solid #6b6096; }
      #top .visual__banner.visual__modal {
        margin-left: 10px; }
      #top .visual__banner img {
        border: 1px solid #FFF;
        width: 280px;
        height: auto;
        display: block; }
    #top .visual__slide {
      position: absolute;
      z-index: 101; }
    #top .visual__slides {
      display: block;
      overflow: visible;
      width: 100%;
      height: 810px;
      position: relative; }
    #top .visual__controls {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 0;
      overflow: visible;
      z-index: 200;
      cursor: pointer; }
      #top .visual__controls--left {
        float: left;
        margin-left: 25px; }
      #top .visual__controls--right {
        float: right;
        margin-right: 25px; }
    @media (min-width: 1400px) {
      #top .visual__slides {
        height: 850px; }
      #top .visual__controls {
        top: 460px; } }
    #top .visual__border {
      display: block;
      background: url(/assets/img/top/border_visual.png) 0 0 repeat-x;
      width: 100%;
      height: 6px;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 200; }
  #top .content {
    background: url(/assets/img/common/bg_content.png) 0 0 repeat;
    padding: 183px 0 40px; }
    #top .content__wrap {
      width: 960px;
      margin: 0 auto; }
    #top .content__banners {
      width: 984px;
      margin: 0 auto;
      position: relative;
      overflow: visible;
      display: table; }
      #top .content__banners a {
        display: table-cell;
        text-align: center; }
    #top .content__banner {
      float: left;
      width: 320px;
      height: 160px; }
    #top .content .columns {
      overflow: hidden;
      width: 970px;
      margin: 0 auto; }
    #top .content .block {
      width: 350px;
      height: 240px;
      background: #FFF;
      position: relative;
      box-shadow: 1px 1px 5px #DDD;
      margin: 5px;
      float: left; }
      #top .content .block--movie {
        width: 240px;
        overflow: hidden; }
        #top .content .block--movie .slide {
          transition: all 0.5s ease 0s;
          padding: 20px 0 0 15px;
          overflow: visible;
          width: 9000px;
          position: absolute;
          bottom: 18px;
          left: 0;
          z-index: 1; }
          #top .content .block--movie .slide a {
            float: left;
            margin-right: 15px; }
            #top .content .block--movie .slide a img.pc-on {
              display: block; }
        #top .content .block--movie .moviecontrols {
          position: absolute;
          z-index: 2;
          top: 75px;
          left: 0;
          width: 100%;
          text-align: center; }
          #top .content .block--movie .moviecontrols--left {
            margin-right: 5px; }
          #top .content .block--movie .moviecontrols--right {
            margin-left: 5px; }
          #top .content .block--movie .moviecontrols--ind {
            display: inline-block;
            background: #CCC;
            width: 9px;
            height: 9px;
            border-radius: 9px;
            line-height: 10px; }
          #top .content .block--movie .moviecontrols--active {
            background: #b569f1; }
      #top .content .block__title {
        background: url(/assets/img/top/title_bg.png) 0 0 no-repeat;
        height: 79px; }
        #top .content .block__title img {
          padding: 15px 0 0 10px; }
      #top .content .block__link {
        position: absolute;
        top: 14px;
        right: 9px; }
    #top .content .twitter iframe {
      min-height: 161px !important; }
    #top .content .twitter__feed {
      padding-left: 10px; }
    #top .content .news {
      height: 161px;
      overflow-y: scroll;
      margin: 0 0 0 10px; }
      #top .content .news__row {
        border-bottom: 1px solid #edebfa;
        padding-bottom: 5px;
        margin-bottom: 5px;
        overflow: hidden; }
      #top .content .news__date {
        color: #8b57da;
        font-size: 12px;
        margin-bottom: 5px; }
      #top .content .news__title a {
        color: #474747;
        font-size: 14px;
        line-height: 20px; }
        #top .content .news__title a:hover {
          text-decoration: underline; }
  #top .modal {
    display: none;
    width: 100%;
    position: absolute;
    top: 100px;
    left: 0;
    overflow: hidden;
    z-index: 2000; }
    #top .modal__close {
      position: absolute;
      top: 0;
      right: 0; }
    #top .modal__cover {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.9;
      background-color: #d3ceea;
      z-index: 1000; }
    #top .modal__wrap {
      padding-top: 50px;
      width: 838px;
      height: 530px;
      margin: 0 auto;
      position: relative; }
    #top .modal__body {
      border: 10px solid #FFF;
      width: 100%;
      height: 100%; }
      #top .modal__body iframe {
        width: 100%;
        height: 100%; }

.top-lead {
  position: absolute;
  left: 50%;
  bottom: 50px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 300; }

@media (max-width: 750px) {
  .top-lead {
    width: 80%;
    bottom: 30px; }
    .top-lead img {
      width: 100%; }
  .splash {
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9000;
    background: url(/assets/img/top/sp/splash_bg.png) 0 0 no-repeat #FFF;
    background-size: cover; }
    .splash__center {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    .splash__cover {
      display: none;
      width: 100%;
      height: 100%;
      background: #FFF;
      position: absolute;
      top: 0;
      left: 0; }
    .splash__border {
      display: none;
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      left: 0;
      width: 100%;
      text-align: center; }
      .splash__border img {
        width: 100%;
        height: auto; }
    .splash__char {
      -webkit-transition: all .3s ease 0s;
      transition: all .3s ease 0s;
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      width: 100%;
      text-align: center; }
      .splash__char img {
        width: 100%;
        height: auto; }
      .splash__char.char1.active {
        top: 50%;
        left: 0px; }
      .splash__char.char2.active {
        top: 50%;
        left: 0px; }
      .splash__char.char3.active {
        top: 50%;
        left: 0px; }
      .splash__char.char4.active {
        top: 50%;
        left: 0px; }
      .splash__char.char5.active {
        top: 50%;
        left: 0px; }
      .splash__char.char6.active {
        top: 50%;
        left: 0px; }
      .splash__char.char1 {
        top: -100%;
        left: 0px; }
      .splash__char.char2 {
        top: -100%;
        left: 100%; }
      .splash__char.char3 {
        top: 100%;
        left: 100%; }
      .splash__char.char4 {
        top: 100%;
        left: 0px; }
      .splash__char.char5 {
        top: 100%;
        left: -100%; }
      .splash__char.char6 {
        top: -100%;
        left: -100%; }
    .splash__logo {
      text-align: center;
      width: 100%;
      visibility: hidden;
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      left: 0; }
      .splash__logo img {
        width: 100%;
        height: auto; }
  #top {
    width: 100%;
    min-width: auto;
    background: url(/assets/img/top/bg_visual.png) top center no-repeat;
    background-size: 100% auto; }
    #top .main {
      background: url(/assets/img/common/bg_content.png) 0 0 repeat; }
    #top .app-banner {
      position: static; }
    #top .visual__slides {
      display: none; }
    #top .visual__logo {
      display: none; }
    #top .visual__catch {
      display: none; }
    #top .visual__controls {
      display: none; }
    #top .visual__border {
      display: none; }
    #top .visual__wrap {
      width: auto;
      margin: 0; }
    #top .visual__image {
      display: block;
      margin-bottom: 10px; }
      #top .visual__image img {
        display: block; }
    #top .visual__banners {
      width: auto;
      margin: 0;
      text-align: center;
      margin: 0 15px 10px;
      padding-top: 10px; }
      #top .visual__banners a {
        position: static;
        display: block; }
        #top .visual__banners a img {
          width: 100%; }
      #top .visual__banners .app-banner > img {
        width: 100%; }
      #top .visual__banners .app-btn {
        width: 160px; }
        #top .visual__banners .app-btn--i {
          top: 86px;
          left: 138px; }
        #top .visual__banners .app-btn--g {
          top: 86px;
          left: 432px; }
    #top .visual__wrap {
      display: table;
      width: 100%;
      border-spacing: 20px 0; }
    #top .visual__banner {
      display: table-cell;
      text-align: center;
      float: none;
      margin: 0; }
      #top .visual__banner img {
        width: 333px; }
    #top .content {
      padding: 0 0 80px; }
      #top .content__wrap {
        width: auto;
        margin: 0; }
      #top .content__banners {
        width: auto;
        margin: 0;
        margin: 0 15px 10px;
        text-align: center;
        display: table; }
        #top .content__banners a {
          position: static;
          display: table-cell; }
          #top .content__banners a img {
            width: 100%; }
      #top .content .columns {
        margin: 0 20px;
        overflow: visible;
        width: auto; }
      #top .content .block {
        width: 100%;
        height: 350px;
        background: #FFF;
        position: relative;
        box-shadow: 1px 1px 5px #DDD;
        margin: 0 0 18px 0;
        float: none; }
        #top .content .block--left {
          margin-bottom: 20px; }
        #top .content .block__title {
          background: url(/assets/img/top/sp/title_bg.png) 0 0 no-repeat;
          background-size: 100% auto;
          height: 76px;
          margin-bottom: 12px; }
          #top .content .block__title img {
            padding: 10px 0 0 10px; }
        #top .content .block__link {
          top: 10px;
          right: 20px; }
        #top .content .block--movie {
          height: 390px; }
          #top .content .block--movie .slide {
            padding: 0 0 25px 140px;
            bottom: 0; }
            #top .content .block--movie .slide a img.pc-on {
              display: none; }
            #top .content .block--movie .slide a img.pc-sp {
              display: block; }
      #top .content .news {
        margin: 0 20px;
        height: 262px; }
        #top .content .news__row {
          border-bottom: 1px solid #edebfa;
          padding-bottom: 10px;
          margin-bottom: 10px;
          overflow: hidden; }
        #top .content .news__date {
          color: #8b57da;
          font-size: 18px;
          width: 100%;
          float: none;
          margin-bottom: 5px; }
        #top .content .news__title {
          width: 100%;
          float: none; }
          #top .content .news__title a {
            line-height: 20px;
            color: #474747;
            font-size: 18px; }
      #top .content .twitter iframe {
        width: 670px !important;
        height: 262px !important; }
    #top .modal {
      top: 900px; }
      #top .modal__wrap {
        width: 730px;
        height: 469px; } }

#top .visual__slide {
  -webkit-transition: all .7s ease 0s;
  transition: all .7s ease 0s;
  display: block;
  width: 100%;
  height: 200%; }
  #top .visual__slide.slide1 {
    background: url(/assets/img/top/visual_oda.png) 0 0 no-repeat;
    background-size: 46% auto; }
  #top .visual__slide.slide2 {
    background: url(/assets/img/top/visual_toyotomi.png) 0 0 no-repeat;
    background-size: 27% auto; }
  #top .visual__slide.slide3 {
    background: url(/assets/img/top/visual_uesugi.png) 0 0 no-repeat;
    background-size: 30% auto; }
  #top .visual__slide.slide4 {
    background: url(/assets/img/top/visual_takeda.png) 0 0 no-repeat;
    background-size: 36% auto; }
  #top .visual__slide.slide5 {
    background: url(/assets/img/top/visual_sanada.png) 0 0 no-repeat;
    background-size: 33% auto; }
  #top .visual__slide.slide6 {
    background: url(/assets/img/top/visual_date.png) 0 0 no-repeat;
    background-size: 28% auto; }
  #top .visual__slide.slide7 {
    background: url(/assets/img/top/visual_mouri.png) 0 0 no-repeat;
    background-size: 50% auto; }

#top .visual__slides#state1 .slide1 {
  background-size: 75% auto; }

#top .visual__slides#state1 .slide1 {
  top: 130px;
  left: -1%;
  z-index: 106; }

#top .visual__slides#state1 .slide2 {
  top: 340px;
  left: 50%;
  z-index: 105; }

#top .visual__slides#state1 .slide3 {
  top: 260px;
  left: 64%;
  z-index: 104; }

#top .visual__slides#state1 .slide4 {
  top: 60px;
  left: 75%;
  z-index: 103; }

#top .visual__slides#state1 .slide5 {
  top: -2px;
  left: 39%;
  z-index: 101; }

#top .visual__slides#state1 .slide6 {
  top: 20px;
  left: -8%;
  z-index: 102; }

#top .visual__slides#state1 .slide7 {
  top: 109px;
  left: -17%;
  z-index: 102; }

#top .visual__slides#state2 .slide2 {
  background-size: 42% auto; }

#top .visual__slides#state2 .slide2 {
  top: 130px;
  left: 13%;
  z-index: 106; }

#top .visual__slides#state2 .slide3 {
  top: 290px;
  left: 48%;
  z-index: 105; }

#top .visual__slides#state2 .slide4 {
  top: 260px;
  left: 64%;
  z-index: 104; }

#top .visual__slides#state2 .slide5 {
  top: -80px;
  left: 69%;
  z-index: 103; }

#top .visual__slides#state2 .slide6 {
  top: 110px;
  left: 43%;
  z-index: 101; }

#top .visual__slides#state2 .slide1 {
  top: 240px;
  left: -14%;
  z-index: 102; }

#top .visual__slides#state2 .slide7 {
  top: -7px;
  left: -14%;
  z-index: 100; }

#top .visual__slides#state3 .slide3 {
  background-size: 50% auto; }

#top .visual__slides#state3 .slide3 {
  top: 60px;
  left: 11%;
  z-index: 106; }

#top .visual__slides#state3 .slide4 {
  top: 325px;
  left: 46%;
  z-index: 105; }

#top .visual__slides#state3 .slide5 {
  top: 170px;
  left: 61%;
  z-index: 104; }

#top .visual__slides#state3 .slide6 {
  top: 20px;
  left: 76%;
  z-index: 103; }

#top .visual__slides#state3 .slide1 {
  top: 14px;
  left: -15%;
  z-index: 101; }

#top .visual__slides#state3 .slide2 {
  top: 260px;
  left: -9%;
  z-index: 102; }

#top .visual__slides#state3 .slide7 {
  top: 54px;
  left: 35%;
  z-index: 101; }

#top .visual__slides#state4 .slide4 {
  background-size: 64% auto; }

#top .visual__slides#state4 .slide4 {
  top: 120px;
  left: 4%;
  z-index: 106; }

#top .visual__slides#state4 .slide5 {
  top: 170px;
  left: 37%;
  z-index: 105; }

#top .visual__slides#state4 .slide6 {
  top: 310px;
  left: 64%;
  z-index: 104; }

#top .visual__slides#state4 .slide1 {
  top: 109px;
  left: 43%;
  z-index: 103; }

#top .visual__slides#state4 .slide2 {
  top: 20px;
  left: -9%;
  z-index: 101; }

#top .visual__slides#state4 .slide3 {
  top: 190px;
  left: -6%;
  z-index: 102; }

#top .visual__slides#state4 .slide7 {
  top: -10px;
  left: 61%;
  z-index: 102; }

#top .visual__slides#state5 .slide5 {
  background-size: 56% auto; }

#top .visual__slides#state5 .slide5 {
  top: -130px;
  left: 0%;
  z-index: 106; }
  @media (max-width: 1200px) {
    #top .visual__slides#state5 .slide5 {
      top: -50px; } }

#top .visual__slides#state5 .slide6 {
  top: 330px;
  left: 45%;
  z-index: 105; }

#top .visual__slides#state5 .slide1 {
  top: 50px;
  left: 68%;
  z-index: 104; }

#top .visual__slides#state5 .slide2 {
  top: 110px;
  left: 46%;
  z-index: 103; }

#top .visual__slides#state5 .slide3 {
  top: -20px;
  left: -8%;
  z-index: 101; }

#top .visual__slides#state5 .slide4 {
  top: 250px;
  left: -5%;
  z-index: 102; }

#top .visual__slides#state5 .slide7 {
  top: 240px;
  left: 54%;
  z-index: 104; }

#top .visual__slides#state6 .slide6 {
  background-size: 44% auto; }

#top .visual__slides#state6 .slide6 {
  top: 150px;
  left: 9%;
  z-index: 106; }

#top .visual__slides#state6 .slide1 {
  top: 240px;
  left: 67%;
  z-index: 105; }

#top .visual__slides#state6 .slide2 {
  top: 100px;
  left: 70%;
  z-index: 104; }

#top .visual__slides#state6 .slide3 {
  top: 90px;
  left: 42%;
  z-index: 103; }

#top .visual__slides#state6 .slide4 {
  top: 20px;
  left: -12%;
  z-index: 101; }

#top .visual__slides#state6 .slide5 {
  top: 110px;
  left: -8%;
  z-index: 102; }

#top .visual__slides#state6 .slide7 {
  top: 270px;
  left: 39%;
  z-index: 105; }

#top .visual__slides#state7 .slide7 {
  background-size: 83% auto; }

#top .visual__slides#state7 .slide7 {
  top: 60px;
  left: -7%;
  z-index: 106; }

#top .visual__slides#state7 .slide1 {
  top: 300px;
  left: 42%;
  z-index: 106; }

#top .visual__slides#state7 .slide2 {
  top: 221px;
  left: 71%;
  z-index: 105; }

#top .visual__slides#state7 .slide3 {
  top: -10px;
  left: 75%;
  z-index: 104; }

#top .visual__slides#state7 .slide4 {
  top: 110px;
  left: 42%;
  z-index: 103; }

#top .visual__slides#state7 .slide5 {
  top: -70px;
  left: -11%;
  z-index: 101; }

#top .visual__slides#state7 .slide6 {
  top: 210px;
  left: 0%;
  z-index: 102; }

.main-ket {
  padding-top: 10px;
  background: url(/assets/img/common/bg_content.png) 0 0 repeat; }
  .main-ket img {
    width: 100%; }

.main-fixd img {
  width: 100%; }

#top .visual__logo {
  left: 50%;
  top: 68%;
  text-align: center;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%); }
  #top .visual__logo .visual__wrap {
    width: auto; }
  #top .visual__logo img {
    width: 25%; }

.top-fade {
  position: relative;
  width: 100%; }
  .top-fade .top-fade-slide img {
    width: 100%; }

.slide-token {
  width: 100%;
  height: 40px;
  display: none; }

.visual__banners--single.app-banner {
  top: 10px !important; }

#top {
  min-width: 1200px; }
  #top .content {
    padding: 203px 0 40px !important; }

@media screen and (max-width: 750px) {
  #top {
    min-width: 320px; }
    #top .content {
      padding: 143px 0 40px !important; } }

#character {
  padding-top: 152px;
  background: url(/assets/img/common/header_bg.png) top center no-repeat;
  background-size: 100% auto; }
  #character .content {
    padding-top: 10px; }
  #character .subnav {
    padding-bottom: 16px;
    background: url(/assets/img/common/header_border.png) left bottom repeat-x;
    width: 100%; }
    #character .subnav__wrap {
      width: 960px;
      margin: 0 auto; }
    #character .subnav a {
      background: #c1a0cc;
      border-radius: 6px;
      color: #FFF;
      padding: 5px 20px;
      margin-right: 15px;
      font-size: 14px;
      display: inline-block;
      position: relative; }
      #character .subnav a:hover {
        background: #aa4eab; }
      #character .subnav a.active {
        background: #aa4eab;
        cursor: default; }
    #character .subnav--new::after {
      content: '';
      position: absolute;
      width: 29px;
      height: 10px;
      top: -10px;
      left: 4px;
      background: url(/assets/img/common/ico_subnavnew.png) 0 0 no-repeat;
      background-size: auto 10px; }
  #character .group {
    position: relative;
    overflow: hidden;
    margin-bottom: 30px; }
    #character .group__wrap {
      width: 960px;
      margin: 0 auto;
      height: 630px;
      position: relative; }
    #character .group__title {
      width: 100%;
      min-width: 1500px;
      height: 80px;
      position: absolute;
      left: 0;
      bottom: 0; }
    #character .group__titlewrap {
      width: 1500px;
      height: 80px;
      margin: 0 auto;
      position: relative; }
    #character .group__name {
      position: absolute;
      left: 500px;
      bottom: 370px;
      width: 260px;
      text-align: left;
      transition: all 1s ease 0.5s; }
    #character .group__illust {
      transition: all 1s ease 0.5s;
      position: absolute;
      right: 500px;
      bottom: 370px;
      width: 260px;
      text-align: right; }
    #character .group--oda {
      background: url(/assets/img/character/oda_bg.jpg) top center no-repeat; }
      #character .group--oda .group__title {
        background: url(/assets/img/character/oda_titlebg.png) bottom left repeat-x;
        background-size: auto 100%; }
      #character .group--oda .active .character__wrap {
        background-image: url(/assets/img/character/oda_char_bg.png); }
      #character .group--oda .character--1 .character__visual {
        left: -80px; }
      #character .group--oda .character--2 .character__visual {
        left: -110px; }
      #character .group--oda .character--3 .character__visual {
        left: 0px; }
      #character .group--oda .character--4 .character__visual {
        left: -110px; }
      #character .group--oda .character--5 .character__visual {
        left: -50px; }
    #character .group--toyotomi {
      background: url(/assets/img/character/toyotomi_bg.jpg) top center no-repeat; }
      #character .group--toyotomi .group__title {
        background: url(/assets/img/character/toyotomi_titlebg.png) bottom left repeat-x;
        background-size: auto 100%; }
      #character .group--toyotomi .active .character__wrap {
        background-image: url(/assets/img/character/toyotomi_char_bg.png); }
      #character .group--toyotomi .character--1 .character__visual {
        left: -20px; }
      #character .group--toyotomi .character--2 .character__visual {
        left: 2px; }
      #character .group--toyotomi .character--3 .character__visual {
        left: -70px; }
      #character .group--toyotomi .character--4 .character__visual {
        left: -34px; }
      #character .group--toyotomi .character--5 .character__visual {
        left: -50px; }
    #character .group--uesugi {
      background: url(/assets/img/character/uesugi_bg.jpg) top center no-repeat; }
      #character .group--uesugi .group__title {
        background: url(/assets/img/character/uesugi_titlebg.png) bottom left repeat-x;
        background-size: auto 100%; }
      #character .group--uesugi .active .character__wrap {
        background-image: url(/assets/img/character/uesugi_char_bg.png); }
      #character .group--uesugi .character--1 .character__visual {
        left: -50px; }
      #character .group--uesugi .character--2 .character__visual {
        left: -68px; }
      #character .group--uesugi .character--3 .character__visual {
        left: -60px; }
      #character .group--uesugi .character--4 .character__visual {
        left: -10px; }
      #character .group--uesugi .character--5 .character__visual {
        left: -30px; }
    #character .group--takeda {
      background: url(/assets/img/character/takeda_bg.jpg) top center no-repeat; }
      #character .group--takeda .group__title {
        background: url(/assets/img/character/takeda_titlebg.png) bottom left repeat-x;
        background-size: auto 100%; }
      #character .group--takeda .active .character__wrap {
        background-image: url(/assets/img/character/takeda_char_bg.png); }
      #character .group--takeda .character--1 .character__visual {
        left: -60px; }
      #character .group--takeda .character--2 .character__visual {
        left: -78px; }
      #character .group--takeda .character--3 .character__visual {
        left: -30px; }
      #character .group--takeda .character--4 .character__visual {
        left: -50px; }
      #character .group--takeda .character--5 .character__visual {
        left: -40px; }
    #character .group--sanada {
      background: url(/assets/img/character/sanada_bg.jpg) top center no-repeat; }
      #character .group--sanada .group__title {
        background: url(/assets/img/character/sanada_titlebg.png) bottom left repeat-x;
        background-size: auto 100%; }
      #character .group--sanada .active .character__wrap {
        background-image: url(/assets/img/character/sanada_char_bg.png); }
      #character .group--sanada .character--1 .character__visual {
        left: -100px; }
      #character .group--sanada .character--2 .character__visual {
        left: -38px; }
      #character .group--sanada .character--3 .character__visual {
        left: -90px; }
      #character .group--sanada .character--4 .character__visual {
        left: -7px; }
      #character .group--sanada .character--5 .character__visual {
        left: -45px; }
    #character .group--date {
      background: url(/assets/img/character/date_bg.jpg) top center no-repeat; }
      #character .group--date .group__title {
        background: url(/assets/img/character/date_titlebg.png) bottom left repeat-x;
        background-size: auto 100%; }
      #character .group--date .active .character__wrap {
        background-image: url(/assets/img/character/date_char_bg.png); }
      #character .group--date .character--1 .character__visual {
        left: -30px; }
      #character .group--date .character--2 .character__visual {
        left: -148px; }
      #character .group--date .character--3 .character__visual {
        left: -60px; }
    #character .group--mouri {
      background: url(/assets/img/character/mouri_bg.jpg) top center no-repeat; }
      #character .group--mouri .group__title {
        background: url(/assets/img/character/mouri_titlebg.png) bottom left repeat-x;
        background-size: auto 100%; }
      #character .group--mouri .active .character__wrap {
        background-image: url(/assets/img/character/mouri_char_bg.png); }
      #character .group--mouri.gather .character--2 {
        left: 172px; }
      #character .group--mouri.gather .character--4 .character__catch {
        left: 20px; }
      #character .group--mouri.gather .character--3 .character__catch {
        left: -23px; }
      #character .group--mouri.gather .character--5 {
        left: 778px; }
        #character .group--mouri.gather .character--5 .character__visual {
          width: 265px; }
          #character .group--mouri.gather .character--5 .character__visual img {
            width: 100%; }
        #character .group--mouri.gather .character--5 .character__catch {
          left: 20px; }
          #character .group--mouri.gather .character--5 .character__catch img {
            position: relative;
            left: 70px; }
  #character .gather .group__illust {
    bottom: 0;
    right: 0; }
  #character .gather .group__name {
    bottom: 0;
    left: 0; }
  #character .gather .character {
    bottom: 0px;
    opacity: 1; }
    #character .gather .character--1 {
      left: 384px; }
    #character .gather .character--2 {
      left: 192px; }
    #character .gather .character--3 {
      left: 576px; }
    #character .gather .character--4 {
      left: 0px; }
    #character .gather .character--5 {
      left: 768px; }
  #character .character {
    transition: all 1.5s ease 0.5s;
    opacity: 0;
    position: absolute;
    width: 192px;
    height: 550px;
    bottom: -300px; }
    #character .character--1 {
      left: 384px; }
    #character .character--2 {
      left: 0px; }
    #character .character--3 {
      left: 960px; }
    #character .character--4 {
      left: -960px; }
    #character .character--5 {
      left: 1980px; }
    #character .character--new:hover .character__catch {
      z-index: 300; }
    #character .character--new::after {
      content: '';
      background: url(/assets/img/character/ico_new.png) top center no-repeat;
      display: block;
      width: 100%;
      height: 42px;
      position: absolute;
      top: -70px;
      left: 0;
      z-index: 300; }
    #character .character__wrap {
      position: relative;
      overflow: visible;
      width: 192px;
      height: 550px;
      transition: all 0.5s ease 0s;
      background-image: none;
      background-repeat: no-repeat;
      background-position: bottom 0px left -192px; }
    #character .character.active {
      z-index: 10; }
      #character .character.active .character__wrap {
        background-position: bottom 0px left 0px; }
      #character .character.active .character__name {
        bottom: 0; }
    #character .character__visual {
      position: absolute;
      width: 50px;
      bottom: 0;
      left: -100px;
      z-index: 0;
      pointer-events: none; }
    #character .character__catch {
      position: absolute;
      top: 0;
      left: 0;
      width: 192px;
      height: 550px;
      z-index: 200; }
      #character .character__catch img {
        transition: all 0.5s ease 0s;
        opacity: 0;
        margin: 0 50px; }
      #character .character__catch--left {
        text-align: left; }
      #character .character__catch--right {
        text-align: right; }
      #character .character__catch:hover img {
        opacity: 1;
        margin: 0 14px; }
      #character .character__catch--disable:hover {
        cursor: default; }
        #character .character__catch--disable:hover img {
          opacity: 0; }
    #character .character__name {
      width: 50px;
      height: 259px;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 300;
      transition: all 0.5s ease 0s; }
      #character .character__name--disable {
        background: url(/assets/img/character/disable_name.png) bottom left no-repeat; }
        #character .character__name--disable img {
          opacity: 0.5; }
    #character .character--1 .character__visual {
      z-index: 1; }
  #character .modal {
    display: none;
    position: absolute;
    top: 100px;
    left: 0;
    min-width: 1280px;
    width: 100%;
    z-index: 2000;
    background-color: #FFF;
    background-position: bottom center;
    background-repeat: no-repeat; }
    #character .modal__cover {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.9;
      background-color: #d3ceea;
      z-index: 1000; }
    #character .modal__wrap {
      position: relative;
      width: 100%;
      height: 1200px;
      border-top: 13px solid #deb4f3;
      border-bottom: 13px solid #deb4f3;
      box-sizing: content-box;
      overflow: hidden; }
    #character .modal__control {
      position: absolute;
      z-index: 2000; }
      #character .modal__control--close {
        top: 0;
        right: 10px; }
      #character .modal__control--left {
        top: 480px;
        left: 10px; }
      #character .modal__control--right {
        top: 480px;
        right: 10px; }
      #character .modal__control--voices {
        margin-bottom: 3px; }
      #character .modal__control--k {
        display: none; }
        #character .modal__control--k a {
          margin-right: -3px; }
      #character .modal__control--nok {
        display: block; }
      #character .modal__control--int {
        display: none; }
      #character .modal__control--rec {
        display: inline-block; }
    #character .modal__char {
      width: 45%;
      position: absolute;
      left: 0;
      top: 0; }
      #character .modal__charvisual {
        text-align: right; }
        #character .modal__charvisual img {
          float: right;
          margin-right: -1020px; }
      #character .modal__charcatch {
        position: absolute;
        top: 45px;
        left: 20px; }
      #character .modal__charbtns {
        margin: 0 0 15px 200px;
        width: 470px; }
        #character .modal__charbtns a:hover img {
          opacity: 0.8; }
      #character .modal__charvoices {
        margin: 0 0 15px 200px; }
      #character .modal__charcopy {
        margin: 0 0 15px 0; }
        #character .modal__charcopy img {
          width: 100%;
          height: auto; }
      #character .modal__charname {
        margin: 0 0 15px 190px; }
      #character .modal__chartext {
        text-shadow: 0 0 6px #FFF;
        font-size: 18px;
        line-height: 30px;
        margin: 0 0 0 200px;
        background: rgba(255, 255, 255, 0.2);
        padding: 15px;
        width: 480px; }
      #character .modal__charspec {
        padding: 15px;
        margin: 0 0 0 200px;
        width: 480px;
        font-size: 18px;
        text-shadow: 0 0 6px #FFF;
        line-height: 30px; }
        #character .modal__charspec dl {
          overflow: hidden; }
        #character .modal__charspec dt {
          width: 150px;
          float: left;
          position: relative;
          padding-left: 16px; }
          #character .modal__charspec dt::before {
            content: '';
            background: url(/assets/img/character/modal/ico_spec.png) top left no-repeat;
            background-size: auto 100%;
            width: 12px;
            height: 13px;
            position: absolute;
            top: 7px;
            left: 0; }
          #character .modal__charspec dt::after {
            content: '：';
            position: absolute;
            top: 0;
            right: 5px; }
        #character .modal__charspec dd {
          width: 300px;
          float: left;
          position: relative; }
          #character .modal__charspec dd span {
            font-size: 14px; }
      #character .modal__charextra {
        padding: 15px;
        margin: 10px 0 0 200px;
        width: 480px;
        font-size: 18px;
        text-shadow: 0 0 6px #FFF;
        line-height: 30px;
        position: relative; }
        #character .modal__charextra img {
          display: block; }
      #character .modal__charzoom {
        display: block;
        position: absolute;
        top: 100px;
        left: 0;
        width: 100%;
        text-align: right; }
        #character .modal__charzoom img {
          height: 950px;
          width: auto;
          float: right; }
    #character .modal__info {
      width: 55%;
      position: absolute;
      right: 0;
      top: 0; }
    #character .modal__leader {
      position: relative; }
      #character .modal__leaderbtn {
        position: absolute;
        top: 8px;
        left: 208px; }
        #character .modal__leaderbtn:hover img {
          opacity: 0.8; }
    #character .modal__btns {
      padding-left: 225px; }
      #character .modal__btns img {
        display: block; }
      #character .modal__btnsvoice {
        overflow: hidden; }
        #character .modal__btnsvoice li {
          float: left; }
    #character .modal .modal__char .modal__charvisual img, #character .modal .modal__char .modal__charzoom img {
      display: none; }
    #character .modal .modal__char .modal__charvisual .modal__charvisual--a {
      display: block; }
    #character .modal .modal__char.all .modal__charvisual img, #character .modal .modal__char.all .modal__charzoom img {
      display: none; }
    #character .modal .modal__char.all .modal__charzoom .modal__charzoom--a {
      display: block; }
    #character .modal .modal__char.secondary .modal__charvisual img, #character .modal .modal__char.secondary .modal__charzoom img {
      display: none; }
    #character .modal .modal__char.secondary .modal__charvisual .modal__charvisual--b {
      display: block; }
    #character .modal .modal__char.secondary.all .modal__charvisual img, #character .modal .modal__char.secondary.all .modal__charzoom img {
      display: none; }
    #character .modal .modal__char.secondary.all .modal__charzoom .modal__charzoom--b {
      display: block; }
    #character .modal .modal__char.kakusei .modal__charvisual img, #character .modal .modal__char.kakusei .modal__charzoom img {
      display: none; }
    #character .modal .modal__char.kakusei .modal__charvisual .modal__charvisual--k {
      display: block; }
    #character .modal .modal__char.kakusei.all .modal__charvisual img, #character .modal .modal__char.kakusei.all .modal__charzoom img {
      display: none; }
    #character .modal .modal__char.kakusei.all .modal__charzoom .modal__charzoom--k {
      display: block; }
    #character .modal.oda.char1 .modal__charvisual--a {
      margin: -20px -960px; }
    #character .modal.oda.char1 .modal__charvisual--b {
      margin: -20px -900px; }
    #character .modal.oda.char1 .modal__charvisual--k {
      margin: -18px -960px; }
    #character .modal.oda.char1 .modal__charzoom--a {
      margin-right: -90px; }
    #character .modal.oda.char1 .modal__charzoom--b {
      margin-right: -50px; }
    #character .modal.oda.char1 .modal__charzoom--k {
      margin-right: -113px;
      margin-top: -1px; }
    #character .modal.oda.char1 .modal__control--int {
      display: inline-block; }
    #character .modal.oda.char2 .modal__charcatch {
      top: 25px; }
    #character .modal.oda.char2 .modal__charvisual--a {
      margin: -20px -940px; }
    #character .modal.oda.char2 .modal__charvisual--b {
      margin: -20px -900px; }
    #character .modal.oda.char2 .modal__charzoom--a {
      margin-right: 50px;
      margin-top: -50px; }
    #character .modal.oda.char2 .modal__charzoom--b {
      margin-right: -10px; }
    #character .modal.oda.char3 .modal__charvisual--a {
      margin: -20px -900px; }
    #character .modal.oda.char3 .modal__charvisual--b {
      margin: -20px -900px; }
    #character .modal.oda.char3 .modal__charzoom--a {
      margin-right: -90px; }
    #character .modal.oda.char3 .modal__charzoom--b {
      margin-right: -50px; }
    #character .modal.oda.char4 .modal__charvisual--a {
      margin: 0 -910px; }
    #character .modal.oda.char4 .modal__charvisual--b {
      margin: 0 -910px; }
    #character .modal.oda.char4 .modal__charzoom--a {
      margin-right: -70px;
      margin-top: -30px; }
    #character .modal.oda.char4 .modal__charzoom--b {
      margin-right: 50px; }
    #character .modal.oda.char5 .modal__charvisual--a {
      margin: 0 -910px; }
    #character .modal.oda.char5 .modal__charvisual--b {
      margin: 0 -910px; }
    #character .modal.oda.char5 .modal__charzoom--a {
      margin-right: -40px; }
    #character .modal.oda.char5 .modal__charzoom--b {
      margin-right: -50px; }
    #character .modal.toyotomi.char1 .modal__charvisual--a {
      margin: 0 -880px; }
    #character .modal.toyotomi.char1 .modal__charvisual--b {
      margin: 0 -910px; }
    #character .modal.toyotomi.char1 .modal__charvisual--k {
      margin: 0 -880px; }
    #character .modal.toyotomi.char1 .modal__charzoom--a {
      margin-right: 10px; }
    #character .modal.toyotomi.char1 .modal__charzoom--b {
      margin-right: 20px; }
    #character .modal.toyotomi.char1 .modal__charzoom--k {
      margin-right: 10px; }
    #character .modal.toyotomi.char1 .modal__control--int {
      display: inline-block; }
    #character .modal.toyotomi.char1 .modal__charextra {
      min-height: 200px; }
    #character .modal.toyotomi.char1 .modal__charextra02 {
      position: absolute;
      top: 20px;
      right: 10px; }
    #character .modal.toyotomi.char2 .modal__charcatch {
      top: 95px; }
    #character .modal.toyotomi.char2 .modal__charvisual--a {
      margin: 0 -910px; }
    #character .modal.toyotomi.char2 .modal__charvisual--b {
      margin: 0 -910px; }
    #character .modal.toyotomi.char2 .modal__charzoom--a {
      margin-right: -20px;
      margin-top: -40px; }
    #character .modal.toyotomi.char2 .modal__charzoom--b {
      margin-right: 10px; }
    #character .modal.toyotomi.char3 .modal__charcatch {
      top: 0; }
    #character .modal.toyotomi.char3 .modal__charvisual--a {
      margin: 0 -910px; }
    #character .modal.toyotomi.char3 .modal__charvisual--b {
      margin: 0 -910px; }
    #character .modal.toyotomi.char3 .modal__charzoom--a {
      margin-right: 10px; }
    #character .modal.toyotomi.char3 .modal__charzoom--b {
      margin-right: 10px; }
    #character .modal.toyotomi.char4 .modal__charvisual--a {
      margin: 0 -940px; }
    #character .modal.toyotomi.char4 .modal__charvisual--b {
      margin: 0 -940px; }
    #character .modal.toyotomi.char4 .modal__charzoom--a {
      margin-right: -40px; }
    #character .modal.toyotomi.char4 .modal__charzoom--b {
      margin-right: 10px; }
    #character .modal.toyotomi.char5 .modal__charvisual--a {
      margin: 0 -940px; }
    #character .modal.toyotomi.char5 .modal__charvisual--b {
      margin: -20px -940px; }
    #character .modal.toyotomi.char5 .modal__charzoom--a {
      margin-right: -20px;
      margin-top: -40px; }
    #character .modal.toyotomi.char5 .modal__charzoom--b {
      margin-right: 40px; }
    #character .modal.uesugi.char1 .modal__charcatch {
      left: 40px; }
    #character .modal.uesugi.char1 .modal__charvisual img {
      margin-right: -910px; }
    #character .modal.uesugi.char1 .modal__charzoom--k {
      margin-right: -127px; }
    #character .modal.uesugi.char1 .modal__control--int {
      display: inline-block; }
    #character .modal.uesugi.char2 .modal__charvisual--a {
      margin-right: -910px; }
    #character .modal.uesugi.char2 .modal__charvisual--b {
      margin-right: -910px; }
    #character .modal.uesugi.char3 .modal__charvisual--a {
      margin-right: -930px; }
    #character .modal.uesugi.char3 .modal__charvisual--b {
      margin-right: -930px; }
    #character .modal.uesugi.char3 .modal__charzoom--a {
      margin-right: -20px; }
    #character .modal.uesugi.char3 .modal__charzoom--b {
      margin-right: -10px; }
    #character .modal.uesugi.char4 .modal__charvisual--a {
      margin-right: -940px; }
    #character .modal.uesugi.char4 .modal__charvisual--b {
      margin-right: -940px; }
    #character .modal.uesugi.char5 .modal__charcatch {
      left: 40px; }
    #character .modal.uesugi.char5 .modal__charvisual--a {
      margin-right: -960px; }
    #character .modal.uesugi.char5 .modal__charvisual--b {
      margin-right: -940px; }
    #character .modal.uesugi.char5 .modal__charzoom--a {
      margin-right: -50px; }
    #character .modal.uesugi.char5 .modal__charzoom--b {
      margin-right: -50px; }
    #character .modal.takeda.char1 .modal__charvisual img {
      margin: 0 -930px; }
    #character .modal.takeda.char1 .modal__charzoom--a {
      margin-right: -70px; }
    #character .modal.takeda.char1 .modal__charzoom--b {
      margin-right: -70px; }
    #character .modal.takeda.char1 .modal__charzoom--k {
      margin-right: -69px; }
    #character .modal.takeda.char1 .modal__control--int {
      display: inline-block; }
    #character .modal.takeda.char2 .modal__charvisual--a {
      margin-right: -920px; }
    #character .modal.takeda.char2 .modal__charvisual--b {
      margin-right: -930px; }
    #character .modal.takeda.char3 .modal__charvisual img {
      margin-right: -920px; }
    #character .modal.takeda.char3 .modal__charzoom--a {
      margin-right: -60px; }
    #character .modal.takeda.char4 .modal__charcatch {
      top: -10px; }
    #character .modal.takeda.char4 .modal__charvisual--a {
      margin-right: -920px; }
    #character .modal.takeda.char4 .modal__charvisual--b {
      margin-right: -950px; }
    #character .modal.takeda.char4 .modal__charzoom--a {
      margin-right: -30px; }
    #character .modal.takeda.char5 .modal__charvisual--a {
      margin-right: -930px; }
    #character .modal.takeda.char5 .modal__charvisual--b {
      margin-right: -990px; }
    #character .modal.takeda.char5 .modal__charzoom--a {
      margin-right: -90px; }
    #character .modal.sanada.char1 .modal__charvisual img {
      margin-right: -890px; }
    #character .modal.sanada.char1 .modal__charzoom--a {
      margin-right: -30px;
      margin-top: -90px;
      height: 990px; }
    #character .modal.sanada.char1 .modal__charzoom--k {
      margin-right: -39px;
      margin-top: -87px;
      height: 990px; }
    #character .modal.sanada.char1 .modal__control--int {
      display: inline-block; }
    #character .modal.sanada.char2 .modal__charvisual img {
      margin-right: -890px; }
    #character .modal.sanada.char3 .modal__charvisual img {
      margin-right: -950px; }
    #character .modal.sanada.char4 .modal__charvisual img {
      margin-right: -950px; }
    #character .modal.sanada.char5 .modal__charvisual img {
      margin-right: -940px; }
    #character .modal.date.char1 .modal__charvisual img {
      margin-right: -900px; }
    #character .modal.date.char1 .modal__charvisual--k {
      margin-top: 0px; }
    #character .modal.date.char1 .modal__charzoom--k {
      margin-right: -31px; }
    #character .modal.date.char1 .modal__control--int {
      display: inline-block; }
    #character .modal.date.char2 .modal__charvisual img {
      margin-right: -900px; }
    #character .modal.date.char3 .modal__charvisual img {
      margin-right: -900px; }
    #character .modal.mouri.char1 .modal__charvisual--a {
      margin: -157px -810px; }
    #character .modal.mouri.char1 .modal__charvisual--b {
      margin: -157px -810px; }
    #character .modal.mouri.char1 .modal__charvisual--k {
      margin: -157px -810px; }
    #character .modal.mouri.char1 .modal__charzoom--a {
      margin-right: -130px;
      margin-top: -80px;
      height: 970px; }
    #character .modal.mouri.char1 .modal__charzoom--b {
      margin-right: -90px;
      margin-top: -60px;
      height: 970px; }
    #character .modal.mouri.char1 .modal__charzoom--k {
      margin-right: -130px;
      margin-top: -80px;
      height: 970px; }
    #character .modal.mouri.char2 .modal__charvisual--a {
      margin: -330px -810px; }
    #character .modal.mouri.char2 .modal__charvisual--b {
      margin: -180px -859px; }
    #character .modal.mouri.char2 .modal__charzoom--a {
      margin-right: -230px;
      margin-top: -180px;
      height: 1200px; }
    #character .modal.mouri.char2 .modal__charzoom--b {
      margin-right: -80px;
      margin-top: -60px; }
    #character .modal.mouri.char3 .modal__charvisual--a {
      margin: -170px -780px; }
    #character .modal.mouri.char3 .modal__charvisual--b {
      margin: -170px -780px; }
    #character .modal.mouri.char3 .modal__charzoom--a {
      margin-right: -70px;
      margin-top: -60px; }
    #character .modal.mouri.char3 .modal__charzoom--b {
      margin-right: -70px;
      margin-top: -60px; }
    #character .modal.mouri.char4 .modal__charvisual--a {
      margin: -200px -750px; }
    #character .modal.mouri.char4 .modal__charvisual--b {
      margin: -200px -750px; }
    #character .modal.mouri.char4 .modal__charzoom--a {
      margin-right: -20px;
      margin-top: -60px; }
    #character .modal.mouri.char4 .modal__charzoom--b {
      margin-right: 30px;
      margin-top: -60px; }
    #character .modal.mouri.char5 .modal__charvisual--a {
      margin: 60px -790px; }
    #character .modal.mouri.char5 .modal__charvisual--b {
      margin: 30px -330px; }
    #character .modal.mouri.char5 .modal__charzoom img {
      height: 650px; }
    #character .modal.mouri.char5 .modal__charzoom--a {
      margin-right: -60px;
      margin-top: 30px; }
    #character .modal.mouri.char5 .modal__charzoom--b {
      margin-right: -10px;
      margin-top: 30px; }
    #character .modal.oda .modal__wrap {
      border-top: 13px solid #deb4f3;
      border-bottom: 13px solid #deb4f3; }
    #character .modal.oda .modal__chartext {
      background: rgba(219, 177, 241, 0.4); }
    #character .modal.oda .modal__charspec {
      background: rgba(219, 177, 241, 0.4); }
    #character .modal.oda .modal__charextra {
      background: rgba(219, 177, 241, 0.4); }
    #character .modal.toyotomi .modal__wrap {
      border-top: 13px solid #dac38f;
      border-bottom: 13px solid #dac38f; }
    #character .modal.toyotomi .modal__chartext {
      background: rgba(216, 191, 142, 0.4); }
    #character .modal.toyotomi .modal__charspec {
      background: rgba(216, 191, 142, 0.4); }
    #character .modal.toyotomi .modal__charextra {
      background: rgba(216, 191, 142, 0.4); }
    #character .modal.uesugi .modal__wrap {
      border-top: 13px solid #a5d3f2;
      border-bottom: 13px solid #a5d3f2; }
    #character .modal.uesugi .modal__chartext {
      background: rgba(166, 211, 240, 0.4); }
    #character .modal.uesugi .modal__charspec {
      background: rgba(166, 211, 240, 0.4); }
    #character .modal.uesugi .modal__charextra {
      background: rgba(166, 211, 240, 0.4); }
    #character .modal.takeda .modal__wrap {
      border-top: 13px solid #efaabf;
      border-bottom: 13px solid #efaabf; }
    #character .modal.takeda .modal__chartext {
      background: rgba(237, 171, 191, 0.4); }
    #character .modal.takeda .modal__charspec {
      background: rgba(237, 171, 191, 0.4); }
    #character .modal.takeda .modal__charextra {
      background: rgba(237, 171, 191, 0.4); }
    #character .modal.sanada .modal__wrap {
      border-top: 13px solid #de9e81;
      border-bottom: 13px solid #de9e81; }
    #character .modal.sanada .modal__chartext {
      background: rgba(227, 173, 143, 0.4); }
    #character .modal.sanada .modal__charspec {
      background: rgba(227, 173, 143, 0.4); }
    #character .modal.sanada .modal__charextra {
      background: rgba(227, 173, 143, 0.4); }
    #character .modal.date .modal__wrap {
      border-top: 13px solid #b9c2fd;
      border-bottom: 13px solid #b9c2fd; }
    #character .modal.date .modal__chartext {
      background: rgba(224, 229, 251, 0.4); }
    #character .modal.date .modal__charspec {
      background: rgba(224, 229, 251, 0.4); }
    #character .modal.date .modal__charextra {
      background: rgba(224, 229, 251, 0.4); }
    #character .modal.mouri .modal__wrap {
      border-top: 13px solid #5FDC94;
      border-bottom: 13px solid #5FDC94; }
    #character .modal.mouri .modal__chartext {
      background: rgba(95, 220, 148, 0.4);
      letter-spacing: -0.04em; }
    #character .modal.mouri .modal__charspec {
      background: rgba(95, 220, 148, 0.4); }
    #character .modal.mouri .modal__charextra {
      background: rgba(95, 220, 148, 0.4); }

html.magnifying > body {
  overflow-x: hidden !important; }

.magnify {
  position: relative;
  display: inline-block; }

.magnify .magnify-lens {
  /* Create the magnifying lens */
  position: absolute;
  width: 304px;
  height: 304px;
  border-radius: 100%;
  /* Multiple box shadows to achieve the glass lens effect */
  -webkit-box-shadow: 0 0 7px 7px rgba(0, 0, 0, 0.25), inset 0 0 40px 2px rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 7px 7px rgba(0, 0, 0, 0.25), inset 0 0 40px 2px rgba(0, 0, 0, 0.25);
  /* Hide the mouse pointer */
  cursor: none;
  /* Hide the lens by default */
  display: none;
  /* Place the lens on top of other elements */
  z-index: 100;
  overflow: visible;
  background-size: auto 2600px !important; }
  .magnify .magnify-lens#z1 {
    background-size: auto 2000px !important; }
  .magnify .magnify-lens#z2 {
    background-size: auto 2600px !important; }
  .magnify .magnify-lens#z3 {
    background-size: auto 3000px !important; }

.magnify .magnify-lens::after {
  content: '';
  display: block;
  background: url(/assets/img/character/modal/zoom_glass.png) center center no-repeat;
  width: 326px;
  height: 326px;
  margin-top: -12px;
  margin-left: -12px; }

.magnify .magnify-lens.loading {
  background: #333 !important;
  opacity: 0.8; }

.magnify .magnify-lens.loading:after {
  /* Loading text */
  position: absolute;
  top: 45%;
  left: 0;
  width: 100%;
  color: #fff;
  content: 'Loading...';
  font: italic normal 16px/1 Calibri, sans-serif;
  text-align: center;
  text-shadow: 0 0 2px rgba(51, 51, 51, 0.8);
  text-transform: none; }

.video-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 3000;
  display: none; }
  .video-modal .video-cont {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 854px;
    height: 480px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
    .video-modal .video-cont iframe {
      width: 100%;
      height: 100%; }
    .video-modal .video-cont .video-close {
      position: absolute;
      top: 0;
      right: 0;
      -webkit-transform: translateY(-100%);
      -ms-transform: translateY(-100%);
      transform: translateY(-100%); }

@media (max-width: 750px) {
  #character {
    padding-top: 140px;
    background: url(/assets/img/common/sp/bg_main.png) top 120px center no-repeat;
    min-width: auto; }
    #character .subnav {
      padding-bottom: 16px;
      background: url(/assets/img/common/sp/header_border.png) left bottom repeat-x; }
      #character .subnav__wrap {
        width: auto;
        margin: 0; }
      #character .subnav a {
        padding: 5px 20px;
        margin-right: 0;
        margin-left: 20px;
        font-size: 20px; }
    #character .group {
      padding-top: 72px; }
      #character .group__wrap {
        width: auto;
        margin: 0;
        height: 600px; }
      #character .group__title {
        min-width: auto;
        width: 100%;
        height: 72px;
        position: static;
        background: url(/assets/img/common/bg_content.png) 0 0 repeat;
        margin-bottom: -72px; }
        #character .group__title.sp-on {
          display: block; }
      #character .group--oda {
        background: url(/assets/img/character/oda_bg.jpg) top 72px center no-repeat; }
        #character .group--oda .group__wrap {
          background: url(/assets/img/character/oda_titlebg.png) bottom center no-repeat; }
        #character .group--oda .active .character__wrap {
          background-image: url(/assets/img/character/oda_char_bg.png); }
        #character .group--oda .character--1 .character__visual {
          left: -80px; }
        #character .group--oda .character--2 .character__visual {
          left: -90px; }
        #character .group--oda .character--3 .character__visual {
          left: 0px; }
        #character .group--oda .character--4 .character__visual {
          left: -110px; }
        #character .group--oda .character--5 .character__visual {
          left: -50px; }
      #character .group--toyotomi {
        background: url(/assets/img/character/toyotomi_bg.jpg) top 72px center no-repeat; }
        #character .group--toyotomi .group__wrap {
          background: url(/assets/img/character/toyotomi_titlebg.png) bottom center no-repeat; }
        #character .group--toyotomi .active .character__wrap {
          background-image: url(/assets/img/character/toyotomi_char_bg.png); }
        #character .group--toyotomi .character--1 .character__visual {
          left: -20px; }
        #character .group--toyotomi .character--2 .character__visual {
          left: 2px; }
        #character .group--toyotomi .character--3 .character__visual {
          left: -70px; }
        #character .group--toyotomi .character--4 .character__visual {
          left: -34px; }
        #character .group--toyotomi .character--5 .character__visual {
          left: -50px; }
      #character .group--uesugi {
        background: url(/assets/img/character/uesugi_bg.jpg) top 72px center no-repeat; }
        #character .group--uesugi .group__wrap {
          background: url(/assets/img/character/uesugi_titlebg.png) bottom center no-repeat; }
        #character .group--uesugi .active .character__wrap {
          background-image: url(/assets/img/character/uesugi_char_bg.png); }
        #character .group--uesugi .character--1 .character__visual {
          left: -50px; }
        #character .group--uesugi .character--2 .character__visual {
          left: -68px; }
        #character .group--uesugi .character--3 .character__visual {
          left: -60px; }
        #character .group--uesugi .character--4 .character__visual {
          left: -10px; }
        #character .group--uesugi .character--5 .character__visual {
          left: -30px; }
      #character .group--takeda {
        background: url(/assets/img/character/takeda_bg.jpg) top 72px center no-repeat; }
        #character .group--takeda .group__wrap {
          background: url(/assets/img/character/takeda_titlebg.png) bottom center no-repeat; }
        #character .group--takeda .active .character__wrap {
          background-image: url(/assets/img/character/takeda_char_bg.png); }
        #character .group--takeda .character--1 .character__visual {
          left: -60px; }
        #character .group--takeda .character--2 .character__visual {
          left: -78px; }
        #character .group--takeda .character--3 .character__visual {
          left: -30px; }
        #character .group--takeda .character--4 .character__visual {
          left: -50px; }
        #character .group--takeda .character--5 .character__visual {
          left: -40px; }
      #character .group--sanada {
        background: url(/assets/img/character/sanada_bg.jpg) top 72px center no-repeat; }
        #character .group--sanada .group__wrap {
          background: url(/assets/img/character/sanada_titlebg.png) bottom center no-repeat; }
        #character .group--sanada .active .character__wrap {
          background-image: url(/assets/img/character/sanada_char_bg.png); }
        #character .group--sanada .character--1 .character__visual {
          left: -100px; }
        #character .group--sanada .character--2 .character__visual {
          left: -38px; }
        #character .group--sanada .character--3 .character__visual {
          left: -90px; }
        #character .group--sanada .character--4 .character__visual {
          left: -7px; }
        #character .group--sanada .character--5 .character__visual {
          left: -45px; }
      #character .group--date {
        background: url(/assets/img/character/date_bg.jpg) top 72px center no-repeat; }
        #character .group--date .group__wrap {
          background: url(/assets/img/character/date_titlebg.png) bottom center no-repeat; }
        #character .group--date .active .character__wrap {
          background-image: url(/assets/img/character/date_char_bg.png); }
        #character .group--date .character--1 .character__visual {
          left: -30px; }
        #character .group--date .character--2 .character__visual {
          left: -148px; }
        #character .group--date .character--3 .character__visual {
          left: -60px; }
      #character .group--mouri {
        background: url(/assets/img/character/mouri_bg.jpg) top 72px center no-repeat; }
        #character .group--mouri .group__wrap {
          background: url(/assets/img/character/mouri_titlebg.png) bottom center no-repeat; }
        #character .group--mouri .active .character__wrap {
          background-image: url(/assets/img/character/mouri_char_bg.png); }
        #character .group--mouri.gather .character--2 {
          left: 140px; }
        #character .group--mouri.gather .character--3 {
          left: 463px; }
        #character .group--mouri.gather .character--4 .character__catch {
          left: 20px; }
        #character .group--mouri.gather .character--5 {
          left: 660px; }
          #character .group--mouri.gather .character--5 .character__name {
            right: 46px; }
    #character .gather .character {
      bottom: 0px;
      opacity: 1; }
      #character .gather .character--1 {
        left: 300px; }
      #character .gather .character--2 {
        left: 150px; }
      #character .gather .character--3 {
        left: 450px; }
      #character .gather .character--4 {
        left: 0px; }
      #character .gather .character--5 {
        left: 600px; }
    #character .character {
      transition: all 1.5s ease 0s;
      opacity: 0;
      position: absolute;
      width: 150px;
      height: 550px;
      bottom: -300px; }
      #character .character--1 {
        left: 300px; }
      #character .character--2 {
        left: 0px; }
      #character .character--3 {
        left: 600px; }
      #character .character--4 {
        left: -150px; }
      #character .character--5 {
        left: 750px; }
      #character .character--new::after {
        top: -40px; }
      #character .character__wrap {
        position: relative;
        overflow: visible;
        width: 150px;
        height: 550px;
        transition: all 0.5s ease 0s;
        background-image: none;
        background-repeat: no-repeat;
        background-position: bottom 0px left -150px;
        right: 25px; }
      #character .character.active .character__wrap {
        background-position: bottom 0px left 0px; }
      #character .character__visual {
        position: absolute;
        bottom: 0;
        left: -100px;
        z-index: 0; }
      #character .character__catch img {
        display: none; }
      #character .character__catch:hover img {
        opacity: 0; }
      #character .character__name {
        width: 50px;
        height: 259px;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 300; }
      #character .character--1 .character__visual {
        z-index: 1; }
    #character .modal {
      display: none;
      position: absolute;
      top: 100px;
      left: 0;
      width: 100%;
      min-width: 750px;
      height: 1120px;
      background-size: auto 100%; }
      #character .modal__wrap {
        position: relative;
        width: 100%;
        height: 1120px;
        box-sizing: border-box; }
      #character .modal__control {
        position: absolute;
        z-index: 2000; }
        #character .modal__control--close {
          top: 0px;
          right: 10px; }
        #character .modal__control--left {
          display: none;
          top: 800px;
          left: 10px; }
          #character .modal__control--left img {
            width: 60px; }
        #character .modal__control--right {
          display: none;
          top: 800px;
          right: 10px; }
          #character .modal__control--right img {
            width: 60px; }
        #character .modal__control--k a {
          margin-right: 0; }
      #character .modal__char {
        width: 100%;
        position: relative;
        height: 1094px;
        overflow: hidden; }
        #character .modal__charvisual {
          display: none; }
        #character .modal__charcatch {
          left: 5px !important;
          top: auto !important;
          bottom: 20px;
          z-index: 200; }
          #character .modal__charcatch img {
            height: 650px; }
        #character .modal__charzoom {
          display: block;
          overflow: visible;
          top: 0;
          height: 1174px;
          position: relative;
          text-align: right;
          width: 105%; }
          #character .modal__charzoom img {
            height: 1600px;
            width: auto;
            margin-right: 150px; }
        #character .modal__charinfo {
          padding-top: 64px;
          width: 100%; }
        #character .modal__charcopy {
          width: 100%;
          overflow: hidden; }
          #character .modal__charcopy img {
            display: block;
            width: 110%;
            height: auto; }
        #character .modal__charname {
          position: static;
          width: 300px;
          margin-left: 160px;
          margin-bottom: 0; }
          #character .modal__charname img {
            width: 275px;
            height: auto; }
        #character .modal__charbtns {
          width: 380px;
          margin-left: 70px;
          margin-right: 20px;
          margin-bottom: 5px;
          text-align: right; }
          #character .modal__charbtns .modal__control--all {
            display: none; }
        #character .modal__chartext {
          font-size: 16px;
          line-height: 22px;
          margin: 0 20px 0 100px;
          padding: 10px;
          width: auto; }
        #character .modal__charspec {
          margin: 0 20px 0 100px;
          padding: 10px;
          width: auto;
          font-size: 16px;
          line-height: 22px; }
          #character .modal__charspec dt {
            float: none; }
          #character .modal__charspec dd {
            float: none;
            width: auto; }
        #character .modal__charextra {
          margin: 10px 20px 0 100px;
          padding: 10px;
          width: auto;
          font-size: 16px;
          line-height: 22px; }
      #character .modal__info {
        width: 470px;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 45px; }
      #character .modal__leader {
        margin-left: 20px;
        height: 60px;
        overflow: hidden;
        margin-bottom: 18px; }
      #character .modal__btns {
        padding-left: 0; }
        #character .modal__btns img {
          display: block; }
        #character .modal__btnszoom {
          display: none; }
        #character .modal__btnsvoice {
          overflow: hidden; }
          #character .modal__btnsvoice li {
            float: left; }
      #character .modal .modal__char .modal__charvisual img, #character .modal .modal__char .modal__charzoom img {
        display: none; }
      #character .modal .modal__char .modal__charzoom .modal__charzoom--a {
        display: block; }
      #character .modal .modal__char.secondary .modal__charvisual img, #character .modal .modal__char.secondary .modal__charzoom img {
        display: none; }
      #character .modal .modal__char.secondary .modal__charzoom .modal__charzoom--b {
        display: block; }
      #character .modal .modal__char.kakusei .modal__charvisual img, #character .modal .modal__char.kakusei .modal__charzoom img {
        display: none; }
      #character .modal .modal__char.kakusei .modal__charzoom .modal__charzoom--k {
        display: block; }
      #character .modal.oda.char1 .modal__wrap {
        height: auto; }
      #character .modal.oda.char1 .modal__charzoom {
        top: 0; }
      #character .modal.oda.char1 .modal__charzoom--a {
        margin: 40px 30px; }
      #character .modal.oda.char1 .modal__charzoom--b {
        margin: 30px 140px; }
      #character .modal.oda.char1 .modal__charzoom--k {
        margin: 39px -6px; }
      #character .modal.oda.char2 .modal__charcatch {
        top: 200px; }
      #character .modal.oda.char2 .modal__charzoom--a {
        margin: -50px 340px; }
      #character .modal.oda.char2 .modal__charzoom--b {
        margin: 40px 220px; }
      #character .modal.oda.char3 .modal__charzoom img {
        height: 1440px; }
      #character .modal.oda.char3 .modal__charzoom--a {
        margin: 20px 140px; }
      #character .modal.oda.char3 .modal__charzoom--b {
        margin: 40px 250px; }
      #character .modal.oda.char4 .modal__charzoom--a {
        margin: -50px 140px; }
      #character .modal.oda.char4 .modal__charzoom--b {
        margin: 20px 320px; }
      #character .modal.oda.char5 .modal__charzoom--a {
        margin: 20px 200px; }
      #character .modal.oda.char5 .modal__charzoom--b {
        margin: 20px 200px; }
      #character .modal.uesugi.char1 .modal__wrap {
        height: auto; }
      #character .modal.uesugi.char1 .modal__charzoom {
        top: 0; }
      #character .modal.uesugi.char1 .modal__charzoom--a {
        margin: -50px 270px; }
      #character .modal.uesugi.char1 .modal__charzoom--b {
        margin: 10px 300px; }
      #character .modal.uesugi.char1 .modal__charzoom--k {
        margin: -50px 69px; }
      #character .modal.uesugi.char2 .modal__charzoom--a {
        margin: -20px 300px; }
      #character .modal.uesugi.char2 .modal__charzoom--b {
        margin: 10px 300px; }
      #character .modal.uesugi.char3 .modal__charzoom--a {
        margin: 10px 250px; }
      #character .modal.uesugi.char3 .modal__charzoom--b {
        margin: 10px 250px; }
      #character .modal.uesugi.char4 .modal__charzoom--a {
        margin: 10px 240px; }
      #character .modal.uesugi.char4 .modal__charzoom--b {
        margin: 10px 260px; }
      #character .modal.uesugi.char5 .modal__charzoom img {
        height: 1520px; }
      #character .modal.uesugi.char5 .modal__charzoom--a {
        margin: 10px 120px; }
      #character .modal.uesugi.char5 .modal__charzoom--b {
        margin: 10px 170px; }
      #character .modal.toyotomi.char1 .modal__wrap {
        height: auto; }
      #character .modal.toyotomi.char1 .modal__charzoom {
        top: 0; }
      #character .modal.toyotomi.char1 .modal__charzoom--a {
        margin: -20px 310px; }
      #character .modal.toyotomi.char1 .modal__charzoom--b {
        margin: -20px 310px; }
      #character .modal.toyotomi.char1 .modal__charzoom--k {
        margin: -20px 310px; }
      #character .modal.toyotomi.char1 .modal__charextra {
        min-height: 140px; }
      #character .modal.toyotomi.char1 .modal__charextra02 {
        top: 10px; }
        #character .modal.toyotomi.char1 .modal__charextra02 img {
          width: 150px;
          height: auto; }
      #character .modal.toyotomi.char2 .modal__charcatch {
        top: 195px; }
      #character .modal.toyotomi.char2 .modal__charzoom--a {
        margin: -80px 240px; }
      #character .modal.toyotomi.char2 .modal__charzoom--b {
        margin: 0px 250px; }
      #character .modal.toyotomi.char3 .modal__charcatch {
        top: 200px; }
      #character .modal.toyotomi.char3 .modal__charzoom--a {
        margin: 10px 280px; }
      #character .modal.toyotomi.char3 .modal__charzoom--b {
        margin: 0px 230px; }
      #character .modal.toyotomi.char4 .modal__charzoom--a {
        margin: -80px 190px; }
      #character .modal.toyotomi.char4 .modal__charzoom--b {
        margin: -100px 240px; }
      #character .modal.toyotomi.char5 .modal__charzoom--a {
        margin: -60px 250px; }
      #character .modal.toyotomi.char5 .modal__charzoom--b {
        margin: 0px 320px; }
      #character .modal.takeda.char1 .modal__wrap {
        height: auto; }
      #character .modal.takeda.char1 .modal__charzoom {
        top: 0; }
      #character .modal.takeda.char1 .modal__charzoom--a {
        margin: 10px 140px; }
      #character .modal.takeda.char1 .modal__charzoom--b {
        margin: 10px 180px; }
      #character .modal.takeda.char1 .modal__charzoom--k {
        margin: 10px 141px; }
      #character .modal.takeda.char2 .modal__charzoom img {
        height: 1520px; }
      #character .modal.takeda.char2 .modal__charzoom--a {
        margin: -30px 230px; }
      #character .modal.takeda.char2 .modal__charzoom--b {
        margin: 20px 230px; }
      #character .modal.takeda.char3 .modal__charzoom--a {
        margin: 20px 150px; }
      #character .modal.takeda.char3 .modal__charzoom--b {
        margin: 10px 200px; }
      #character .modal.takeda.char4 .modal__charcatch {
        top: 200px; }
      #character .modal.takeda.char4 .modal__charzoom--a {
        margin: 0px 170px; }
      #character .modal.takeda.char4 .modal__charzoom--b {
        margin: 0px 300px; }
      #character .modal.takeda.char5 .modal__charzoom--a {
        margin: -50px 130px; }
      #character .modal.takeda.char5 .modal__charzoom--b {
        margin: -10px 250px; }
      #character .modal.sanada.char1 .modal__wrap {
        height: auto; }
      #character .modal.sanada.char1 .modal__charzoom {
        top: 0; }
      #character .modal.sanada.char1 .modal__charzoom--a {
        height: 1800px;
        margin: -240px 290px; }
      #character .modal.sanada.char1 .modal__charzoom--b {
        margin: -10px 320px; }
      #character .modal.sanada.char1 .modal__charzoom--k {
        height: 1800px;
        margin: -234px 274px; }
      #character .modal.sanada.char2 .modal__charzoom img {
        margin: -10px 220px; }
      #character .modal.sanada.char3 .modal__charzoom img {
        height: 1440px; }
      #character .modal.sanada.char3 .modal__charzoom--a {
        margin: -10px 310px; }
      #character .modal.sanada.char3 .modal__charzoom--b {
        margin: 20px 230px; }
      #character .modal.sanada.char4 .modal__charzoom img {
        margin: -20px 230px; }
      #character .modal.sanada.char5 .modal__charzoom--a {
        margin: -90px 280px; }
      #character .modal.sanada.char5 .modal__charzoom--b {
        margin: -20px 210px; }
      #character .modal.date.char1 .modal__wrap {
        height: auto; }
      #character .modal.date.char1 .modal__charzoom {
        top: 0; }
      #character .modal.date.char1 .modal__charzoom--a {
        margin: 30px 300px; }
      #character .modal.date.char1 .modal__charzoom--b {
        margin: 20px 330px; }
      #character .modal.date.char1 .modal__charzoom--k {
        margin: 30px 251px; }
      #character .modal.date.char2 .modal__charzoom img {
        height: 1520px; }
      #character .modal.date.char2 .modal__charzoom--a {
        margin: 20px 270px; }
      #character .modal.date.char2 .modal__charzoom--b {
        margin: 20px 370px; }
      #character .modal.date.char3 .modal__charzoom--a {
        margin: 0px 320px; }
      #character .modal.date.char3 .modal__charzoom--b {
        margin: 30px 280px; }
      #character .modal.mouri.char1 .modal__wrap {
        height: auto; }
      #character .modal.mouri.char1 .modal__charzoom {
        top: 0; }
      #character .modal.mouri.char1 .modal__charzoom--a {
        margin: -20px 50px;
        height: 1600px; }
      #character .modal.mouri.char1 .modal__charzoom--b {
        margin: 10px 120px;
        height: 1600px; }
      #character .modal.mouri.char1 .modal__charzoom--k {
        margin: -20px 50px;
        height: 1600px; }
      #character .modal.mouri.char2 .modal__wrap {
        height: auto; }
      #character .modal.mouri.char2 .modal__charzoom {
        top: 0; }
      #character .modal.mouri.char2 .modal__charzoom--a {
        margin: -100px 60px;
        height: 1600px; }
      #character .modal.mouri.char2 .modal__charzoom--b {
        margin: 40px 170px;
        height: 1600px; }
      #character .modal.mouri.char3 .modal__wrap {
        height: auto; }
      #character .modal.mouri.char3 .modal__charzoom {
        top: 0; }
      #character .modal.mouri.char3 .modal__charzoom--a {
        margin: -20px 190px; }
      #character .modal.mouri.char3 .modal__charzoom--b {
        margin: -20px 190px; }
      #character .modal.mouri.char4 .modal__wrap {
        height: auto; }
      #character .modal.mouri.char4 .modal__charzoom {
        top: 0; }
      #character .modal.mouri.char4 .modal__charzoom--a {
        margin: 30px 290px; }
      #character .modal.mouri.char4 .modal__charzoom--b {
        margin: 30px 340px; }
      #character .modal.mouri.char5 .modal__wrap {
        height: auto; }
      #character .modal.mouri.char5 .modal__charzoom {
        top: 0; }
        #character .modal.mouri.char5 .modal__charzoom img {
          height: auto; }
      #character .modal.mouri.char5 .modal__charzoom--a {
        margin: 30px 220px; }
      #character .modal.mouri.char5 .modal__charzoom--b {
        margin: 30px 220px; }
      #character .modal.sanada .modal__chartext {
        background: rgba(250, 224, 201, 0.7); }
      #character .modal.sanada .modal__charspec {
        background: rgba(250, 224, 201, 0.7); }
      #character .modal.date .modal__chartext {
        background: rgba(224, 229, 251, 0.7); }
      #character .modal.date .modal__charspec {
        background: rgba(224, 229, 251, 0.7); } }

#character .guns__nav {
  width: 960px;
  margin: 0 auto;
  padding: 50px 0; }
  #character .guns__nav a {
    position: relative;
    display: inline-block; }
    #character .guns__nav a.guns__nav--new::after {
      content: '';
      position: absolute;
      width: 29px;
      height: 10px;
      top: -10px;
      left: 14px;
      background: url(/assets/img/common/ico_subnavnew.png) 0 0 no-repeat;
      background-size: auto 10px; }

#character .gun__wrap {
  width: 960px;
  margin: 0 auto;
  padding-bottom: 30px;
  position: relative; }

#character .gun__btnmovie {
  position: absolute;
  top: 8px;
  left: 220px; }

#character .gun__title {
  margin-bottom: 10px; }

#character .gun__chart {
  margin-bottom: 10px; }

#character .gun__text {
  font-size: 18px;
  line-height: 32px; }

#character .gun__cs {
  margin-bottom: 5px; }

#character .gun#oda {
  border-top: 12px solid #dfb5f4; }
  #character .gun#oda .gun__wrap {
    background: url(/assets/img/character/gun/bg_oda.png) center right no-repeat; }

#character .gun#toyotomi {
  border-top: 12px solid #dbc490; }
  #character .gun#toyotomi .gun__wrap {
    background: url(/assets/img/character/gun/bg_toyotomi.png) center right no-repeat; }

#character .gun#uesugi {
  border-top: 12px solid #a6c7ef; }
  #character .gun#uesugi .gun__wrap {
    background: url(/assets/img/character/gun/bg_uesugi.png) center right no-repeat; }

#character .gun#takeda {
  border-top: 12px solid #efa2b5; }
  #character .gun#takeda .gun__wrap {
    background: url(/assets/img/character/gun/bg_takeda.png) center right no-repeat; }

#character .gun#sanada {
  border-top: 12px solid #e4ae8d; }
  #character .gun#sanada .gun__wrap {
    background: url(/assets/img/character/gun/bg_sanada.png) center right no-repeat; }

#character .gun#date {
  border-top: 12px solid #b4bafb; }
  #character .gun#date .gun__wrap {
    background: url(/assets/img/character/gun/bg_date.png) center right no-repeat; }

#character .gunmodal {
  display: none;
  width: 100%;
  position: absolute;
  top: 100px;
  left: 0;
  overflow: hidden;
  z-index: 2000; }
  #character .gunmodal__close {
    position: absolute;
    top: 0;
    right: 0; }
  #character .gunmodal__cover {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    background-color: #d3ceea;
    z-index: 1000; }
  #character .gunmodal__wrap {
    padding-top: 50px;
    width: 838px;
    height: 530px;
    margin: 0 auto;
    position: relative; }
  #character .gunmodal__body {
    border: 10px solid #FFF;
    width: 100%;
    height: 100%; }
    #character .gunmodal__body iframe {
      width: 100%;
      height: 100%; }

@media (max-width: 750px) {
  #character .guns__nav {
    width: auto;
    margin: 0;
    padding: 30px 30px; }
    #character .guns__nav img {
      width: 110px;
      height: auto; }
  #character .gun__wrap {
    width: auto;
    margin: 0; }
  #character .gun__chart {
    padding: 0 10px; }
    #character .gun__chart img {
      width: 100%;
      height: auto; }
  #character .gun__text {
    padding: 0 20px; }
  #character .gun__cs {
    padding: 0 20px; }
    #character .gun__cs img {
      width: 100%;
      height: auto; }
  #character .gunmodal {
    top: 900px; }
    #character .gunmodal__wrap {
      width: 730px;
      height: 469px; }
  #character .video-modal .video-cont {
    width: 80%;
    height: 30%; } }

#character .gun#mouri .gun__wrap {
  height: 550px;
  background: url(/assets/img/character/gun/bg_mouri.png) center right no-repeat; }

#character .gun#mouri {
  border-top: 12px solid #3EB370; }

.modal.mouri .modal__control--int {
  display: none !important; }

#about {
  padding-top: 152px;
  background: url(/assets/img/common/header_bg.png) top center no-repeat;
  background-size: 100% auto; }
  #about .content {
    padding-bottom: 0; }
  #about .title {
    width: 100%;
    height: 80px;
    text-align: center;
    background: url(/assets/img/about/title_bg.png) top center no-repeat;
    margin-bottom: 20px; }
    #about .title img {
      padding-top: 10px; }
  #about .decbox {
    width: 960px;
    margin: 0 auto;
    box-sizing: border-box;
    background: url(/assets/img/about/floral_top.png) top 14px center no-repeat, url(/assets/img/about/floral_btm.png) bottom 14px center no-repeat, url(/assets/img/about/floral_grad.png) top left repeat-x, url(/assets/img/about/floral_pattern.png) top left repeat;
    border: 4px solid #e8c95e;
    padding: 32px 0;
    position: relative;
    text-align: center; }
    #about .decbox::before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 6px;
      left: 6px;
      bottom: 6px;
      right: 6px;
      border: 1px solid #e8c95e; }
    #about .decbox .decbox__thumb {
      position: relative;
      z-index: 2;
      border: 14px solid #6b6096;
      width: 700px;
      height: 405px;
      margin: 0 auto 26px; }
      #about .decbox .decbox__thumb iframe {
        width: 100%;
        height: 100%; }
    #about .decbox .decbox__title {
      margin-bottom: 20px; }
    #about .decbox .decbox__text {
      text-align: center;
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 32px;
      text-shadow: 0 0 6px #FFF; }
  #about .project {
    background: url(/assets/img/about/project_bg.png) top right 20px no-repeat;
    background-attachment: fixed; }
  #about .about__block {
    padding-bottom: 35px; }
  #about .cast {
    padding-bottom: 80px;
    background: url(/assets/img/about/project_bg.png) top right 20px no-repeat;
    background-attachment: fixed; }
    #about .cast__list {
      width: 777px;
      overflow: hidden;
      margin: 0 auto 20px;
      z-index: 100; }
    #about .cast__line {
      display: table;
      width: 100%;
      position: relative;
      z-index: 2;
      border-spacing: 10px 5px; }
      #about .cast__line a {
        display: table-cell;
        width: 50%;
        vertical-align: bottom;
        text-align: center; }
        #about .cast__line a img {
          display: inline; }
    #about .cast__memo {
      margin-bottom: 20px;
      width: 100%;
      text-align: center; }
    #about .cast__comingsoon {
      font-size: 20px; }
  #about .illust {
    background: url(/assets/img/about/project_bg.png) top right 20px no-repeat;
    background-attachment: fixed; }
    #about .illust__list {
      width: 760px;
      margin: 0 auto;
      overflow: hidden;
      position: relative;
      z-index: 2; }
    #about .illust__banner {
      float: left;
      width: 50%;
      display: block;
      text-align: center;
      margin-bottom: 30px; }
      #about .illust__banner img {
        display: block;
        opacity: 1;
        transition: all 0.5s linear 0s; }
      #about .illust__banner:hover img {
        opacity: 0.8;
        filter: none; }

@media (max-width: 750px) {
  #about {
    padding-top: 140px;
    background: url(/assets/img/common/sp/bg_main.png) top 120px center repeat-x;
    min-width: auto; }
    #about .decbox {
      width: 750px;
      background-size: 710px auto, 710px auto, auto, auto; }
      #about .decbox .decbox__thumb {
        width: 560px;
        height: 325px; }
    #about .cast__list {
      width: 100%; }
      #about .cast__list img {
        width: 350px; }
    #about .cast__line {
      border-spacing: 10px 0px; }
    #about .illust__list {
      width: 700px;
      text-align: center; }
    #about .illust__banner {
      margin-bottom: 20px; }
      #about .illust__banner img {
        display: inline;
        width: 95%; } }

#special {
  padding-top: 152px;
  background: url(/assets/img/common/header_bg.png) top center no-repeat;
  background-size: 100% auto; }
  #special .main .content {
    padding-top: 16px;
    padding-bottom: 0; }
  #special .subnav {
    padding-bottom: 16px;
    background: url(/assets/img/common/header_border.png) left bottom repeat-x;
    width: 100%; }
    #special .subnav__wrap {
      width: 960px;
      margin: 0 auto; }
    #special .subnav a {
      background: #c1a0cc;
      border-radius: 6px;
      color: #FFF;
      padding: 5px 20px;
      margin-right: 15px;
      font-size: 14px;
      display: inline-block;
      position: relative; }
      #special .subnav a:hover {
        background: #aa4eab; }
      #special .subnav a.active {
        background: #aa4eab;
        cursor: default; }
      #special .subnav a:last-child {
        margin-top: 20px; }
    #special .subnav--new::after {
      content: '';
      position: absolute;
      width: 29px;
      height: 10px;
      top: -10px;
      left: 4px;
      background: url(/assets/img/common/ico_subnavnew.png) 0 0 no-repeat;
      background-size: auto 10px; }
  #special .title {
    width: 100%;
    height: 96px;
    margin-top: 20px;
    text-align: center;
    background: url(/assets/img/special/title_bg.png) top center no-repeat; }
    #special .title img {
      padding-top: 0;
      max-width: 100%; }
  #special .title--fankit {
    margin-top: 20px;
    margin-bottom: 25px; }
  #special .commentary {
    position: relative;
    z-index: 100;
    font-size: 18px;
    color: #111111;
    line-height: 1.8;
    margin-bottom: 20px; }
    #special .commentary a:hover {
      text-decoration: underline; }
  #special .decbox {
    width: 960px;
    margin: 0 auto;
    box-sizing: border-box;
    background: url(/assets/img/special/floral_top.png) top 14px center no-repeat, url(/assets/img/special/floral_btm.png) bottom 14px center no-repeat, url(/assets/img/special/floral_grad.png) top left repeat-x, url(/assets/img/special/floral_pattern.png) top left repeat;
    border: 4px solid #e8c95e;
    padding: 32px 0;
    position: relative;
    text-align: center; }
    #special .decbox::before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 6px;
      left: 6px;
      bottom: 6px;
      right: 6px;
      border: 1px solid #e8c95e; }
    #special .decbox__thumb {
      margin-bottom: 26px;
      position: relative;
      z-index: 2; }
    #special .decbox__title {
      margin-bottom: 20px; }
    #special .decbox__text {
      text-align: center;
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 32px;
      text-shadow: 0 0 6px #FFF;
      position: relative;
      z-index: 2; }
      #special .decbox__text a {
        color: #2384C8;
        text-decoration: underline; }
        #special .decbox__text a:hover {
          text-decoration: none; }
      #special .decbox__text--twitter {
        margin-bottom: 70px; }
    #special .decbox__center {
      text-align: center;
      margin-bottom: 10px; }
    #special .decbox__info {
      width: 760px;
      margin: 0 auto;
      text-align: left;
      font-size: 14px;
      margin-bottom: 90px;
      position: relative;
      z-index: 2; }
      #special .decbox__info a {
        color: #2384C8;
        text-decoration: underline; }
        #special .decbox__info a:hover {
          text-decoration: none; }
      #special .decbox__info li {
        padding-left: 20px;
        position: relative; }
        #special .decbox__info li::before {
          content: '・';
          position: absolute;
          top: 0;
          left: 0; }
  #special .camp {
    background: url(/assets/img/special/project_bg.png) top right 20px no-repeat;
    background-attachment: fixed; }
    #special .camp__visual {
      text-align: center;
      overflow: hidden; }
      #special .camp__visual img {
        margin-top: -6px;
        margin-left: -5px;
        margin-bottom: -6px; }
    #special .camp__step {
      width: 100%;
      position: relative;
      z-index: 2; }
      #special .camp__step--1 {
        background: url(/assets/img/special/camp_03_step01_bg.png) top 0 left 30px no-repeat, url(/assets/img/special/camp_03_step_arrow.png) bottom 20px left 75px no-repeat; }
        #special .camp__step--1 .camp__steptitle {
          height: 220px; }
      #special .camp__step--2 {
        background: url(/assets/img/special/camp_03_step02_bg.png) top 0 left 30px no-repeat, url(/assets/img/special/camp_03_step_arrow.png) bottom 20px left 75px no-repeat;
        padding-bottom: 100px; }
        #special .camp__step--2 .camp__steptitle {
          margin-bottom: 10px; }
      #special .camp__step--3 {
        background: url(/assets/img/special/camp_03_step03_bg.png) top 0 left 30px no-repeat;
        margin-bottom: 50px; }
        #special .camp__step--3 .camp__steptitle {
          height: 60px; }
      #special .camp__steptitle {
        padding-top: 7px;
        padding-left: 200px;
        text-align: left; }
      #special .camp__stepbtn {
        display: block;
        margin-top: 15px; }
      #special .camp__stepbanners {
        padding-left: 140px;
        overflow: hidden; }
      #special .camp__stepbanner {
        float: left;
        width: 376px;
        height: 149px;
        margin-right: 30px; }
  #special .about__block {
    padding-bottom: 35px; }
  #special .cast {
    background: url(/assets/img/about/cast_bg.png) center center no-repeat;
    background-attachment: fixed; }
    #special .cast__list {
      width: 690px;
      margin: 0 auto;
      overflow: hidden;
      margin-bottom: 50px;
      z-index: 100; }
    #special .cast__line {
      width: 50%;
      height: 38px;
      float: left;
      overflow: hidden;
      text-align: left;
      position: relative;
      z-index: 2; }
      #special .cast__line--vc {
        text-indent: 5px;
        width: 56%;
        float: left;
        font-size: 24px; }
      #special .cast__line--char {
        width: 44%;
        float: left;
        font-size: 19px;
        padding-top: 5px; }
      #special .cast__line:nth-child(odd) .cast__line--vc {
        width: 50%; }
      #special .cast__line:nth-child(odd) .cast__line--char {
        width: 50%; }
    #special .cast__memo {
      margin-bottom: 20px;
      width: 100%;
      text-align: center; }
    #special .cast__comingsoon {
      font-size: 20px; }
  #special .illust__list {
    width: 760px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 2; }
  #special .illust__banner {
    float: left;
    width: 50%;
    display: block;
    text-align: center;
    margin-bottom: 30px; }
    #special .illust__banner img {
      display: block; }
  #special .embedded-list {
    margin: 0 40px; }
    #special .embedded-list:after {
      content: "";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden; }
  #special .embedded {
    position: relative;
    width: 48%;
    float: left;
    background-color: #fff;
    margin-right: 2%;
    margin-bottom: 2%;
    z-index: 50; }
    #special .embedded:nth-child(2n) {
      margin-right: 0; }
    #special .embedded__title {
      position: relative;
      z-index: 30; }
      #special .embedded__title img {
        width: 100%; }
    #special .embedded__tweet {
      position: relative;
      top: -20px;
      z-index: 20; }
  #special .normal-inner {
    width: 960px;
    margin: 0 auto 10px auto;
    padding: 20px 0;
    background: #ffffff;
    /* Old browsers */
    background: -moz-linear-gradient(top, white 0%, white 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, white 0%, white 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, white 0%, rgba(255, 255, 255, 0.7) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }
  #special .normal-inner--center {
    text-align: center; }
  #special .radio-key {
    width: 960px;
    margin: 0 auto;
    box-shadow: 0px 2px 10px rgba(100, 100, 100, 0.4); }
    #special .radio-key img {
      width: 100%;
      height: auto;
      display: block; }
  #special .taping-nav {
    margin-bottom: 30px; }
    #special .taping-nav li {
      display: inline-block; }
  #special .taping-area {
    padding-bottom: 50px; }
  #special .taping-title {
    margin-bottom: 30px;
    text-align: left;
    padding-left: 10px; }
  #special .taping-list {
    width: 820px;
    margin: 0 auto;
    overflow: hidden; }
  #special .taping {
    width: 380px;
    overflow: hidden;
    padding: 15px;
    margin-bottom: 20px; }
    #special .taping:nth-child(odd) {
      float: left; }
    #special .taping:nth-child(even) {
      float: right; }
    #special .taping__thum {
      margin-top: 15px;
      margin-right: 10px;
      float: left; }
      #special .taping__thum img {
        display: block; }
    #special .taping__btn {
      float: left; }
      #special .taping__btn li:hover {
        cursor: pointer;
        opacity: 0.7; }
  #special #taping-oda .taping-title {
    border-top: 3px solid #e6c6f6; }
  #special #taping-oda .taping {
    background: #f4e7f9; }
  #special #taping-toyotomi .taping-title {
    border-top: 3px solid #dcc693; }
  #special #taping-toyotomi .taping {
    background: #f9f3e4; }
  #special #taping-uesugi .taping-title {
    border-top: 3px solid #a5d2f2; }
  #special #taping-uesugi .taping {
    background: #eff1f6; }
  #special #taping-takeda .taping-title {
    border-top: 3px solid #efa8ba; }
  #special #taping-takeda .taping {
    background: #fbeded; }
  #special #taping-sanada .taping-title {
    border-top: 3px solid #e6b190; }
  #special #taping-sanada .taping {
    background: #faebe4; }
  #special #taping-date .taping-title {
    border-top: 3px solid #bec6fc; }
  #special #taping-date .taping {
    background: #eff0fd; }
  #special #taping-mouri .taping-title {
    border-top: 3px solid #5FDC94; }
  #special #taping-mouri .taping {
    background: #d9f8e6; }
  #special .modal-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(160, 160, 160, 0.9);
    z-index: 100;
    display: none; }
  #special .modal-img-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
    #special .modal-img-inner img {
      width: 100%; }
  #special .modal-close {
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    z-index: 100; }
    #special .modal-close img {
      width: 100%; }
  #special .modal-img--twihead,
  #special .modal-img--wallpc {
    width: 960px; }
  #special .modal-img--twiico,
  #special .modal-img--wallsp {
    width: 300px; }
  #special .yonkoma-list {
    width: 920px;
    margin: 0 auto 10px auto;
    overflow: hidden;
    position: relative;
    z-index: 30;
    padding-top: 10px; }
    #special .yonkoma-list li {
      position: relative;
      width: 300px;
      float: left;
      margin-right: 10px; }
      #special .yonkoma-list li:hover {
        opacity: 0.7; }
      #special .yonkoma-list li:nth-child(3n) {
        margin-right: 0; }
      #special .yonkoma-list li.new-koma:before {
        position: absolute;
        -webkit-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        transform: translateY(-100%);
        left: 2px;
        content: "";
        width: 29px;
        height: 10px;
        background: url(/assets/img/common/ico_subnavnew.png) 0 0 no-repeat;
        background-size: cover; }
      #special .yonkoma-list li img {
        width: 100%; }
  #special .yonkoma-page {
    margin-bottom: 10px; }
  #special .yonkoma-an {
    position: relative;
    width: 505px;
    margin: 0 auto;
    border: 1px solid #e8ca24;
    z-index: 100;
    box-shadow: 0px 2px 5px #ccc; }
    #special .yonkoma-an img {
      width: 100%;
      display: block; }
    #special .yonkoma-an .twitter-tweet {
      width: auto !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important; }
    #special .yonkoma-an .EmbeddedTweet-tweet {
      border: none !important; }
  #special .yonkoma-main {
    position: relative;
    z-index: 100; }
  #special .yonkoma-cont {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 200; }
    #special .yonkoma-cont:hover {
      opacity: 0.7; }
  #special .yonkoma-cont--next {
    right: 30px; }
  #special .yonkoma-cont--prev {
    left: 30px; }
  #special #fankit .decbox:before {
    content: none; }
  #special .yaku-box {
    margin-bottom: 60px; }
    #special .yaku-box__title {
      margin-bottom: 10px; }
  #special .cast__list {
    width: 777px;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap; }
  #special .cast-btn:hover {
    opacity: 0.7;
    cursor: pointer; }
  #special .cast__line {
    float: left;
    height: auto;
    margin-bottom: 15px;
    text-align: center; }
    #special .cast__line:nth-child(2) {
      position: relative;
      top: 7px; }
    #special .cast__line:nth-child(2n) {
      margin-right: 0; }
  #special .taisyo-btn {
    position: relative;
    z-index: 100; }
    #special .taisyo-btn:hover {
      opacity: 0.7; }

@media (max-width: 750px) {
  #special {
    padding-top: 140px;
    background: url(/assets/img/common/sp/bg_main.png) top 120px center repeat-x;
    min-width: auto; }
    #special .title {
      display: block !important;
      width: 100%;
      height: 96px;
      text-align: center;
      background: url(/assets/img/special/title_bg.png) top center no-repeat; }
      #special .title img {
        padding-top: 5px;
        max-width: 100%; }
    #special .subnav {
      background: url(/assets/img/common/sp/header_border.png) left bottom repeat-x;
      padding-bottom: 3px; }
      #special .subnav__wrap {
        width: auto;
        margin: 0; }
      #special .subnav a {
        padding: 5px 20px;
        margin-right: 0;
        margin-left: 20px;
        margin-bottom: 13px;
        font-size: 20px; }
    #special .decbox {
      width: 750px;
      background-size: 710px auto, 710px auto, auto, auto; }
      #special .decbox__info {
        width: 650px; }
      #special .decbox__center img {
        width: 85%; }
    #special .camp__visual img {
      width: 100%;
      height: auto; }
    #special .camp__step {
      padding-right: 30px; }
      #special .camp__step .camp__steptitle img {
        width: 100%;
        height: auto; }
      #special .camp__step--2 {
        padding-bottom: 60px; }
      #special .camp__step--3 .camp__steptitle img {
        width: auto;
        height: auto; }
      #special .camp__stepbanner {
        width: 270px;
        height: auto;
        margin-right: 10px; }
        #special .camp__stepbanner img {
          width: 100%;
          height: auto;
          display: block; }
    #special .illust__list {
      width: 700px;
      text-align: center; }
    #special .illust__banner {
      margin-bottom: 20px; }
      #special .illust__banner img {
        display: inline;
        width: 95%; }
    #special .embedded {
      width: auto;
      margin-right: 0;
      float: none; }
      #special .embedded__tweet {
        width: 80%;
        margin: 0 auto; }
    #special .normal-inner {
      width: auto; }
    #special .radio-key {
      width: auto; }
    #special .taping-list {
      width: auto; }
    #special .taping:nth-child(odd), #special .taping:nth-child(even) {
      width: 80%;
      margin: 0 auto;
      float: none;
      margin-bottom: 3px; }
    #special .taping__thum {
      width: 48%;
      margin-right: 4%;
      margin-top: 4%; }
      #special .taping__thum img {
        width: 100%; }
    #special .taping__btn {
      width: 48%; }
      #special .taping__btn img {
        width: 100%; }
    #special .modal-img-inner {
      width: 80%; }
    #special .modal-img--twihead,
    #special .modal-img--wallpc,
    #special .modal-img--twiico {
      width: auto; }
    #special .modal-img--wallsp {
      width: 70%;
      margin: 0 auto; }
    #special .yonkoma-list {
      width: 95%; }
      #special .yonkoma-list li {
        width: 33.3333%;
        margin: 0 auto; }
    #special .cast__list {
      width: auto; }
    #special .cast__line {
      float: none;
      width: 380px;
      margin: 0 auto 20px auto; }
      #special .cast__line:nth-child(2n) {
        margin-right: auto; } }

.mb10 {
  margin-bottom: 10px; }

.mb20 {
  margin-bottom: 20px; }

.mb30 {
  margin-bottom: 30px; }

.mb40 {
  margin-bottom: 40px; }

.mb50 {
  margin-bottom: 50px; }

#system {
  padding-top: 152px;
  background: url(/assets/img/common/header_bg.png) top center no-repeat;
  background-size: 100% auto; }
  #system .content {
    padding-top: 10px;
    padding-bottom: 0; }
  #system .subnav {
    padding-bottom: 16px;
    background: url(/assets/img/common/header_border.png) left bottom repeat-x;
    width: 100%; }
    #system .subnav__wrap {
      width: 960px;
      margin: 0 auto; }
    #system .subnav a {
      background: #c1a0cc;
      border-radius: 6px;
      color: #FFF;
      padding: 5px 20px;
      margin-right: 15px;
      font-size: 14px;
      display: inline-block;
      position: relative; }
      #system .subnav a:hover {
        background: #aa4eab; }
      #system .subnav a.active {
        background: #aa4eab;
        cursor: default; }
    #system .subnav--new::after {
      content: '';
      position: absolute;
      width: 29px;
      height: 10px;
      top: -10px;
      left: 4px;
      background: url(/assets/img/common/ico_subnavnew.png) 0 0 no-repeat;
      background-size: auto 10px; }
  #system .system__block {
    padding-top: 25px;
    padding-bottom: 50px;
    background: url(/assets/img/system/battle_bg.jpg) top center repeat;
    background-attachment: fixed; }
  #system .decbox {
    width: 960px;
    margin: 0 auto;
    box-sizing: border-box;
    background: url(/assets/img/system/floral_top.png) top 14px center no-repeat, url(/assets/img/system/floral_btm.png) bottom 14px center no-repeat, url(/assets/img/system/floral_grad.png) top left repeat-x, url(/assets/img/system/floral_pattern.png) top left repeat;
    border: 4px solid #e8c95e;
    padding: 32px 0 60px;
    position: relative;
    text-align: center;
    margin-bottom: 30px; }
    #system .decbox::before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 6px;
      left: 6px;
      bottom: 6px;
      right: 6px;
      border: 1px solid #e8c95e; }
    #system .decbox__wrap {
      width: 840px;
      margin: 0 auto; }
    #system .decbox__title {
      margin-bottom: 20px; }
    #system .decbox__subtitle {
      text-align: left;
      margin-bottom: 10px; }
      #system .decbox__subtitle img {
        max-width: 100%; }
      #system .decbox__subtitle--s {
        padding-left: 20px; }
    #system .decbox__text {
      padding-left: 20px;
      text-align: left;
      font-size: 18px;
      line-height: 32px;
      text-shadow: 0 0 6px #FFF;
      position: relative;
      z-index: 2; }
      #system .decbox__text a {
        color: #2384C8;
        text-decoration: underline; }
        #system .decbox__text a:hover {
          text-decoration: none; }
      #system .decbox__text--small {
        line-height: 12px;
        font-size: 12px; }
    #system .decbox__notice {
      text-align: left;
      font-size: 14px;
      padding: 40px 0 0 20px; }
    #system .decbox__thumb {
      display: table-cell; }
      #system .decbox__thumbs {
        width: 860px;
        margin: 0 auto;
        display: table;
        border-spacing: 0 15px; }
      #system .decbox__thumbcaption {
        text-align: left;
        padding-left: 10px; }
      #system .decbox__thumbrow {
        display: table-row; }
    #system .decbox__center {
      position: relative;
      z-index: 2; }
      #system .decbox__center img {
        max-width: 95%; }
      #system .decbox__center--illust {
        padding: 20px 0; }
  #system .flow__block {
    background: url(/assets/img/system/flow_arrow.png) bottom 0 left 90px no-repeat;
    padding-bottom: 65px;
    margin-bottom: 10px;
    position: relative;
    z-index: 2; }
    #system .flow__block .decbox__subtitle {
      width: 810px;
      margin: 0 auto; }
    #system .flow__block--kyuketsu {
      padding-bottom: 95px; }
    #system .flow__block--last {
      background: none;
      padding-bottom: 80px; }
  #system .flow__imari {
    background: url(/assets/img/system/flow_imari_illust.png) top left no-repeat;
    width: 640px;
    margin: 0 auto;
    text-align: left;
    text-shadow: 0 0 6px #FFF; }
    #system .flow__imaritalk {
      background: url(/assets/img/system/flow_imari_talk.png) top left no-repeat;
      padding: 8px 8px 20px 90px;
      margin-left: 160px;
      font-weight: bold;
      font-size: 18px;
      line-height: 23px; }
    #system .flow__imariname {
      margin-left: 200px; }
    #system .flow__imaritext {
      font-size: 17px;
      line-height: 30px;
      padding: 0 0 10px 210px; }
  #system .flow__illust {
    position: absolute;
    top: -20px;
    right: 90px; }
  #system .flow__btn {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 10px; }
  #system .type__block {
    background: url(/assets/img/system/ikusei_type_bg.png) top left no-repeat;
    width: 832px;
    height: 646px;
    margin: 0 auto;
    text-align: left;
    box-sizing: border-box;
    padding: 92px 24px 0; }
    #system .type__block--kakusei2 {
      height: 376px;
      background: url(/assets/img/system/kakusei2_type_bg.png) top left no-repeat; }
  #system .type__title {
    margin-bottom: 2px; }
  #system .type__text {
    font-size: 16px;
    line-height: 19px;
    text-shadow: 0 0 6px #FFF;
    margin-bottom: 10px;
    padding-left: 20px; }
  #system .type__subtitle {
    padding-top: 20px; }
  #system .decbox--flow .decbox__notice {
    padding: 10px 0 0 20px; }
  #system .decbox--ikusei .decbox__notice {
    padding: 10px 0 0 20px; }
  #system .decbox--kakusei2 .decbox__notice {
    padding: 10px 0 0 20px; }

@media (max-width: 750px) {
  #system {
    padding-top: 140px;
    background: url(/assets/img/common/sp/bg_main.png) top 120px center repeat-x;
    min-width: auto; }
    #system .subnav {
      padding-bottom: 16px;
      background: url(/assets/img/common/sp/header_border.png) left bottom repeat-x; }
      #system .subnav__wrap {
        width: auto;
        margin: 0; }
      #system .subnav a {
        padding: 5px 20px;
        margin-right: 0;
        margin-left: 20px;
        font-size: 20px; }
    #system .decbox {
      width: 750px;
      background-size: 710px auto, 710px auto, auto, auto; }
      #system .decbox__info {
        width: 650px; }
      #system .decbox__wrap {
        width: 710px; }
      #system .decbox__thumb {
        display: table-cell; }
        #system .decbox__thumbs {
          width: 720px;
          margin: 0 auto;
          display: table;
          border-spacing: 0 5px;
          padding: 10px 0; }
        #system .decbox__thumbrow {
          display: table-row; }
        #system .decbox__thumb img {
          width: 230px;
          height: auto; }
    #system .flow__block .decbox__subtitle {
      width: 710px; }
    #system .flow__block .decbox__thumb img {
      width: 340px; }
    #system .flow__illust {
      position: absolute;
      top: 0;
      right: 20px; }
      #system .flow__illust img {
        width: 400px; }
    #system .type__block {
      background: url(/assets/img/system/sp/ikusei_type_bg.png) top left no-repeat;
      width: 730px; }
      #system .type__block--kakusei2 {
        background: url(/assets/img/system/sp/kakusei_type_bg.png) top left no-repeat; }
    #system .decbox--kakusei .decbox__thumb img {
      width: 340px; } }

#story {
  padding-top: 152px;
  background: url(/assets/img/common/header_bg.png) top center no-repeat;
  background-size: 100% auto; }
  #story .content {
    padding-bottom: 0;
    position: relative; }
    #story .content__bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      transition: all 1s ease 0s;
      opacity: 1; }
      #story .content__bg#bg1 {
        background: url(/assets/img/story/block_01_bg.jpg) top center no-repeat;
        background-size: cover;
        background-attachment: fixed; }
      #story .content__bg#bg2 {
        background: url(/assets/img/story/block_02_bg.jpg) top center no-repeat;
        background-size: cover;
        background-attachment: fixed; }
      #story .content__bg.hide {
        opacity: 0; }
  #story .story__block {
    width: 960px;
    margin: 0 auto;
    position: relative;
    z-index: 2; }
    #story .story__block img {
      width: 100%;
      height: auto; }
    #story .story__block#block1 {
      padding-bottom: 200px; }
    #story .story__block#block2 {
      padding-bottom: 80px; }

@media (max-width: 750px) {
  #story {
    padding-top: 140px;
    background: url(/assets/img/common/sp/bg_main.png) top 120px center repeat-x;
    min-width: auto; }
    #story .content__bg#bg1 {
      background: url(/assets/img/story/block_01_bg.jpg) top center no-repeat;
      background-size: cover;
      background-attachment: fixed; }
    #story .content__bg#bg2 {
      background: url(/assets/img/story/block_02_bg.jpg) top center no-repeat;
      background-size: cover;
      background-attachment: fixed; }
    #story .story__block {
      width: 100%; }
      #story .story__block#block1 {
        padding-bottom: 200px; }
      #story .story__block#block2 {
        padding-bottom: 80px; } }

#gallery {
  padding-top: 152px;
  background: url(/assets/img/common/header_bg.png) top center no-repeat;
  background-size: 100% auto; }
  #gallery .content {
    padding-top: 10px;
    padding-bottom: 0; }
  #gallery .subnav {
    padding-bottom: 16px;
    background: url(/assets/img/common/header_border.png) left bottom repeat-x;
    width: 100%; }
    #gallery .subnav__wrap {
      width: 960px;
      margin: 0 auto; }
    #gallery .subnav a {
      background: #c1a0cc;
      border-radius: 6px;
      color: #FFF;
      padding: 5px 20px;
      margin-right: 15px;
      font-size: 14px;
      display: inline-block;
      position: relative; }
      #gallery .subnav a:hover {
        background: #aa4eab; }
      #gallery .subnav a.active {
        background: #aa4eab;
        cursor: default; }
    #gallery .subnav--new::after {
      content: '';
      position: absolute;
      width: 29px;
      height: 10px;
      top: -10px;
      left: 4px;
      background: url(/assets/img/common/ico_subnavnew.png) 0 0 no-repeat;
      background-size: auto 10px; }
  #gallery .cgnav {
    padding-bottom: 16px;
    background: url(/assets/img/gallery/cgnav_bg.png) left top repeat-x;
    width: 100%;
    height: 213px; }
    #gallery .cgnav__wrap {
      width: 960px;
      margin: 0 auto;
      padding-top: 75px; }
      #gallery .cgnav__wrap a {
        margin-left: 20px;
        display: block;
        float: left;
        position: relative; }
    #gallery .cgnav--new::after {
      content: '';
      position: absolute;
      width: 29px;
      height: 10px;
      top: -12px;
      left: 2px;
      background: url(/assets/img/common/ico_subnavnew.png) 0 0 no-repeat;
      background-size: auto 10px; }
  #gallery .cg__main li {
    display: none; }
    #gallery .cg__main li.active {
      display: block; }
    #gallery .cg__main li img {
      max-width: 100%; }
  #gallery .movie__block {
    padding-top: 70px;
    padding-bottom: 80px; }
    #gallery .movie__block .decbox {
      margin-bottom: 40px; }
      #gallery .movie__block .decbox:last-child {
        margin-bottom: 0; }
  #gallery .stageevent__block {
    padding-top: 70px;
    padding-bottom: 80px; }
  #gallery .cg__block {
    padding-bottom: 80px; }
    #gallery .cg__block .decbox {
      padding-top: 88px; }
  #gallery .gun__block {
    padding-top: 70px;
    padding-bottom: 80px; }
    #gallery .gun__block .decbox {
      margin-bottom: 50px; }
      #gallery .gun__block .decbox:last-child {
        margin-bottom: 0; }
  #gallery .decbox {
    width: 960px;
    margin: 0 auto;
    box-sizing: border-box;
    background: url(/assets/img/gallery/floral_top.png) top 14px center no-repeat, url(/assets/img/gallery/floral_btm.png) bottom 14px center no-repeat, url(/assets/img/gallery/floral_grad.png) top left repeat-x, url(/assets/img/gallery/floral_pattern.png) top left repeat;
    border: 4px solid #e8c95e;
    padding-top: 32px;
    padding-bottom: 90px;
    position: relative;
    text-align: center; }
    #gallery .decbox::before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 6px;
      left: 6px;
      bottom: 6px;
      right: 6px;
      border: 1px solid #e8c95e; }
    #gallery .decbox__wrap {
      width: 840px;
      margin: 0 auto; }
    #gallery .decbox__title {
      margin-bottom: 20px; }
    #gallery .decbox__thumb {
      position: relative;
      z-index: 2;
      width: 670px;
      height: 378px;
      margin: 0 auto; }
      #gallery .decbox__thumb iframe {
        width: 100%;
        height: 100%; }
    #gallery .decbox__subtitle {
      text-align: left;
      margin-bottom: 10px; }
      #gallery .decbox__subtitle--s {
        padding-left: 20px; }
    #gallery .decbox__text {
      padding-left: 20px;
      text-align: left;
      font-size: 18px;
      line-height: 32px;
      text-shadow: 0 0 6px #FFF;
      position: relative;
      z-index: 2; }
      #gallery .decbox__text a {
        color: #2384C8;
        text-decoration: underline; }
        #gallery .decbox__text a:hover {
          text-decoration: none; }
  #gallery .guns__title {
    padding-top: 15px;
    text-align: center; }
    #gallery .guns__title img {
      max-width: 100%; }
  #gallery .guns__nav {
    width: 960px;
    margin: 0 auto;
    padding: 20px 0 25px; }
    #gallery .guns__nav a, #gallery .guns__nav span {
      position: relative;
      display: inline-block; }
      #gallery .guns__nav a.guns__nav--new::after, #gallery .guns__nav span.guns__nav--new::after {
        content: '';
        position: absolute;
        width: 29px;
        height: 10px;
        top: -10px;
        left: 14px;
        background: url(/assets/img/common/ico_subnavnew.png) 0 0 no-repeat;
        background-size: auto 10px; }
      #gallery .guns__nav a.guns__nav--cs::after, #gallery .guns__nav span.guns__nav--cs::after {
        content: '';
        position: absolute;
        width: 100px;
        height: 29px;
        top: 0;
        left: 0;
        background: url(/assets/img/gallery/mq/nav_cs.png) 0 0 no-repeat;
        background-size: 100% auto; }
  #gallery .gun__wrap {
    width: 960px;
    margin: 0 auto;
    position: relative; }
  #gallery .gun__btnmovie {
    position: absolute;
    top: 8px;
    left: 220px; }
  #gallery .gun#oda {
    border-top: 12px solid #dfb5f4; }
    #gallery .gun#oda .gun__wrap {
      background: url(/assets/img/gallery/mq/bg_oda.png) center center no-repeat;
      background-size: 450px auto; }
  #gallery .gun#toyotomi {
    border-top: 12px solid #dbc490; }
    #gallery .gun#toyotomi .gun__wrap {
      background: url(/assets/img/gallery/mq/bg_toyotomi.png) center center no-repeat;
      background-size: 450px auto; }
  #gallery .gun#uesugi {
    border-top: 12px solid #a6c7ef; }
    #gallery .gun#uesugi .gun__wrap {
      background: url(/assets/img/gallery/mq/bg_uesugi.png) center center no-repeat;
      background-size: 450px auto; }
  #gallery .gun#takeda {
    border-top: 12px solid #efa2b5; }
    #gallery .gun#takeda .gun__wrap {
      background: url(/assets/img/gallery/mq/bg_takeda.png) center center no-repeat;
      background-size: 450px auto; }
  #gallery .gun#sanada {
    border-top: 12px solid #e4ae8d; }
    #gallery .gun#sanada .gun__wrap {
      background: url(/assets/img/gallery/mq/bg_sanada.png) center center no-repeat;
      background-size: 450px auto; }
  #gallery .gun#date {
    border-top: 12px solid #b4bafb; }
    #gallery .gun#date .gun__wrap {
      background: url(/assets/img/gallery/mq/bg_date.png) center center no-repeat;
      background-size: 450px auto; }
  #gallery .gun__char {
    display: table-cell; }
    #gallery .gun__chars {
      width: 100%;
      display: table;
      border-spacing: 14px; }
  #gallery .gun__illust {
    text-align: center;
    margin-bottom: 5px; }
  #gallery .gun__btn {
    text-align: center; }
  #gallery .gunmodal {
    display: none;
    width: 100%;
    position: absolute;
    top: 100px;
    left: 0;
    overflow: hidden;
    z-index: 2000; }
    #gallery .gunmodal__close {
      position: absolute;
      top: 0;
      right: 0; }
    #gallery .gunmodal__cover {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.9;
      background-color: #d3ceea;
      z-index: 1000; }
    #gallery .gunmodal__wrap {
      padding-top: 50px;
      width: 344px;
      height: 646px;
      margin: 0 auto;
      position: relative; }
    #gallery .gunmodal__body {
      border: 10px solid #FFF;
      width: 100%;
      height: 100%; }
      #gallery .gunmodal__body iframe {
        width: 100%;
        height: 100%; }

@media (max-width: 750px) {
  #gallery {
    padding-top: 140px;
    background: url(/assets/img/common/sp/bg_main.png) top 120px center repeat-x;
    min-width: auto; }
    #gallery .cgnav {
      background-size: contain;
      height: 308px; }
      #gallery .cgnav__wrap {
        position: relative;
        top: 17px; }
    #gallery .cgnav a img {
      width: 100px;
      height: auto; }
    #gallery .cgnav a:nth-child(7) {
      position: relative;
      top: 17px;
      clear: both; }
    #gallery .subnav {
      background: url(/assets/img/common/sp/header_border.png) left bottom repeat-x;
      padding-bottom: 3px; }
      #gallery .subnav__wrap {
        width: auto;
        margin: 0; }
      #gallery .subnav a {
        padding: 5px 20px;
        margin-right: 0;
        margin-left: 20px;
        margin-bottom: 13px;
        font-size: 20px; }
    #gallery .decbox {
      width: 750px;
      background-size: 710px auto, 710px auto, auto, auto; }
      #gallery .decbox__thumb {
        width: 560px;
        height: 325px; }
      #gallery .decbox__info {
        width: 650px; }
      #gallery .decbox__wrap {
        width: 710px; }
    #gallery .guns__nav {
      width: auto;
      margin: 0;
      padding: 30px 30px; }
      #gallery .guns__nav img {
        width: 110px;
        height: auto; }
      #gallery .guns__nav a.guns__nav--cs::after, #gallery .guns__nav span.guns__nav--cs::after {
        width: 110px;
        height: 32px; }
    #gallery .gun__char img {
      width: 130px; }
    #gallery .gun#date img {
      width: 217px; }
    #gallery .gun__wrap {
      width: auto;
      margin: 0; }
    #gallery .gunmodal {
      top: 900px; } }
