#story {
  padding-top:152px;
  background:url(/assets/img/common/header_bg.png) top center no-repeat;
  background-size:100% auto;
  .content {
    padding-bottom:0;
    position:relative;
    &__bg {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      z-index:1;
      transition:all 1s ease 0s;
      opacity:1;
      &#bg1 {
        background:url(/assets/img/story/block_01_bg.jpg) top center no-repeat;
        background-size:cover;
        background-attachment:fixed;
      }
      &#bg2 {
        background:url(/assets/img/story/block_02_bg.jpg) top center no-repeat;
        background-size:cover;
        background-attachment:fixed;
      }
      &.hide { opacity:0; }
    }
  }
  .story__block {
    width:960px;
    margin:0 auto;
    img {
      width:100%;
      height:auto;
    }
    &#block1 { padding-bottom:200px; }
    &#block2 { padding-bottom:80px; }
    position:relative;
    z-index:2;
  }
}

@media (max-width: 750px) {

  #story {
    padding-top:140px;
    background:url(/assets/img/common/sp/bg_main.png) top 120px center repeat-x;
    min-width:auto;
    .content {
      &__bg {
        &#bg1 {
          background:url(/assets/img/story/block_01_bg.jpg) top center no-repeat;
          background-size:cover;
          background-attachment:fixed;
        }
        &#bg2 {
          background:url(/assets/img/story/block_02_bg.jpg) top center no-repeat;
          background-size:cover;
          background-attachment:fixed;
        }
      }
    }
    .story__block {
      width:100%;
      &#block1 { padding-bottom:200px; }
      &#block2 { padding-bottom:80px; }
    }
  }

}